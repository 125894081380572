import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { v4 as uuidv4 } from 'uuid';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";

import { User, FileText, Lock, Clock, Globe, List, CreditCard, ArrowLeft, HelpCircle } from 'react-feather';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import { FileUploaderButton } from '../../Components/FileUploaderButton';
import { FormTextInput } from '../../Components/FormTextInput';

import { languages } from '../../Data/languages';

import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { useEnterKey } from '../../Hooks/useEnterKey';

import { AuthContext } from '../../Context/AuthContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './OrderDocuments.css';
import TopBar from '../../Components/TopBar';

const OrderDocuments = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();
  const { showNotification } = useContext(NotificationContext);

  const { signedIn } = useContext(AuthContext);

  const { order, setOrder } = useContext(DataContext);

  // const orderID = useRef();

  useEffect(() => {
    if (order.parsed && (order.name === '' || order.email === '') && !signedIn) {
      transitionTo('/order/contact');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, signedIn]);

  useEffect(() => {
    if ((windowSize.width > 767 || windowSize.orientation === 'landscape') && document.getElementById('mainPrimary') && document.getElementById('mainSecondary')) {
      if (document.getElementById('mainPrimary').clientHeight > document.getElementById('mainSecondary').clientHeight) {
        document.getElementById('mainSecondary').style.minHeight = `${document.getElementById('mainPrimary').clientHeight}px`;
      } else {
        document.getElementById('mainPrimary').style.minHeight = `${document.getElementById('mainSecondary').clientHeight}px`;
      }    
    }
  });

  const [certified, setCertified] = useState(true);

  const [languageFrom, setLanguageFrom] = useState('');
  const [languageTo, setLanguageTo] = useState('');

  const certifiedProps = useSpring({ borderColor: certified ? '#FF7C1D' : 'rgba(102,102,102,0.1)' });
  const standardProps = useSpring({ borderColor: !certified ? '#FF7C1D' : 'rgba(102,102,102,0.1)' });

  const [files, setFiles] = useState([]);

  const [pagesCount, setPagesCount] = useState('');
  const [wordCount, setWordCount] = useState('');

  useEffect(() => {
    setCertified(order.certified || true);
    setLanguageFrom(order.languageFrom || '');
    setLanguageTo(order.languageTo || '');
    setFiles(order.files || []);
    setPagesCount(order.pagesCount || '');
    setWordCount(order.wordCount || '');
  }, [order]);

  useEffect(() => {
    if (order.parsed && (!order.orderID || order.orderID === '')) {
      updateOrderId();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [order]);

  const updateOrderId = () => {
    const id = uuidv4();
    setOrder(o => ({ ...o, orderID: id }));
    return id;
  }

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    const oID = !order.orderID || order.orderID === '' ? updateOrderId() : order.orderID;

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name)) {
        const fileID = uuidv4();
        setFiles(f => f.concat([{ id: fileID, name: mFiles[i].name, file: mFiles[i], progress: 0, status: 'uploading' }]));
      // } else {
      //   showNotification("File already uploaded.", "You've already uploaded a file with this name. Try uploading with another name.", "error");
      // }
    }

    const storageRef = firebase.storage().ref();
    
    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name)) {
        const subFolder = uuidv4();
        const fileRef = storageRef.child(oID + '/' + subFolder + '/' + mFiles[i].name);

        const downloadToken = uuidv4();

        const fileName = mFiles[i].name.replace('#', '%23').replace('+', "%2b");

        const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${oID}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

        var metadata = {
          firebaseStorageDownloadTokens: downloadToken,
          customMetadata: {
            'orderID': oID
          }
        }

        const uploadTask = fileRef.put(mFiles[i], metadata);

        setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, task: uploadTask } : file));

        uploadTask.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, progress: progress.toFixed(2) } : file));
        }, (error) => {
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'failed' } : file));
          showNotification("Error uploading.", "There was an error uploading the file " + mFiles[i].name + ". Please, try again.", "error");
        }, () => {
          /*uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            setFiles(f => f.map(file => file.name === mFiles[i].name ? { ...file, status: 'complete', ref: oID + '/' + subFolder + '/' + mFiles[i].name, url: downloadURL } : file));
          });*/
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'complete', ref: oID + '/' + subFolder + '/' + mFiles[i].name, url: url } : file));
        });
      // }
    }
  }


  const handleDeleteFile = file => {
    const storageRef = firebase.storage().ref();

    if (file.status === 'complete') {
      const fileRef = storageRef.child(file.ref);
      setFiles(f => f.map(mFile => mFile.name === file.name && mFile.ref === file.ref ? { ...mFile, status: 'deleting' } : mFile));

      fileRef.delete()
        .then(function() {
          setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
        }).catch(function(error) {
          showNotification("Error deleting the file.", "There was an error deleting the file " + file.name + ". Please, try again.", "error");
        });
    } else if (file.status === 'failed') {
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    }
  }


  const handleContinueToOptions = () => {
    const orderId = !order.orderID || order.orderID === '' ? updateOrderId() : order.orderID;
    localStorage.setItem('order', JSON.stringify({ ...order, orderID: orderId, certified: certified, languageFrom: languageFrom, languageTo: languageTo, files: JSON.stringify(files.filter(f => f.ref && f.url && f.status === 'complete').map(file => ({ name: file.name, ref: file.ref, url: file.url, type: 'source', size: file.file ? file.file.size : file.size, format: file.file ? file.file.type : file.format, status: 'complete', progress: 100 }))), pagesCount: pagesCount, wordCount: wordCount }));
    setOrder(o => ({ ...o, orderID: orderId, certified: certified, languageFrom: languageFrom, languageTo: languageTo, files: files.filter(f => f.ref && f.url && f.status === 'complete').map(file => ({ name: file.name, ref: file.ref, url: file.url, type: 'source', size: file.file ? file.file.size : file.size, format: file.file ? file.file.type : file.format, status: 'complete', progress: 100 })), pagesCount: pagesCount, wordCount: wordCount }));
    transitionTo('/order/options');
  }

  const handleClickContact = () => {
    if (!signedIn) {
      transitionTo('/order/contact');
    }
  }

  const handleClickOptions = () => {
    if ((signedIn || order.name !== '') && order.languageFrom && order.languageFrom !== '') {
      transitionTo('/order/options');
    }
  }

  const handleClickPayment = () => {
    if ((signedIn || order.name !== '') && order.languageFrom && order.languageFrom !== '' && order.optionsSet) {
      transitionTo('/order/payment');
    }
  }

  const onChangePagesCount = (count) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (count === '' || reg.test(count)) {
      setPagesCount(count);
    }
  }

  const onChangeWordCount = (count) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (count === '' || reg.test(count)) {
      setWordCount(count);
    }
  }

  const getLanguage = (language) => {
    if (languages.find(lang => lang.value === language)) {
      return languages.find(lang => lang.value === language).label;
    } else {
      return '';
    }
  }

  const getExpedited = () => {
    let total = 0;

    if (certified) {
      if (pagesCount !== '') {
        total = total + parseInt(pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else {
      if (wordCount !== '') {
        total = total + parseInt(wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    return total * 0.4;
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const getTimeFrame = () => {

    let limits = [0, 750];

    for (let i = 2; i < 200; i++) {
      limits.push(limits[limits.length - 1] + 1500 + (i-2) * 250);
    }

    let count = 0;

    if (certified && pagesCount !== '') {
      count = pagesCount * 250;
    } else if (!certified && wordCount !== '') {
      count = wordCount;
    };

    const ceiling = limits.filter(limit => count <= limit).sort((a,b) => a - b)[0];
    let ceilingIndex = limits.findIndex(limit => limit === ceiling);

    if (order.expedited) {
      ceilingIndex = ceilingIndex / 2;
    };

    if (ceilingIndex > 0.5) {
      ceilingIndex = Math.ceil(ceilingIndex);
    }

    if (ceilingIndex <= 2) {
      return `${ceilingIndex * 24} hours`;
    } else {
      return `${ceilingIndex - 1}-${ceilingIndex} days`;
    }

  }

  const triggerContinue = () => {
    if (!((!signedIn && !order.name) || languageFrom === '' || languageTo === '' || files.length === 0 || (pagesCount === '' && wordCount === '') || (!certified && parseInt(wordCount, 10) < 200) || files.some(file => file.status !== 'complete'))) {
      handleContinueToOptions();
    }
  }

  useEnterKey(() => triggerContinue());

  const phoneScreen = windowSize.orientation === 'portrait' && windowSize.width <= 767; 

  return (
    <>
    <TopBar/>
    <div className="quoteContainer">
      <div className="quoteHeader">
        <img src={require('../../assets/logoAlt.png')} alt="Logo eVerbary" className="quoteLogo" onClick={(e) => transitionTo('/',e)} />
        <div className="quoteHeaderExtra">
          <div className="quoteHeaderExtraBlock" onClick={handleClickContact} style={{ opacity: 0.5, cursor: !signedIn ? 'pointer' : 'default' }}>
            <User className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Contact</p>
          </div>
          <div className="orderHeaderConnector" />
          <div className="quoteHeaderExtraBlock">
            <FileText className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Documents</p>
          </div>
          <div className="orderHeaderConnector" />
          <div className="quoteHeaderExtraBlock" onClick={handleClickOptions} style={{ opacity: 0.5, cursor: (signedIn || order.name !== '') && order.languageFrom && order.languageFrom !== '' ? 'pointer' : 'default' }}>
            <List className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Options</p>
          </div>
          <div className="orderHeaderConnector" />
          <div className="quoteHeaderExtraBlock" onClick={handleClickPayment} style={{ opacity: 0.5, cursor: (signedIn || order.name !== '') && order.languageFrom && order.languageFrom !== '' && order.optionsSet ? 'pointer' : 'default' }}>
            <CreditCard className="quoteHeaderIcon" />
            <p className="quoteHeaderExtraBlockText" style={{ display: phoneScreen ? 'none' : 'flex' }}>Payment</p>
          </div>
        </div>
      </div>
      <div className="quoteMainContainer">
        <div id="mainPrimary" className="quoteMainPrimaryContainer">
          <div className="quotePrimaryInner">
            <h3 className="quotePrimaryTitle">What type of translation do you need?</h3>
            <div className="quotePrimaryOptions">
              <animated.div style={certifiedProps} className="quotePrimaryOption" onClick={() => setCertified(true)}>
                <p className="quotePrimaryOptionTitle">Certified Translation</p>
                <p className="quotePrimaryOptionText">Word-for-word human translation of documents with certification for official use.</p>
              </animated.div>
              <animated.div style={standardProps} className="quotePrimaryOption" onClick={() => setCertified(false)}>
                <p className="quotePrimaryOptionTitle">Standard Translation</p>
                <p className="quotePrimaryOptionText">Human translation of documents and text-based content for business or personal use.</p>
              </animated.div>
            </div>
            <h3 className="quotePrimaryTitle">What language pair do you need translated?</h3>
            <p className="quoteSmallMessage">Either the source or target language must include English. Please contact us if you need a language pair that doesn't include English.</p>
            <div className="quoteDetailsInputsContainer">
              <div className="quoteDetailsSelectContainer">
                <select value={languageFrom} onChange={(e) => setLanguageFrom(e.target.value)} className="quoteDetailsSelect">
                  <option key="" value="" disabled>Translate From (*)</option>
                  {languages.filter(language => languageTo === 'english' || languageTo === '' || language.value === 'english').map(language => 
                    <option key={language.value} value={language.value}>{language.label}</option>
                  )}
                </select>
              </div>
              <div className="quoteDetailsSelectContainer">
                <select placeholder="Translate To (*)" value={languageTo} onChange={(e) => setLanguageTo(e.target.value)} className="quoteDetailsSelect">
                  <option key="" value="" disabled>Translate To (*)</option>
                  {languages.filter(language => languageFrom === 'english' || languageFrom === '' || language.value === 'english').map(language => 
                    <option key={language.value} value={language.value}>{language.label}</option>
                  )}
                </select>
              </div>
            </div>

            <p className="quotePrimaryTitle">{certified ? "How many pages do you need translated?" : "How many words do you need translated?"}</p>
            {wordCount !== '' && parseInt(wordCount, 10) < 200 && 
              <div className="orderCountWarning">Sorry, but we have a 200 word minimum per order.</div>
            }
            <div className="orderCountContainer">
              <div className="orderCountInputContainer">
                <FormTextInput placeholder={certified ? "Page Count (*)" : "Word Count (*)"} value={certified ? pagesCount : wordCount} onChange={certified ? onChangePagesCount : onChangeWordCount} color='#666' />
              </div>
              <p className="orderCountAuxText">{certified ? "A page is 250 words or fewer including numbers. Unsure of your page count?" : "Enter the total word count from all of your documents. Unsure of your word count?"} <span className="quoteSignInButton">Request a quote.</span></p>
            </div>

            <h3 className="quotePrimaryTitle">Upload the files you need translated</h3>
            <div className="quoteDetailsFileUploaderContainer">
              <FileUploaderButton display text="Choose Files" files={files} handleFiles={handleFiles} handleDeleteFile={handleDeleteFile} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.6 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
            </div>
            
            
            {!phoneScreen && <div className="quoteSubmitButtonContainer">
              <div className="quoteGoBackButton" style={{ display: !signedIn ? 'flex' : 'none' }} onClick={(e) => transitionTo('/order/contact',e)}>
                <ArrowLeft className="quoteGoBackIcon" />
              </div>
              <AltAnimatedButton text="Continue to Options" disabled={(!signedIn && !order.name) || languageFrom === '' || languageTo === '' || files.length === 0 || (pagesCount === '' && wordCount === '') || (!certified && wordCount === '') || (!certified && parseInt(wordCount, 10) < 200) || files.some(file => file.status !== 'complete')} onClick={() => handleContinueToOptions()} fontSize={windowSize.width > 1024 ? 1.2 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
            </div>}
          </div>
        </div>
        <div id="mainSecondary" className="quoteMainSecondaryContainer">
          <div className="orderSummary">
            <h3 className="orderSummaryTitle">SUMMARY</h3>
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">{certified ? "Certified Translation" : "Standard Translation"}</p>
                <p className="orderSummaryInfoCount">{certified ? (pagesCount !== '' ? pagesCount + " pages (" + (parseInt(pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (wordCount !== '' ? wordCount + " words" : "0 words")}</p>
              </div>
              <p className="orderSummaryInfoType">{certified ? (pagesCount !== "" ? '$' + (parseInt(pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((wordCount !== '' ? '$' + (parseInt(wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>
            </div>
            {order.notarization ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">Notarization</p>
                <p className="orderSummaryInfoCount">Valid in all 50 states</p>
              </div>
              <p className="orderSummaryInfoType">$34.90</p>
            </div> : null}
            {order.expedited ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">Expedited Turnaround</p>
                <p className="orderSummaryInfoCount">Turnaround reduced by 50%</p>
              </div>
              <p className="orderSummaryInfoType">{formatTotal(getExpedited())}</p>
            </div> : null}
            {order.hardCopy && order.hardCopyExtra.country !== '' && order.hardCopyExtra.method !== '' ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">{`USPS ${order.hardCopyExtra.method.charAt(0).toUpperCase() + order.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? 'International' : ''}`}</p>
                <p className="orderSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? (order.hardCopyExtra.method === 'priority' ? '2-3' : '1-2') : (order.hardCopyExtra.method === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
              </div>
              <p className="orderSummaryInfoType">{`$${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? (order.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (order.hardCopyExtra.method === 'priority' ? '49.95' : '78.95')}`}</p>
            </div> : null}
            {order.hardCopy && order.hardCopyExtra.requireSignature ? 
            <div className="orderSummaryInfo">
              <div className="orderSummaryInfoMain">
                <p className="orderSummaryInfoType">Signature Required for Delivery</p>
                <p className="orderSummaryInfoCount">Adult signature required</p>
              </div>
              <p className="orderSummaryInfoType">$4.99</p>
            </div> : null}
            <div className="orderSummaryTotal">
              <p>Total</p>
              <p>{certified ? (pagesCount !== "" ? '$' + (parseInt(pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((wordCount !== '' ? '$' + (parseInt(wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>
            </div>
          </div>
          <div className="quoteSecondaryExtraInfo">
            {((certified && pagesCount !== '') || (!certified && wordCount !== '')) &&
              <div className="orderSecondaryTitle">
                <Clock className="orderSecondaryIcon" />
                {`${getTimeFrame()} est. turnaround`}
              </div>}
            {((certified && pagesCount !== '') || (!certified && wordCount !== '')) &&
              <div className="quoteSecondarySub">This is an estimate to deliver the digital copy based on the current order information you've provided.</div>
            }
            {languageFrom !== '' && languageTo !== '' &&
              <div className="orderSecondaryTitle">
                <Globe className="orderSecondaryIcon" />
                {`${getLanguage(languageFrom)} to ${getLanguage(languageTo)} Translation`}
              </div>
            }
            {languageFrom !== '' && languageTo !== '' &&
              <div className="quoteSecondarySub">We'll pair you with a professional translator and a project manager from our support team.</div>
            }
            <div className="orderSecondaryTitle">
              <Lock className="orderSecondaryIcon" />
              Secure and Private
            </div>
            <div className="quoteSecondarySub">All data processed by our site, including uploaded files, is encrypted. Only essential staff with signed NDAs can access your information.</div>
          </div>
          <img src={require('../../assets/encryptionIcons.png')} alt="Encryption Certificates" className="quoteSecondaryEncryptionImg" />
          <div className="orderSecondaryTitle">
            <HelpCircle className="orderSecondaryIcon" />
            Need help?
          </div>
          <div className="quoteSecondarySub"><a href="https://everbary.com/contact">Contact us</a> and we will create the order on your behalf.</div>
          {phoneScreen && <div className="quoteSubmitButtonContainer">
            <div className="quoteGoBackButton" style={{ display: !signedIn ? 'flex' : 'none' }} onClick={(e) => transitionTo('/order/contact',e)}>
              <ArrowLeft className="quoteGoBackIcon" />
            </div>
            <AltAnimatedButton text="Continue to Options" disabled={(!signedIn && !order.name) || languageFrom === '' || languageTo === '' || files.length === 0 || (pagesCount === '' && wordCount === '') || (!certified && wordCount === '') || (!certified && parseInt(wordCount, 10) < 200) || files.some(file => file.status !== 'complete')} onClick={() => handleContinueToOptions()} fontSize={windowSize.width > 1024 ? 1.2 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
          </div>}
        </div>
      </div>
      <div className="quoteFooter">
        <div className="quoteFooterLinksContainer">
          <div className="quoteFooterLink">Contact</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Terms</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Refunds</div>
          <div className="quoteFooterLinkSeparator" />
          <div className="quoteFooterLink">Privacy</div>
        </div>
      </div>
    </div>
    </>
  );
}

export default OrderDocuments;