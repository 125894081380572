import React, { useContext, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useHistory } from 'react-router-dom';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { ChevronDown, Menu, ArrowRight, X } from 'react-feather';

import { AnimatedButton } from './AnimatedButton';
import { AltAnimatedButton } from './AltAnimatedButton';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';
import { AuthContext } from '../Context/AuthContext';
import { DataContext } from '../Context/DataContext';
// import NewIcon from '../assets/NewIcon.png';

import './Navbar.css';

const Navbar = ({ windowSize, isLoginFormOpen,isCreateAccountOpen }) => {

  const { transitionTo } = useScreenTransition();

  const { setBodyLock, color, setColor } = useContext(TransitionContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn, clearSignOut } = useContext(AuthContext);
  const { userData } = useContext(DataContext);

  const history = useHistory();

  const [openMobile, setOpenMobile] = useState(false);
  const mobileMenuProps = useSpring({ opacity: openMobile ? 1 : 0, pointerEvents: openMobile ? 'all' : 'none' });

  const colorProps = useSpring({ color: color || (windowSize.width <= 1024 && windowSize.orientation === 'portrait') ? '#18b7bb' : '#fff' });

  const mobileMenuTransition = (path, e) => {
    setBodyLock(false);
    transitionTo(path,e);
  }

  const signOut = () => {
    setColor(false);
    setBodyLock(false);
    setOpenMobile(false);
    history.push('/');

    firebase.auth().signOut()
      .then(() => {
        showNotification("Success", "You have signed out.", "success");
        clearSignOut();
      })
      .catch(() => {
        showNotification("Error", "There was an error signing out. Please, try again.", "error");
      })
  }

  const changeLoginOpen=()=>{
    isLoginFormOpen(true);
    setOpenMobile(false)
  }

  const changeCreateAccountOpen=()=>{
    isLoginFormOpen(false);
    setOpenMobile(false);
    isCreateAccountOpen(true);
  }


  return (
    <>
    <div className="navbarContainer">
      <img src={require('../assets/logo.png')} alt="Logo eVerbary" className="logo" onClick={(e) => transitionTo('/',e)} />
      <div className="navbarMain">
        <div className="navbarButtons">
          {signedIn && userData && userData.hasTeam && <div className="navbarLink">
          {windowSize.width > 1024
                    ? (!color ? <AnimatedButton text="My Team" onClick={(e) => transitionTo('/team/orders',e)} fontSize={1.1}/> : <AltAnimatedButton text="My Team" onClick={(e) => transitionTo('/team/orders',e)} fontSize={1.1}/>)
                    : <AltAnimatedButton text="Team" onClick={(e) => transitionTo('/team/orders',e)} fontSize={windowSize.orientation === 'portrait' ? 1.4 : 1.2}/>
                    }
          </div>
            }
          <div className="navbarLink">
            <animated.p className="navbarLinkText" style={colorProps}>Services</animated.p>
            <ChevronDown size={17} color={color ? '#18b7bb' : '#fff'} className="navbarLinkButton" />
            <div className="navbarLinkSubMenuOuter">
              <div className="navbarLinkSubMenu">
                <div className="navbarSubMenuBlock" onClick={(e) => transitionTo('/certified',e)}>
                  <img src={require('../assets/stamp.png')} alt="Stamp" className="navbarSubMenuBlockImg" />
                  <div className="navbarSubMenuBlockInfo">
                    <p className="navbarSubMenuBlockTitle">Certified Translation</p>
                    <p className="navbarSubMenuBlockText">Word-for-word human translation of documents with certification for official use.</p>
                  </div>
                </div>
                <div className="navbarSubMenuBlock" onClick={(e) => transitionTo('/standard',e)}>
                  <img src={require('../assets/singleFile.png')} alt="Document" className="navbarSubMenuBlockImg" />
                  <div className="navbarSubMenuBlockInfo">
                    <p className="navbarSubMenuBlockTitle">Standard Translation</p>
                    <p className="navbarSubMenuBlockText">Human translation of documents and text-based content for business or personal use.</p>
                  </div>
                </div>
                <div className="navbarSubMenuBlock" onClick={(e) => transitionTo('/interpretations',e)}>
                  <img src={require('../assets/interpreter.png')} alt="Document" className="navbarSubMenuBlockImg" />
                  <div className="navbarSubMenuBlockInfo">
                    <p className="navbarSubMenuBlockTitle">Interpretations</p>
                    <p className="navbarSubMenuBlockText">Consecutive interpretation, simultaneous interpretation, sight interpretation, and more.</p>
                  </div>
                </div>
                <div className="navbarSubMenuMiniBlock" onClick={(e) => transitionTo('/languages',e)}>
                  <img src={require('../assets/earth.png')} alt="Planet Earth" className="navbarSubMenuMiniBlockImg" />
                  <div className="navbarSubMenuMiniBlockInfo">
                    <p className="navbarSubMenuMiniBlockTitle">Languages</p>
                    <p className="navbarSubMenuMiniBlockText">60+ languages translated</p>
                  </div>
                </div>
                {/* <div className="navbarSubMenuMiniBlock" onClick={(e) => transitionTo('/documents',e)}>
                  <img src={require('../assets/file.png')} alt="Documents" className="navbarSubMenuMiniBlockImg" />
                  <div className="navbarSubMenuMiniBlockInfo">
                    <p className="navbarSubMenuMiniBlockTitle">Documents</p>
                    <p className="navbarSubMenuMiniBlockText">All document types translated</p>
                  </div>
                </div> */}
                <div className="navbarSubMenuMiniBlock" onClick={(e) => transitionTo('/use-cases',e)}>
                  <img src={require('../assets/gavel.png')} alt="Gavel" className="navbarSubMenuMiniBlockImg" />
                  <div className="navbarSubMenuMiniBlockInfo">
                    <p className="navbarSubMenuMiniBlockTitle">Use Cases</p>
                    <p className="navbarSubMenuMiniBlockText">Explore common use cases</p>
                  </div>
                </div>
                <div className="navbarSubMenuLinkContainer" onClick={(e) => transitionTo('/business',e)}>
                  <p className="navbarSubMenuLink">Translation services for businesses</p>
                  <ArrowRight size={20} color='#18b7bb' />
                </div>
              </div>
            </div>
          </div>
          <div className="navbarLink">
            <animated.p className="navbarLinkText" style={colorProps}>Help</animated.p>
            <ChevronDown size={17} color={color ? '#18b7bb' : '#fff'} className="navbarLinkButton" />
            <div className="navbarLinkSubMenuOuter">
              <div className="navbarLinkSubMenu">
                <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/faq',e)}>
                  <p className="navbarSubMenuLink">FAQs</p>
                </div>
                {/* <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/guides',e)}>
                  <p className="navbarSubMenuLink">Guides</p>
                </div> */}
                <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/order-lookup',e)}>
                  <p className="navbarSubMenuLink">Order Lookup</p>
                </div>
                <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/verify',e)}>
                  <p className="navbarSubMenuLink">Doc Verification</p>
                </div>
                <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/contact',e)}>
                  <p className="navbarSubMenuLink">Contact</p>
                </div>
              </div>
            </div>
          </div>
          <div className="navbarLink">
            <animated.p className="navbarLinkText" style={colorProps}>Company</animated.p>
            <ChevronDown size={17} color={color ? '#18b7bb' : '#fff'} className="navbarLinkButton" />
            <div className="navbarLinkSubMenuOuter">
              <div className="navbarLinkSubMenu">
                <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/about',e)}>
                  <p className="navbarSubMenuLink">About</p>
                </div>
                {/* <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/jobs',e)}>
                  <p className="navbarSubMenuLink">Jobs</p>
                </div> */}
                <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/reviews',e)}>
                  <p className="navbarSubMenuLink">Reviews</p>
                </div>
              </div>
            </div>
          </div>
          <div className="navbarLink">
            <animated.p className="navbarLinkText" style={colorProps}>Account</animated.p>
            <ChevronDown size={17} color={color ? '#18b7bb' : '#fff'} className="navbarLinkButton" />
            <div className="navbarLinkSubMenuOuter">
              { !signedIn
              ? <div className="navbarLinkSubMenu">
                  <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => changeCreateAccountOpen()}>
                    <p className="navbarSubMenuLink">Create Account</p>
                  </div>
                  <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => changeLoginOpen()}>
                    <p className="navbarSubMenuLink">Sign In </p>
                  </div>
                </div>
              : <div className="navbarLinkSubMenu">
                  { userData && (userData.role === 'owner' || userData.role === 'Support' || userData.role === 'Translator') &&
                    <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/office/translations',e)}>
                      <p className="navbarSubMenuLink">{userData.role === 'owner' ? 'Owner Portal' : userData.role === 'Support' ? 'Support Portal' : 'Translator Portal'}</p>
                    </div>
                  }
                  { userData && (userData.role === 'owner' || userData.role === 'Support' || userData.role === 'Translator') &&
                  <>
                    <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/account/profile',e)}>
                      <p className="navbarSubMenuLink">Account Settings</p>
                    </div>
                    </>
                  }
                  {userData && userData.role === 'client' && 
                    <>
                      <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/account/profile',e)}>
                        <p className="navbarSubMenuLink">Profile</p>
                      </div>
                      <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/account/shipping',e)}>
                        <p className="navbarSubMenuLink">Shipping</p>
                      </div>
                      <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/account/quotes',e)}>
                        <p className="navbarSubMenuLink">Quotes</p>
                      </div>
                      <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={(e) => transitionTo('/account/orders',e)}>
                        <p className="navbarSubMenuLink">Orders</p>
                      </div>
                    </>
                  }
                  <div className="navbarSubMenuLinkContainer simpleSubMenuLink" onClick={() => signOut()}>
                    <p className="navbarSubMenuLink">Sign Out</p>
                  </div>
                </div>
              }  
            </div>
          </div>

          
        </div>
        <div className="navbarOrderButtons">
          <animated.p className="navbarLinkText navbarLinkOrder" style={colorProps} onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>Free Quote</animated.p>
          {windowSize.width > 1024
          ? (!color ? <AnimatedButton text="Order" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={1.1}/> : <AltAnimatedButton text="Order" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={1.1}/>)
          : <AltAnimatedButton text="Order" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.orientation === 'portrait' ? 1.6 : 1.2}/>
          }
        </div>
      </div>
      <div className="navbarMobile">
        <Menu size={30} className="mobileMenuButton" onClick={() => { setOpenMobile(true); setBodyLock(true); }}/>
        <animated.div className="mobileMenuOuter" style={mobileMenuProps}>
          <div className="mobileMenuMid">
            <div className="mobileMenu">
              <div className="mobileMenuControls">
                <p className="mobileMenuTitle">MENU</p>
                <X size={30} color='#666' onClick={() => { setOpenMobile(false); setBodyLock(false); }} />
              </div>
              <div className="mobileMenuOrderButtons">
                <AltAnimatedButton text="Order Now" onClick={() => { transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`); setBodyLock(false); }} fontSize={windowSize.width > 1024 ? 1.2 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
               <p className="servicesDisplayButtonsMore" onClick={(e) => mobileMenuTransition('/quote/contact',e)}>Request a Quote</p>
              </div>
              <div className="navbarSubMenuBlock" onClick={(e) => mobileMenuTransition('/certified',e)}>
                <img src={require('../assets/stamp.png')} alt="Stamp" className="navbarSubMenuBlockImg" />
                <div className="navbarSubMenuBlockInfo">
                  <p className="navbarSubMenuBlockTitle">Certified Translation</p>
                  <p className="navbarSubMenuBlockText">Word-for-word human translation of documents with certification for official use.</p>
                </div>
              </div>
              <div className="navbarSubMenuBlock" onClick={(e) => mobileMenuTransition('/standard',e)}>
                <img src={require('../assets/singleFile.png')} alt="Document" className="navbarSubMenuBlockImg" />
                <div className="navbarSubMenuBlockInfo">
                  <p className="navbarSubMenuBlockTitle">Standard Translation</p>
                  <p className="navbarSubMenuBlockText">Human translation of documents and text-based content for business or personal use.</p>
                </div>
              </div>
              <div className="navbarSubMenuBlock" onClick={(e) => mobileMenuTransition('/interpretations',e)}>
                <img src={require('../assets/interpreter.png')} alt="Document" className="navbarSubMenuBlockImg" />
                <div className="navbarSubMenuBlockInfo">
                  <p className="navbarSubMenuBlockTitle">Interpretations</p>
                  <p className="navbarSubMenuBlockText">Consecutive interpretation, simultaneous interpretation, sight interpretation, and more.</p>
                </div>
              </div>
              <div className="mobileMenuMiniBlocks">
                <div className="navbarSubMenuMiniBlock" onClick={(e) => mobileMenuTransition('/languages',e)}>
                  <img src={require('../assets/earth.png')} alt="Planet Earth" className="navbarSubMenuMiniBlockImg" />
                  <div className="navbarSubMenuMiniBlockInfo">
                    <p className="navbarSubMenuMiniBlockTitle">Languages</p>
                    <p className="navbarSubMenuMiniBlockText">60+ languages translated</p>
                  </div>
                </div>
                {/* <div className="navbarSubMenuMiniBlock" onClick={(e) => mobileMenuTransition('/documents',e)}>
                  <img src={require('../assets/file.png')} alt="Documents" className="navbarSubMenuMiniBlockImg" />
                  <div className="navbarSubMenuMiniBlockInfo">
                    <p className="navbarSubMenuMiniBlockTitle">Documents</p>
                    <p className="navbarSubMenuMiniBlockText">All document types translated</p>
                  </div>
                </div> */}
                <div className="navbarSubMenuMiniBlock" onClick={(e) => mobileMenuTransition('/use-cases',e)}>
                  <img src={require('../assets/gavel.png')} alt="Gavel" className="navbarSubMenuMiniBlockImg" />
                  <div className="navbarSubMenuMiniBlockInfo">
                    <p className="navbarSubMenuMiniBlockTitle">Use Cases</p>
                    <p className="navbarSubMenuMiniBlockText">Explore common use cases</p>
                  </div>
                </div>
              </div>
              <div className="mobileMenuLinksLine">
                <p className="mobileMenuLink" onClick={(e) => transitionTo('/reviews',e)}>Reviews</p>
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/about',e)}>About</p>
              </div>
              <div className="mobileMenuLinksLine">
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/business',e)}>Businesses</p>
                {/* <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/jobs',e)}>Jobs</p> */}
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/order-lookup',e)}>Order Lookup</p>
              </div>
              <div className="mobileMenuLinksLine">
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/faq',e)}>FAQs</p> 
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/verify',e)}>Doc Verification</p>
              </div>
              <div className="mobileMenuLinksLine">
                {/* <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/guides',e)}>Guides</p> */}
                { !signedIn
                ? <p className="mobileMenuLink" onClick={() => changeLoginOpen()}>Sign In</p>
                : userData && userData.role === 'client'
                  ? <p className="mobileMenuLink" onClick={() => signOut()}>Sign Out</p>
                  : userData ? <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/account/profile',e)}>Account</p> : null
                }
                { !signedIn
                ? <p className="mobileMenuLink" onClick={() => changeCreateAccountOpen()}>Create Account</p>
                : userData && userData.role === 'client'
                  ? <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/account/profile',e)}>Account</p>
                  : userData ? <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/office/translations',e)}>{userData.role === 'owner' ? 'Owner Portal' : userData.role === 'Support' ? 'Support Portal' : 'Translator Portal'}</p> : null
                }
              </div>
              <div className="mobileMenuLinksLine">
                {signedIn && userData && userData.hasTeam && <>
                 {/* <img className="newIcon" src={NewIcon} alt=""/> */}
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/team/members',e)}>My Team</p>
                </>}
                <p className="mobileMenuLink" onClick={(e) => mobileMenuTransition('/contact',e)}>Contact</p>
                { !signedIn
                ? null
                : userData && userData.role === 'client'
                  ? null
                  : userData ? <p className="mobileMenuLink" onClick={() => signOut()}>Sign Out</p> : null
                }
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
    </>
  );
}

export { Navbar };