import React, { useContext } from "react";
import "./LandingPage.css";

import { ReactComponent as WhiteRightCircle } from "../../assets/icons/RightCircle.svg";
import { ReactComponent as GaryRightCircle } from "../../assets/icons/GrayRightCircle.svg";
import { ReactComponent as StopCircle } from "../../assets/icons/StopSign.svg";

import "./TranslationServiceSection.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useScreenTransition } from "../../Hooks/useScreenTransition";
import { AuthContext } from "../../Context/AuthContext";

const TranslationServiceSection = () => {
  const { transitionTo } = useScreenTransition();
  const { signedIn } = useContext(AuthContext);
  return (
    <div className="translationServiceSection">
      <div className="flex flex-col justify-center items-center gap-4 ServiceHeader">
        <h2 className="section-title">Translation Services</h2>
        <p className="Service-section-subtitle">
          We offer certified and standard translation services. Both are
          performed by the same professional translators, but the style and
          deliverables differ.
        </p>
      </div>

      <div className=" service-card-container">
        <div className="service-card bg-blue">
          <div className=" service-card-content">
            <h3 className=" service-card-title text-white ">
              Certified Translation
            </h3>
            <p className="service-card-subtitle text-white">
              Includes our certification for official use with USCIS, courts,
              universities, and other institutions.
            </p>

            <div className="flex-container">
              <span className="price-tag">$24.95</span>
              <span className="text-muted">/ USD page</span>
            </div>
          </div>
          <hr className="crad-divider" />
          <div className="card-features-container">
            <h3 className="card-features-title text-light-gray">
              Standard Features
            </h3>

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Translation Style</div>
              <div className="feature-vlaue">Word-For-Word</div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className=" dashed-line  border-white " />

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Delivery Format</div>
              <div className="feature-vlaue">PDF</div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Professional Translation</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Revisions Included</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Formatting Included</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Signed/Stamped Certification</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <h3 className="card-features-title text-light-gray">
              Optional Features
            </h3>

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Expedited Turnaround</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <div className="card-features-row text-light-gray">
              <div className="feature-item">Notarization</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line" /> */}
            <div className="dashed-line  border-white " />

            <div className="card-features-row text-light-gray mb-2">
              <div className="feature-item">Hard Copy</div>
              <div className="feature-vlaue">
                <WhiteRightCircle />
              </div>
            </div>

            <div className="service-card-content">
              <button
                className="orange-button"
                onClick={(e) =>
                  transitionTo(
                    `${
                      signedIn && firebase.auth().currentUser
                        ? "/order/documents"
                        : "/order/contact"
                    }`,
                    e
                  )
                }
              >
                Order Now
              </button>
            </div>
          </div>
        </div>

        <div className="bg-white service-card">
          <div className="service-card-content">
            <h3 className="service-card-title ">Standard Translation</h3>
            <p className="service-card-subtitle">
              For business and personal purposes, such as CVs, client intake
              forms, and marketing materials.
            </p>

            <div className="flex-container">
              <span className="price-tag">$0.10</span>
              <span className="text-muted">/ USD word</span>
            </div>
          </div>
          <hr className="crad-divider" />

          <div className="card-features-container">
            <h3 className="card-features-title">Standard Features</h3>

            <div className="card-features-row">
              <div className="feature-item">Translation Style</div>
              <div className=" feture-value text-gary">Interpretative</div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className=" dashed-line border-gray" />

            <div className="card-features-row ">
              <div className="feature-item">Delivery Format</div>
              <div className=" feture-value text-gary">DOCX</div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <div className="card-features-row ">
              <div className="feature-item">Professional Translation</div>
              <div className="feture-value">
                <GaryRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <div className="card-features-row ">
              <div className="feature-item">Revisions Included</div>
              <div className="feture-value">
                <GaryRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <div className="card-features-row ">
              <div className="feature-item">Formatting Included</div>
              <div className=" feture-value text-gary">Optional</div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <div className="card-features-row ">
              <div className="feature-item">Signed/Stamped Certification</div>
              <div className="feture-value">
                <StopCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <h3 className="card-features-title">Optional Features</h3>

            <div className="card-features-row">
              <div className="feature-item">Expedited Turnaround</div>
              <div className="feture-value">
                <GaryRightCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <div className="card-features-row">
              <div className="feature-item">Notarization</div>
              <div className="feture-value">
                <StopCircle />
              </div>
            </div>

            {/* <hr className="my-4 custom-dashed-line-gray" /> */}
            <div className="dashed-line border-gray" />

            <div className="card-features-row mb-2">
              <div className="feature-item">Hard Copy</div>
              <div className="feture-value">
                <StopCircle />
              </div>
            </div>

            <div className="service-card-content">
              <button
                className="orange-button"
                onClick={(e) => transitionTo("/order/contact", e)}
              >
                Order Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TranslationServiceSection;
