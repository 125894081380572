import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { Check, X } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { FormTextInput } from '../Components/FormTextInput';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';

import { useEnterKey } from '../Hooks/useEnterKey';

import './Verify.css';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const Verify = ({ windowSize }) => {

  const { setColor } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const [certificateID, setCertificateID] = useState('');
  const [password, setPassword] = useState('');

  const [authentic, setAuthentic] = useState('');

  const [downloadLinkName, setDownloadLinkName] = useState('');
  const [downloadLink, setDownloadLink] = useState('');

  const [submitButton, setSubmitButton] = useState('Verify');

  const handleLookup = async () => {
    setSubmitButton('Verifying...');

    axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/verifyCredentials",
      data: {
        certificateID: certificateID,
        password: password
      }
    })
      .then((response) => {
        setAuthentic(true);
        setDownloadLink(response.data.downloadLink);
        setDownloadLinkName(response.data.downloadLinkName);
        setSubmitButton('Verify');
      })
      .catch((error) => {
        setAuthentic(false);
        setDownloadLink('');
        setDownloadLinkName('');
        setSubmitButton('Verify');

        if (error.response.status === 500) {
          showNotification('Error', 'An error ocurred verifying the document. Try again. If it persists, please contact Support.', 'error');
        }
      });
    
  }

  const triggerLookup = () => {
    if (!(certificateID === "" || password === "")) {
      handleLookup();
    }
  }

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  useEnterKey(() => triggerLookup());

  return (
    <>
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
    {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="createAccountContainer">
        <div className="createAccountInfoContainer">
          <h1 className="documentsHeading" style={{ width: '100%' }}>Document Verification</h1>
          <h2 className="subheading" style={{ width: '100%' }}>Verify the authenticity of the document provided to you and compare your copy against the original translated by eVerbary, LLC.</h2>
          <div className="verifySeparator" />
          <FormTextInput placeholder="Certificate ID*" value={certificateID} onChange={setCertificateID} color='#666' />
          <FormTextInput placeholder="Password*" value={password} onChange={setPassword} color='#666' />
          <div className="createAccountFormSeparator" />
          <AltAnimatedButton text={submitButton} disabled={certificateID === "" || password === ""} onClick={handleLookup} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.6 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
        </div>
        <div className="createAccountFormContainer">
          {authentic !== '' && <div className="verifyAuthenticContainer">
            <div className="verifyBigCheckContainer" style={{ backgroundColor: authentic ? '#18b7bb' : '#dc3545' }}>
              {authentic ? <Check className="verifyBigCheck" /> : <X className="verifyBigCheck" />}
            </div>
            <p className="verifyCheckTitle">{authentic ? "Your credentials match our records!" : "We couldn't find any document with those credentials."} </p>
          </div>}
          {authentic === true && downloadLink !== '' &&
            <div className="verifyLinkContainer">
              <p className="verifyLinkText">
                You can download your copy from the following link and compare it against the original translated by eVerbary, LLC:
              </p>
              <a className="verifyLinkMain" href={downloadLink} target="_blank" rel="noopener noreferrer" style={{ color: '#444' }}>{downloadLinkName}</a>
              <p className="verifyLinkText">
                If these files match, you can consider the Certified translation valid and authentic. If you suspect fraud, please email us at <a href="mailto:support@everbary.com" target="_blank" rel="noopener noreferrer" style={{ color: '#444' }}>support@everbary.com</a> immediately.
              </p>
            </div>
          }
          {authentic === true && downloadLink === '' &&
            <div className="verifyLinkContainer">
              <p className="verifyLinkText">
                However, the translated file is still unavailable. Our Support team will upload it once the order is approved.
              </p>
            </div>
          }
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Verify;