export const AccountType = {
    CLIENT: 'client',
    OWNER: 'owner',
    SUPPORT: 'support',
    SUPPORTPLUS: 'support-plus',
    TRANSLATER: 'translator',
}

export const AccountStatus = {
    ACTIVE: 'Active',
    PAUSED: 'Paused',
    TEST: 'Test'
}

export const OrderStatus = {
    DRAFT: 'Draft',
    NEW: 'New',
    ASSIGNED: 'Assigned',
    INPROGRESS: 'In Progress',
    QUALITYCHECK: 'Quality Check',
    AWAITSAPPROVAL: 'Awaits Approval',
    APPROVED: 'Approved',
    CANCELLED: 'Cancelled',
    REVISION: 'Revision',
    REFUNDED: 'Refunded',
}