import React, { useState, useContext, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Navbar } from "../../Components/Navbar";
import { AccountFooter } from "../../Components/AccountFooter";

import { useScreenTransition } from "../../Hooks/useScreenTransition";

import { AuthContext } from "../../Context/AuthContext";
import { TransitionContext } from "../../Context/TransitionContext";
import { NotificationContext } from "../../Context/NotificationContext";

import { languages } from "../../Data/languages";

import "./Orders.css";
import { DataContext } from "../../Context/DataContext";
import { AlertCircle, AlertOctagon, Edit, Save } from "react-feather";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import SaveIcon from "../../assets/SaveIcon.png";
import { FormTextInput } from "../../Components/FormTextInput";
import PaymentsPopUp from "./PaymentsPopUp";
import TopBar from "../../Components/TopBar";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pageLimit = 20;

const TeamOrders = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn } = useContext(AuthContext);
  const { userData } = useContext(DataContext);

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);
  const [editNotes, setEditNotes] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [editRef, setEditRef] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [showAdvOption, setShowAdvOption] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  const [orderNumSearch, setOrderNumSearch] = useState("");
  const [triggerFilter, setTriggerFilter] = useState(true);
  const [languageFilter, setLanguageFilter] = useState("");
  const [reqBySearch, setReqBySearch] = useState("");
  const [referenceSearch, setReferenceSearch] = useState("");
  const referenceSearchRef =  useRef();

  const [teamUsers, setTeamUsers] = useState([]);
  
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    const applyReferenceSearch = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const referenceSearchRefVar = referenceSearchRef.current;

    referenceSearchRef.current.addEventListener("keyup", applyReferenceSearch);

    return () => {
      referenceSearchRefVar.removeEventListener("keyup", applyReferenceSearch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  useEffect(()=> {
    if(userData && userData.teamId === "" && !userData.hasTeam){
      transitionTo('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])


  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(()=> {
    if(signedIn && userData && userData.hasTeam){
      const firestore = firebase.firestore();

      firestore.collection("users").where("teamId", "==", userData.teamId).get().then(usersSnap => {
        const data = usersSnap.docs.map(doc => {
          return {id: doc.id, ...doc.data()}
        })
        setTeamUsers(data);
      })

    }
  }, [signedIn, userData])

  useEffect(() => {
    if (!signedIn) {
      transitionTo("/sign-in");
    } else {
      sleep(800).then(() => {
        if (firebase.auth().currentUser && userData) {
          const firestore = firebase.firestore();

          let query = firestore.collection("orders")

          if(orderNumSearch !== ""){

            query = query.where("orderNumber", "==", parseInt(orderNumSearch,10));

          }

          if (languageFilter !== '') {
            query = query.where('languageTo', '==', languageFilter);
          };
          if (reqBySearch !== '') {
            query = query.where('name', '==', reqBySearch);
          };
          if (referenceSearch !== '') {
            query = query.where('referenceSearch', '>=', referenceSearch.toLowerCase()).orderBy("referenceSearch", "asc");
          };
          if (startDate !== '' && endDate !=='' && referenceSearch==='' && showAdvOption) {
            const start = new Date(startDate);
            const end = new Date(endDate);
            query = query.where('timestamp', '>=', start.getTime()).where('timestamp', '<=', end.getTime());
          };

            query.where("teamId", "==", userData.teamId)
            .orderBy("timestamp", "desc")
            .limit(pageLimit)
            .get()
            .then((querySnapshot) => {
              nextPointer.current =
                querySnapshot.docs[querySnapshot.docs.length - 1];

                const orders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
                if(referenceSearch !==""){
                  setOrders(orders.filter(order => order.referenceSearch.includes(referenceSearch.toLowerCase())));
                }else{
                  setOrders(orders);
                }
              setLoadedOrders(true);
            })
            .catch((error) => {
              console.log(error);
              setLoadedOrders(true);
              showNotification(
                "Error",
                "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
                "error"
              );
            });
        } else {
          setLoadedOrders(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, userData, triggerFilter, languageFilter, reqBySearch, startDate, endDate, showAdvOption]);

  const loadMoreOrders = () => {
    if (nextPointer.current && userData) {
      const firestore = firebase.firestore();

      let query = firestore.collection("orders")

      if(orderNumSearch !== ""){

        query = query.where("orderNumber", "==", orderNumSearch);

      }
      if (languageFilter !== '') {
        query = query.where('languageTo', '==', languageFilter);
      };
      if (reqBySearch !== '') {
        query = query.where('name', '==', reqBySearch);
      };
      if (referenceSearch !== '') {
        query = query.where('referenceSearch', '>=', referenceSearch.toLowerCase()).orderBy("referenceSearch", "desc");
      };
      if (startDate !== '' && endDate !=='' && referenceSearch==='') {
        const start = new Date(startDate);
        const end = new Date(endDate);
        query = query.where('timestamp', '>=', start.getTime()).where('timestamp', '<=', end.getTime());
      };

        query.where("teamId", "==", userData.teamId)
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get()
        .then((querySnapshot) => {
          nextPointer.current =
            querySnapshot.docs[querySnapshot.docs.length - 1];

            const orders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
                if(referenceSearch !==""){
                  setOrders((o) => o.concat(orders.filter(order => order.referenceSearch.includes(referenceSearch.toLowerCase()))));
                }else{
                  setOrders((o)=> o.concat(orders));
                }
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
            "error"
          );
          //console.log("Error getting documents: ", error);
        });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return (
      splitted[1] +
      " " +
      splitted[2] +
      ", " +
      splitted[3] +
      " @ " +
      date.toTimeString().substr(0, 5)
    );
  };

  const getLanguage = (language) => {
    if (languages.find((lang) => lang.value === language)) {
      return languages.find((lang) => lang.value === language).label;
    } else {
      return "";
    }
  };

  const getTotal = (order) => {
    let total = 0;
    const orderDate = order ? new Date(order.timestamp) : new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (order && order.certified) {
      if (order.pagesCount !== "") {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== "") {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (order && order.expedited) {
      if (orderDate > priceUpdateDate) {
        total = total + total * 0.4;
      } else {
        total = total + total * 0.6;
      }
    }

    if (order && order.notarization) {
      total = total + 34.9;
    }

    if (order && order.hardCopy) {
      if (orderDate >= priceUpdateDate) {
        if (
          ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
            order.hardCopyExtra.country
          )
        ) {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 49.95;
          } else {
            total = total + 78.95;
          }
        }
      } else {
        if (
          ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
            order.hardCopyExtra.country
          )
        ) {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 12.95;
          } else {
            total = total + 29.95;
          }
        } else {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 39.95;
          } else {
            total = total + 67.95;
          }
        }
      }
    }

    if (order && order.hardCopy && order.hardCopyExtra.requireSignature) {
      if (orderDate >= priceUpdateDate) {
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  };

  const getDiscount = (order) => {
    if (!order || !order.discount || order.discount === 0) {
      return 0;
    } else {
      const orderDate = order ? new Date(order.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        let total = 0;

        if (order && order.certified) {
          if (order.pagesCount !== '') {
            total = total + parseInt(order.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (order) {
          if (order.wordCount !== '') {
            total = total + parseInt(order.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * order.discount / 100;
      }
      return (getTotal(order) * order.discount) / 100;
    }
  };

  const formatTotal = (value) => {
    return "$" + value.toFixed(2);
  };

  const updateOrder = (orderId, data) => {
      const firestore = firebase.firestore();

    firestore.collection("orders").doc(orderId).update(data)
    .then(() => {
        setOrderNotes("");
        setEditNotes("");
        setEditRef("");
        setOrderRef("");
        setTriggerFilter(tr => !tr);
        showNotification("Order Updated", "Order updated succesfully", "success");
    })
    .catch((error) => {
        console.error(error);
        showNotification("Error","Error updating document, please try again.", "error");
    });
  }

  const resetAll = () => {

    setOrderNumSearch("");
    setStartDate("");
    setEndDate("");
    setReqBySearch("");
    setReferenceSearch("");
    setLanguageFilter("");
    setTriggerFilter(trig => !trig);

  }

  console.log(teamUsers)

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
      <animated.div style={accountMenuProps} className="accountSidebar sideBar">
            <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Team' : 'Team Menu'}</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/members',e)}>Members</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/shipping',e)}>Shipping</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/quotes',e)}>Quotes</p>
            <p className="accountSidebarLink" style={{ color: '#666' }}>Orders</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/options',e)}>Options</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/payments',e)}>Payments</p>
        </animated.div>
        <div
          className="accountMainContainer"
        >
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Orders</h1>
            {windowSize.width > 1024 && <div className="warningContainer">
              <div>
              <AlertOctagon className="editBtn"/>
              </div>
              <div>
                <div className="quoteDetailsTitle">Your Feedback Matters!</div>
                <p className="quoteDetailsSub">We're excited to introduce a new section on our website. While we've done extensive testing, problems can happen. If you come across any issues, kindly let us know at alejo@everbary.com. Your feedback is valuable in helping us improve.</p>
              </div>
            </div>}
              <div className="">
               <FormTextInput placeholder="Search by Order no." value={orderNumSearch} onChange={setOrderNumSearch} onKeyDown={(e)=> (e.keyCode === 13) && setTriggerFilter(tf => !tf)} color='#666' />
                {/* <input type="text" className="userNameFilter" ref={orderNumSearchRef} value={orderNumSearch} onChange={(e) => setOrderNumSearch(e.target.value)} placeholder="Search by order number" /> */}
                <p className="quoteDetailsTitle advOption" onClick={()=> setShowAdvOption(show => !show)}>Advanced options</p>
            </div>
          </div>

         {windowSize.width < 1024 && <div style={{width: "100%", display: "flex", justifyContent: "center"}}> 
            <div className="warningContainer">
                <div>
                <AlertOctagon className="editBtn"/>
                </div>
                <div>
                  <div className="quoteDetailsTitle">Your Feedback Matters!</div>
                  <p className="subText">We're excited to introduce a new section on our website. While we've done extensive testing, problems can happen. If you come across any issues, kindly let us know at alejo@everbary.com. Your feedback is valuable in helping us improve.</p>
                </div>
              </div>
            </div>}
            <div className={`searchFilterRow ${showAdvOption ? 'showAnim': 'hideAnim'}`}>
            <div style={{minWidth: "130px"}}>
              <DatePicker
              dateFormat="dd/MM/yyyy"
              className="userNameFilter" showMonthDropdown showYearDropdown
                selected={startDate}
                onChange={(date) => {setStartDate(date); setReferenceSearch("");}}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="select start date"
              />
              <DatePicker
              dateFormat="dd/MM/yyyy"
              className="userNameFilter" showMonthDropdown showYearDropdown
                selected={endDate}
                onChange={(date) => {setEndDate(date); setReferenceSearch("");}}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="select end date"
              />
            </div>

            <select value={reqBySearch} onChange={(e) => setReqBySearch(e.target.value)} className="quoteDetailsSelect">
                  <option key="" value="">No name selected</option>
                  {teamUsers.map(user => 
                    <option key={user.id} value={user.name}>{user.name}</option>
                  )}
                </select>
                
            <input type="text" className="userNameFilter" ref={referenceSearchRef} value={referenceSearch} onChange={(e) => setReferenceSearch(e.target.value)} placeholder="Reference" />

                <select value={languageFilter} onChange={(e) => setLanguageFilter(e.target.value)} className="quoteDetailsSelect">
                  <option key="" value="">No language selected</option>
                  {languages.filter(language => language !== 'english').map(language => 
                    <option key={language.value} value={language.value}>{language.label}</option>
                  )}
                </select>

            <p className="quoteDetailsTitle" onClick={()=> resetAll()}>RESET</p>
            </div>

          <div className="quoteHeaderLine">
            <p className="quoteDetailsHeaderColumn">Details</p>
            <p className="quoteDetailsHeaderColumn">
             {windowSize.width > 768 ? "Ordered By" : "Req. By"}
            </p>
            <p className="quoteDetailsHeaderColumn quoteStatusHeaderColumn">
              Status
            </p>
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn">
              Total
            </p>
            {windowSize.width > 768 && <div className="quoteDetailsHeaderColumn" style={{overflow:"visible"}} >
              Your Reference
              <div style={{position: "relative"}}>
                <AlertCircle className="editBtn tooltip" />
                <div className="refToolTip">This reference will be included in all emails and the invoice.</div>
                </div>
            </div>}
          </div>
          {loadedOrders && orders.length > 0 ? (
            orders.map((order) => (
              <div key={order.id} style={{width:"100%"}}>
              <div className="quoteLine">
                <div className="quoteDetailsLineColumn">
                  <div className="quoteDetailsContainer">
                    
                    {windowSize.width > 767 ? <p
                      className="quoteDetailsTitle"
                      onClick={(e) =>
                        transitionTo(`/account/order/${order.id}`, e)
                      }
                    >{`Order #${order.orderNumber}`}</p> :
                    <p
                      className="quoteDetailsTitle"
                      onClick={(e) =>
                        transitionTo(`/account/order/${order.id}`, e)
                      }
                    >{`#${order.orderNumber}`}</p>}
                    <p className="quoteDetailsSub">{`${getLanguage(
                      order.languageFrom
                    )} to ${getLanguage(order.languageTo)} `}<br/> {`${formatDate(order.timestamp)}`}</p>
                  </div>
                </div>
                <div className="quoteDetailsLineColumn nameText" >
                  {order.name}
                </div>
                <div className="quoteDetailsLineColumn quoteStatusHeaderColumn">
                  <div
                    className="quoteStatusContainer"
                    style={{
                      width:
                        windowSize.width > 1024 ||
                        windowSize.orientation === "landscape"
                          ? "40%"
                          : windowSize.width > 767
                          ? "50%"
                          : "60%",
                      backgroundColor:
                        order.status === "created"
                          ? "#F6C907"
                          : order.status === "new"
                          ? "#FF7C1D"
                          : order.status === "assigned" ||
                            order.status === "in-progress" ||
                            order.status === "support-review" ||
                            (order.status === "revision" &&
                              !order.supportApproved)
                          ? "#1491F8"
                          : order.status === "user-review"
                          ? "#30d5c8"
                          : order.status === "revision"
                          ? "#333"
                          : order.status === "cancelled"
                          ? "#E1504A"
                          : "#17BC5B",
                    }}
                  >
                    {order.status === "created"
                      ? "CREATED"
                      : order.status === "new"
                      ? "NEW ORDER"
                      : order.status === "assigned" ||
                        order.status === "in-progress" ||
                        order.status === "support-review" ||
                        (order.status === "revision" && !order.supportApproved)
                      ? "IN PROGRESS"
                      : order.status === "user-review"
                      ? "READY"
                      : order.status === "revision"
                      ? "REVISION"
                      : order.status === "cancelled"
                      ? "CANCELLED"
                      : "COMPLETED"}
                  </div>
                </div>
                <div className="quoteDetailsLineColumn quoteTotalHeaderColumn">
                  {formatTotal(getTotal(order) - getDiscount(order))}
                </div>
                
                {windowSize.width > 768 && <div className="quoteDetailsLineColumn referenceText">
                    
                    {order.id === editRef ? <div className="inputWrap"><FormTextInput focus={true} onKeyDown={(e) => {
                      e.key === "Enter" && order.id === editRef &&  updateOrder(order.id, {reference: orderRef, referenceSearch:  orderRef.toLowerCase()})}} 
                      onChange={setOrderRef} value={orderRef}/></div> : <div className="refText" style={{width:"80%"}} onClick={()=> {
                          setEditRef(order.id); setOrderRef(order.reference ? order.reference : '');
                        }} >{order.reference ? order.reference : 'No Reference'}</div>}
                    {order.id !== editRef ? <Edit className="editBtn blueColor" onClick={()=> {
                        setEditRef(order.id); setOrderRef(order.reference ? order.reference : '');
                      }}/> : 
                        <img  className="editBtn"  onClick={()=> updateOrder(order.id, {reference: orderRef, referenceSearch:  orderRef.toLowerCase()})} src={SaveIcon} alt=""/>
                      }
                  </div>}
              </div>

              {!order.teamNotes && order.id !== editNotes ? <div className="teamNote" onClick={()=> {
                      setEditNotes(order.id); setOrderNotes(order.teamNotes ? order.teamNotes : '');
                    }}>Add a Team Note</div>
                  : order.id !== editNotes && <div className="notes referenceText">Team Notes:
                  
                   <Edit className="editBtn" onClick={()=> {
                      setEditNotes(order.id); setOrderNotes(order.teamNotes ? order.teamNotes : '');
                    }}/> 
                    <div style={{width:"80%",  cursor:"pointer"}} onClick={()=> {
                        setEditNotes(order.id); setOrderNotes(order.teamNotes ? order.teamNotes : '');
                      }} >{order.teamNotes ? order.teamNotes : 'No Notes'}</div>

                </div>
              }
                        
              {order.id === editNotes  && <div className="notes referenceText">Team Notes:
                      <Save className="editBtn"  onClick={()=> orderNotes !=="" && updateOrder(order.id, {teamNotes: orderNotes})}/>
                      <div className="inputWrap"><FormTextInput color='#666' focus={true} onKeyDown={(e) => {
                      e.key === "Enter" && order.id === editNotes && orderNotes !=="" && updateOrder(order.id, {teamNotes: orderNotes})}} 
                        onChange={setOrderNotes} value={orderNotes}/></div>
                  
                </div>}
              </div>

                
            ))
          ) : loadedOrders ? (
            <div className="loadingQuotes">You have no orders.</div>
          ) : (
            <div className="loadingQuotes">Loading orders...</div>
          )}
          {nextPointer.current ? (
            <p
              onClick={() => loadMoreOrders()}
              className="loadMoreQuotesButton"
            >
              Load more orders...
            </p>
          ) : null}
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
      <PaymentsPopUp windowSize={windowSize} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}/>
    </div>
    </>
  );
};

export default TeamOrders;
