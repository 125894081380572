import React, { useEffect, useContext,useState } from 'react';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { TransitionContext } from '../Context/TransitionContext';

import './Documents.css';
import './UseCases.css';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';


const UseCases = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const { transitionTo } = useScreenTransition();

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  return (
    <>
      <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
      <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>
    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="documentsHeading">Use Cases</h1>
          <h2 className="documentsSubheading">
            While the use cases for our <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified</span> and <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard</span> translation services are endless, there are some common use cases that we see daily, which we’ve detailed below to add a bit of additional information that you might find helpful as you navigate the translation process.
            <br/><br/>
            If you have questions about your specific use case, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span>, and we’ll assist in any way we can.
          </h2>
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'useCases.webp' : 'useCases.png'}`)} alt="Person graduating" className="useCasesHeaderImg" /> : null}
      </div>
      <div className="useCasesContainer">
        <h3 className="useCasesTitle">Certified Translation Use Cases</h3>
        <div className="useCasesBlockContainer">
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/immigration',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/torch.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Immigration<br/>USCIS Acceptance</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/government',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/government.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Government<br/>Official Uses</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/academic',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/hat.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Academic<br/>University Admissions</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/financial',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/mortgage.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Financial<br/>Mortgage Processing</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/legal',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/legal.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Legal<br/>Court Cases</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/medical',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/estet.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Medical<br/>Health History</p>
          </div>
        </div>
      </div>
      <div className="useCasesContainer">
        <h3 className="useCasesTitle">Standard Translation Use Cases</h3>
        <div className="useCasesBlockContainer">
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/legalStandard',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/contracts.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Legal<br/>Documents</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/digital',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/digital.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Business<br/>Digital Content</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/communications',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/communications.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Business<br/>Communications</p>
          </div>
          <div className="useCasePageBlock" /*onClick={(e) => transitionTo('/use-case/documents',e)}*/>
            <div className="useCasePageImgContainer">
              <img src={require('../assets/documentsUseCase.png')} alt="Statue of Liberty Torch" className="useCasePageImg" />
            </div>
            <p className="useCasePageBlockText">Business<br/>Documents</p>
          </div>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default UseCases;