import React, { useState, useContext, useEffect, useRef } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import { Edit, Watch } from 'react-feather';

import { NotificationContext } from '../../Context/NotificationContext';

import './Clients.css';
// import UpdateOrdersOverlay from '../../Components/UpdateOrdersOverlay';
import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { DataContext } from '../../Context/DataContext';

const pageLimit = 50;

const Clients = ({ windowSize }) => {

  // const history = useHistory();

  const { transitionTo } = useScreenTransition()

  const { showNotification } = useContext(NotificationContext);
  const { userData } = useContext(DataContext);

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);

  const orderSearchRef = useRef();
  const [orderSearch, setOrderSearch] = useState('');

  const clientSearchRef = useRef();
  const [clientSearch, setClientSearch] = useState('');

  const [statusFilter, setStatusFilter] = useState('');

  const [triggerFilter, setTriggerFilter] = useState(true);

  let firstLoad = useRef();
  if (!firstLoad.current) firstLoad.current = true;

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    const applyOrder = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const orderSearchRefVar = orderSearchRef.current;
    const clientSearchRefVar = clientSearchRef.current;

    orderSearchRef.current.addEventListener("keyup", applyOrder);
    clientSearchRef.current.addEventListener("keyup", applyOrder);

    return () => {
      orderSearchRefVar.removeEventListener("keyup", applyOrder);
      clientSearchRefVar.removeEventListener("keyup", applyOrder);
    }
  }, []);

  useEffect(() => {
    firstLoad.current = true;

    const firestore = firebase.firestore();

    let query = firestore.collection('orders');

    if (orderSearch !== '') {
      query = query.where('orderNumber', '==', parseInt(orderSearch,10));
    };

    if (clientSearch !== '') {
      const clientSearchCapital = capitalize(clientSearch.toLowerCase())
      query = query.where('name', '>=', clientSearchCapital).orderBy('name', 'asc').orderBy('timestamp', 'desc');
    };

    if (statusFilter !== '') {
      query = query.where('status', '==', statusFilter);
    };

    if(clientSearch === ''){
      query = query.orderBy('timestamp', 'desc');
    }

    const unsubscribe = 
        query.limit(pageLimit)
        .onSnapshot(querySnapshot => {
      const clientSearchCapital = capitalize(clientSearch.toLowerCase())

          if(clientSearch !== ""){
              if (firstLoad.current) {
                nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
                firstLoad.current = false;
                
                const orders= [] 
                querySnapshot.docs.forEach(doc => {
                  if(doc.data().name.includes(clientSearchCapital)){
                    orders.push({ ...doc.data(), id: doc.id })
                  }
                }
                  )
                setOrders(orders.sort((a,b)=> b.timestamp-a.timestamp));
                setLoadedOrders(true);
              } else {
                let newOrders = [];
    
                querySnapshot.docChanges().forEach((change) => {
                  if (change.type === "added" && change.doc.data().name.includes(clientSearchCapital)) {
                    newOrders.push({ id: change.doc.id, ...change.doc.data() });
                  }
                });
                
                setOrders(u => newOrders.concat(u).sort((a,b)=> b.timestamp-a.timestamp));
                setLoadedOrders(true);
              }
          }else{
            if (firstLoad.current) {
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
              firstLoad.current = false;
  
              setOrders(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
              setLoadedOrders(true);
            } else {
              let newOrders = [];
  
              querySnapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  newOrders.push({ id: change.doc.id, ...change.doc.data() });
                }
              });
              
              setOrders(u => newOrders.concat(u));
              setLoadedOrders(true);
            }
          }
          
        }, error => {
          console.log(error);
          showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
          setLoadedOrders(true);
        });

    return () => unsubscribe();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFilter, statusFilter]);

  const loadMoreOrders = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      let query = firestore.collection('orders');

      if (orderSearch !== '') {
        query = query.where('orderNumber', '==', parseInt(orderSearch,10));
      };
  
      if (clientSearch !== '') {
        const clientSearchCapital = capitalize(clientSearch.toLowerCase())
        query = query.where('name', '>=', clientSearchCapital).orderBy('name', 'asc');
      };
  
      if (statusFilter !== '') {
        query = query.where('status', '==', statusFilter);
      };
  
      if(clientSearch === ''){
        query = query.orderBy('timestamp', 'desc');
      }

      query
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get().then((querySnapshot) => {
          const clientSearchCapital = capitalize(clientSearch.toLowerCase())
          if(clientSearch !== ""){
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];

              let oldOrders = [];
    
              querySnapshot.forEach((doc) => {
                if(doc.data().name.includes(clientSearchCapital)){
                  oldOrders.push({ id: doc.id, ...doc.data() });
                }
              });
              
              setOrders(u => u.concat(oldOrders).sort((a,b)=> b.timestamp-a.timestamp));
            }else{
              nextPointer.current = querySnapshot.docs[querySnapshot.docs.length-1];
    
              let oldOrders = [];
    
              querySnapshot.forEach((doc) => {
                oldOrders.push({ id: doc.id, ...doc.data() });
              });
              
              setOrders(u => u.concat(oldOrders));
            }

        })
        .catch((error) => {
          showNotification('Error', "There was an error loading the orders. Please, reload the page.", 'error');
          //console.log("Error getting documents: ", error);
        });
    }
  }

  const getExpedited = (order) => {
    const orderDate = new Date(order.timestamp);
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');
    let total = 0;

    if (order && order.certified) {
      if (order.pagesCount !== '') {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== '') {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }
    if(orderDate >= priceUpdateDate){
      return total * 0.4;
    }

    return total * 0.6;
  }

  const getTotal = (order) => {
    let total = 0;
    const orderDate = order ? new Date(order.timestamp): new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (order && order.certified) {
      if (order.pagesCount !== '') {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== '') {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (order && order.expedited) {
      total = total + getExpedited(order);
    }

    if (order && order.notarization) {
      total = total + 34.90;
    }

    if (order && order.hardCopy) {
      if(orderDate >= priceUpdateDate){
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country)) {
          if (order.hardCopyExtra.method === 'priority') {
              total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (order.hardCopyExtra.method === 'priority') {
            total = total + 49.95;
          } else {
            total = total + 78.95;
          }
        }
      }else{
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country)) {
          if (order.hardCopyExtra.method === 'priority') {
              total = total + 12.95;
          } else {
            total = total + 29.95;
          }
        } else {
          if (order.hardCopyExtra.method === 'priority') {
            total = total + 39.95;
          } else {
            total = total + 67.95;
          }
        }
      }
    }

    if (order && order.hardCopy && order.hardCopyExtra.requireSignature) {
      if(orderDate >= priceUpdateDate){
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  }

  const getDiscount = (order) => {
    if (!order || !order.discount || order.discount === 0) {
      return 0;
    } else {
      
      const orderDate = order ? new Date(order.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        let total = 0;

        if (order && order.certified) {
          if (order.pagesCount !== '') {
            total = total + parseInt(order.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (order) {
          if (order.wordCount !== '') {
            total = total + parseInt(order.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * order.discount / 100;
      }
      return (getTotal(order) * order.discount) / 100;
    }
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }


  const formatDiscount = value => {
    return '-$' + value.toFixed(2);
  }

  const capitalize = value => {
    if(value.length > 1){
    const array = value.split(" ");
    const capitalizeWordArray = array.map(data => {
      if(data.length > 1){
        return data.slice(0,1).toUpperCase() + data.slice(1,-1) + data.slice(-1)
      }
      return data.toUpperCase();
    });
    return capitalizeWordArray.join(" ");
    }else{
      return value.toUpperCase();
    }
  }

  return (
    <div className="translationsContainer">
      <div className="translationsInnerContainer">
        <div className="translationsTitleRow">
          <p className="translationsTitle">
            Clients
          {/* <UpdateOrdersOverlay/> */}
            </p>
          <div className="usersFunctionsContainer">
            <div className="userLanguageFilterContainer">
              <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} className="quoteDetailsSelect">
                <option key="" value="">All</option>
                <option key="created" value="created">Created</option>
                <option key="new" value="new">New</option>
                <option key="assigned" value="assigned">Assigned</option>
                <option key="in-progress" value="in-progress">In Progress</option>
                <option key="support-review" value="support-review">Support Review</option>
                <option key="client-review" value="user-review">Client Review</option>
                <option key="revision" value="revision">Revision</option>
                <option key="completed" value="completed">Completed</option>
                <option key="cancelled" value="cancelled">Cancelled</option>
              </select>
            </div>
            <div className="userLanguageFilterContainer">
              <input type="text" value={orderSearch} ref={orderSearchRef} onChange={(e) => setOrderSearch(e.target.value)} className="userNameFilter" placeholder="Search by order number" />
            </div>
            <div className="userLanguageFilterContainer" style={{ marginRight: 0 }}>
              <input type="text" value={clientSearch} ref={clientSearchRef} onChange={(e) => setClientSearch(e.target.value)} className="userNameFilter" placeholder="Search by client name" />
            </div>
          </div>
        </div>
        <div className="translationsTableHeader">
          <div className="usersHeaderColumn clientsOrderColumn">Order Number</div>
          {userData.role !== 'Support' && <div className="usersHeaderColumn clientsNameColumn">Name</div>}
          <div className="usersHeaderColumn clientsRatingColumn">Pages</div>
          <div className="usersHeaderColumn clientsRatingColumn">Rating</div>
          <div className="usersHeaderColumn clientsIconColumn">
            <Edit className="translationsColumnIcon" />
          </div>
          <div className="usersHeaderColumn clientsIconColumn">
            <Watch className="translationsColumnIcon" />
          </div>
          <div className="usersHeaderColumn clientsStatusColumn">Status</div>
          {userData.role !== 'Support' && <div className="usersHeaderColumn clientsTotalColumn">Total</div>}
        </div>
        { loadedOrders && orders.length > 0
        ? orders.map(order => 
          <div key={order.id} className="translationsTableLine">
            <div className="usersLineColumn clientsOrderColumn" style={{ cursor: 'pointer', color: '#609ddb' }} onClick={(e) =>transitionTo('/office/translation/' + order.id + "/details",e)}>{order.orderNumber}</div>
            {userData.role !== 'Support' && <div className="usersLineColumn clientsNameColumn" style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
              <span style={{ marginBottom: 5, fontWeight: 700, cursor: order.userID !== "" ? 'pointer' : 'default', color: order.userID !== "" ? '#609ddb' : '#666' }} onClick={(e) =>order.userID !== '' ? transitionTo('/office/client/' + order.userID,e) : null}>{order.name}</span>
              {order.email}
            </div> }
            <div className="usersLineColumn clientsRatingColumn">
              {order.pagesCount}
            </div>
            <div className="usersLineColumn clientsRatingColumn">
              {order.reviewRating}
            </div>
            <div className="usersLineColumn clientsIconColumn">
              { order.userRevisionRequested &&
                <Edit className="translationsColumnIcon" />
              }
            </div>
            <div className="usersLineColumn clientsIconColumn">
              { order.userReady && order.userReady > order.due &&
                <Watch className="translationsColumnIcon" />
              }
            </div>
            <div className="usersLineColumn clientsStatusColumn">
              <div className="translationsStatusContainer" style={{ backgroundColor: order.status === 'created' ? "#F6C907" : order.status === 'new' ? '#FF7C1D' : order.status === 'assigned' ? '#61dbfb' : order.status === 'in-progress' ? '#1491F8' : order.status === 'support-review' ? '#e91e63' : order.status === 'user-review' ? '#30d5c8' : order.status === 'revision' ? '#333' : order.status === 'cancelled' ? '#E1504A' : '#17BC5B' }}>
                { order.status === 'created' ? "CREATED" : order.status === 'new' ? "NEW ORDER" : order.status === 'assigned' ? 'ASSIGNED' : order.status === 'in-progress' ? 'IN PROGRESS' : order.status === 'support-review' ? 'SUPPORT' : order.status === 'user-review' ? 'CLIENT' : order.status === 'revision' ? 'REVISION' : order.status === 'cancelled' ? 'CANCELLED' : 'COMPLETED'}
              </div>
            </div>
      
            {userData.role !== 'Support' && <div className="usersLineColumn clientsTotalColumn" style={{ color: order.discount || order.hardCopy || order.expedited || order.notarization ? '#E1504A' : '#666', overflow:"visible" }}>
              {formatTotal(getTotal(order) - getDiscount(order))}
              { (order.discount || order.hardCopy || order.expedited || order.notarization) &&
                <div className="clientsExtraOverlay">
                  <div className="orderSummaryInfo">
                    <div className="orderSummaryInfoMain">
                      {order && <p className="clientsExtraText">{order.certified ? "Certified Translation" : "Standard Translation"}</p>}
                    </div>
                    {order && <p className="clientsExtraText" style={{ marginRight: 0 }}>{order.certified ? (order.pagesCount !== "" ? '$' + (parseInt(order.pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((order.wordCount !== '' ? '$' + (parseInt(order.wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>}
                  </div>
                  {order && order.notarization ? 
                  <div className="orderSummaryInfo">
                    <div className="orderSummaryInfoMain">
                      <p className="clientsExtraText">Notarization</p>
                    </div>
                    <p className="clientsExtraText" style={{ marginRight: 0 }}>$34.90</p>
                  </div> : null}
                  {order && order.expedited ? 
                  <div className="orderSummaryInfo">
                    <div className="orderSummaryInfoMain">
                      <p className="clientsExtraText">Expedited Turnaround</p>
                    </div>
                    <p className="clientsExtraText" style={{ marginRight: 0 }}>{formatTotal(getExpedited(order))}</p>
                  </div> : null}
                  {order && order.hardCopy && order.hardCopyExtra.country !== '' && order.hardCopyExtra.method !== '' ? 
                  <div className="orderSummaryInfo">
                    <div className="orderSummaryInfoMain">
                      <p className="clientsExtraText">{`USPS ${order.hardCopyExtra.method.charAt(0).toUpperCase() + order.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? 'International' : ''}`}</p>
                    </div>
                    <p className="clientsExtraText" style={{ marginRight: 0 }}>{`$${(new Date(order.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? (order.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (order.hardCopyExtra.method === 'priority' ? '49.95' : '78.95')) : ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(order.hardCopyExtra.country) ? (order.hardCopyExtra.method === 'priority' ? '12.95' : '29.95') : (order.hardCopyExtra.method === 'priority' ? '39.95' : '67.95')}`}</p>
                  </div> : null}
                  {order && order.hardCopy && order.hardCopyExtra.requireSignature ? 
                  <div className="orderSummaryInfo">
                    <div className="orderSummaryInfoMain">
                      <p className="clientsExtraText">Signature Required for Delivery</p>
                    </div>
                    <p className="clientsExtraText" style={{ marginRight: 0 }}>{`$${(new Date(order.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? 4.99 : 2.54}`}</p>
                  </div> : null}
                  {order && order.discount && order.discount > 0 ? 
                    <div className="orderSummaryInfo">
                      <div className="orderSummaryInfoMain">
                        <p className="clientsExtraText">{`Discount -${order.discount}%`}</p>
                      </div>
                      <p className="clientsExtraText" style={{ marginRight: 0 }}>{formatDiscount(getDiscount(order))}</p>
                    </div> : null}
                  <div className="clientSummaryTotal">
                    <p>Total</p>
                    <p>{formatTotal(getTotal(order) - getDiscount(order))}</p>
                  </div>
                </div>
              }
            </div>}
          </div>)
        : loadedOrders ? <div className="loadingTranslations">No orders.</div> : <div className="loadingTranslations">Loading orders...</div>
        }
        { loadedOrders && orders.length >= 50 && nextPointer.current &&
          <div className="loadMoreUsersButton" onClick={() => loadMoreOrders()}>
            Load more orders...
          </div>
        }
      </div>
    </div>
  );
};

export default Clients;