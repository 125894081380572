import React, { useState, useContext, useEffect } from 'react';
import { useSprings, animated, config } from 'react-spring';

import { Check, ChevronRight, Clock, Layout } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { AnimatedButton } from '../Components/AnimatedButton';
import { DocumentsDisplay } from '../Components/DocumentsDisplay';
import { LanguagesDisplay } from '../Components/LanguagesDisplay';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Standard.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from '../Context/AuthContext';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const Standard = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();
  
  const { signedIn } = useContext(AuthContext);

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const [faqValues, setFaqValues] = useState([false, false, false, false, false]);

  const faqSprings = useSprings(faqValues.length, faqValues.map(value => ({ maxHeight: value ? (windowSize.width > 1024 ? 'calc(60vw)' : windowSize.width > 767 ? 'calc(130vw)' : 'calc(350vw)') : (windowSize.width > 1024 ? 'calc(3.8vw)' : (windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 'calc(6vw)' : 'calc(4vw)') : (windowSize.orientation === 'portrait' ? 'calc(20vw)' : 'calc(4vw)'))), config: value ? config.molasses : config.default })));

  const toggleLine = index => {
    setFaqValues( faqValues.map((value, mIndex) => mIndex === index ? !value : false) );
  }

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }


  return (
    <>
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    
    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="heading">Professional Translation Services</h1>
          <h2 className="subheading">Get your documents or text-based content translated in 60+ languages by a professional translator for just $0.10 per word with 24 hour delivery.</h2>
          <p className="subheadingFootnote"></p>
          <AltAnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.6) : (windowSize.orientation === 'portrait' ? 6 : 2.2)} />
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'standard.webp' : 'standard.png'}`)} alt="People holding a regular translation" className="certifiedHeaderImg" /> : null}
      </div>
      <div className="certificationContainer">
        <div className="certificationItemContainer">
          <img src={require('../assets/ata-logo.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">ATA Corporate Member #273062</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-3.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">100% guaranteed acceptance by USCIS</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-1.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Trusted by +350 law firms</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-2.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Same-day delivery available</p>
        </div>
      </div>
      <div className="certifiedMainInfoContainer">
        <div className="certifiedMainInfoTextContainer">
          <h2 className="certifiedMainInfoTitle">Standard Translation</h2>
          <p className="certifiedMainInfoText">Standard translations are interpretive translations; they properly convey the true meaning of the source document. This type of translation is perfect for business or personal use of critical documents where certification isn’t required, but a high level human translation is in order to guarantee the quality of the final translation.
            <br/><br/>
            We deliver standard translations in an editable Microsoft Word format (DOCX). You are then free to modify, format, etc. the translation to your needs. We can translate directly in a formatted file, such as Powerpoint or Illustrator, upon request. Revisions are included free of charge with all of our translation services.
            <br/><br/>
            Standard translations are best suited for high importance documents such as employee manuals, financial statements, legal documents, business communications, and many other types of documents when certification isn’t required, but a high quality professional translation is still required.
            <br/><br/>
            eVerbary is among the largest providers of online translation services in the United States. Our services are utilized by tens of thousands businesses and individuals every year because we consistently deliver high quality translations quickly and affordably.
          </p>
        </div>
        <div className="certifiedMainInfoBlockContainer">
          <div className="certifiedMainInfoBlockHeader">
            <h3 className="certifiedMainInfoBlockHeaderTitle">Standard Translation</h3>
            <p className="certifiedMainInfoBlockHeaderPrice">$0.10</p>
            <p className="certifiedMainInfoBlockHeaderMini">per word</p>
          </div>
          <div className="certifiedMainInfoBlockMain">
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Translated by a professional</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Delivery starts at 24 hours</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Expedited turnaround available</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Delivered in editable format</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Revisions included</p>
            </div>
            <div className="servicesDisplayButtonsContainer">
              <AnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.2 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.4) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
              <p className="servicesDisplayButtonsMore" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>Request a Quote</p>
            </div>
          </div>
        </div>
      </div>
      <div className="standardExtraContainer">
        <h2 className="standardExtraTitle">Optional Services</h2>
        <p className="standardExtraSub">Services available to be ordered with your standard translation.</p>
        <div className="standardExtraBlocksContainer">
          <div className="standardExtraBlock">
            <Clock color='#18b7bb' size={windowSize.width > 1024 ? 25 : windowSize.width > 767 ? 22 : 18} />
            <div className="standardExtraBlockInfo">
              <p className="standardExtraBlockTitle">Expedited Turnaround</p>
              <p className="standardExtraBlockText">Your order will be prioritized and turnaround time for digital delivery reduced by 50%. $0.06 per word.</p>
            </div>
          </div>
          <div className="standardExtraBlock">
            <Layout color='#18b7bb' size={windowSize.width > 1024 ? 25 : windowSize.width > 767 ? 22 : 18} />
            <div className="standardExtraBlockInfo">
              <p className="standardExtraBlockTitle">Original Formatting</p>
              <p className="standardExtraBlockText">We can translate in Powerpoint, Illustrator, or other software to retain your original formatting. Pricing varies – <span className="hoveringTextLink" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>request a quote</span>.</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="useContainer">
        <div className="useCaseBlock">
          <div className="useCaseImgContainer" /*onClick={(e) => transitionTo('/use-case/legalStandard',e)}*/>
            <img src={require('../assets/contracts.png')} alt="Statue of Liberty Torch" className="useCaseImg" />
          </div>
          <h3 className="useCaseBlockText">Legal<br/>Contracts</h3>
        </div>
        <div className="useCaseBlock">
          <div className="useCaseImgContainer" /*onClick={(e) => transitionTo('/use-case/digital',e)}*/>
            <img src={require('../assets/digital.png')} alt="Statue of Liberty Torch" className="useCaseImg" />
          </div>
          <h3 className="useCaseBlockText">Business<br/>Digital Content</h3>
        </div>
        <div className="useCaseBlock nonMobileBlock">
          <div className="useCaseImgContainer" /*onClick={(e) => transitionTo('/use-case/communications',e)}*/>
            <img src={require('../assets/communications.png')} alt="Statue of Liberty Torch" className="useCaseImg" />
          </div>
          <h3 className="useCaseBlockText">Business<br/>Communications</h3>
        </div>
        <div className="useCaseInfoContainer">
          <p className="useCaseInfoTitle">Translation Services for Any Use Case</p>
          <p className="useCaseInfoSub">Learn about common use cases for our standard translation services. This service is best for critical business or personal texts where professional human translation is required to convey the true meaning of the text.</p>
          <div className="useCaseInfoButtonContainer" onClick={(e) => transitionTo('/use-cases',e)}>
            <p className="useCaseInfoButtonText">More use cases</p>
            <ChevronRight color='#18b7bb' size={20} />
          </div>
        </div>
      </div>

      <DocumentsDisplay />
      <LanguagesDisplay />
      <div className="certifiedFaqSection">
        <div className="certifiedFaqs">
          <h2 className="certifiedFaqTitle">Frequently Asked Questions</h2>
          <animated.div style={faqSprings[0]} className="certifiedFaqLine" onClick={() => toggleLine(0)}>
            <p className="certifiedFaqLineTitle">What is standard translation?</p>
            <p className="certifiedFaqLineText">Standard translations are typically used for business or personal purposes including employee manuals, emails, text messages, financial statements, press releases, legal contracts, blog posts, and many more. We translate both documents and text-based content. Standard translations are priced per word and delivered in an editable format.</p>
          </animated.div>
          <animated.div style={faqSprings[1]} className="certifiedFaqLine" onClick={() => toggleLine(1)}>
            <p className="certifiedFaqLineTitle">What will you deliver to me?</p>
            <p className="certifiedFaqLineText">Standard translations are delivered as an editable Microsoft Word document (.docx). If you need another format such as .pages, .txt, .rtf, etc. we can likely accommodate. Standard translations only include basic formatting. If you have complex formatting or need the translation to be completed in the document you supply, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> and include a detailed description of your needs.</p>
          </animated.div>
          <animated.div style={faqSprings[2]} className="certifiedFaqLine" onClick={() => toggleLine(2)}>
          <p className="certifiedFaqLineTitle">How long does translation take?</p>
          <p className="certifiedFaqLineText">Turnaround time depends on many factors including the word count, language pair, current demand, translator availability and complexity of the source document to name a few. In general, 1-3 page documents (up to 750 words) are delivered digitally in 24 hours or less. Expedited turnaround is available to reduce the turnaround time by approximately 50%. We will show an estimated turnaround time in the order page before you place it.</p>
        </animated.div>
          <animated.div style={faqSprings[3]} className="certifiedFaqLine" onClick={() => toggleLine(3)}>
            <p className="certifiedFaqLineTitle">Can you help me count the words in my documents?</p>
            <p className="certifiedFaqLineText">Yes, please <span className="hoveringTextLink" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>request a quote</span>. We’ll be happy to provide the count along with estimated turnaround time and the total price.</p>
          </animated.div>
          <animated.div style={faqSprings[4]} className="certifiedFaqLine" onClick={() => toggleLine(4)}>
            <p className="certifiedFaqLineTitle">Who will translate my documents?</p>
            <p className="certifiedFaqLineText">We only use 100% human translation carried out by professional translators. Our translators are based all over the world and typically have a minimum of 5 years of experience as professional translators. Many carry a variety of accreditations and certifications, but this varies per translator as there is no standard worldwide accreditation or certification for translators.</p>
          </animated.div>
        </div>
        <div className="certifiedGuides">
          <h2 className="certifiedFaqTitle">Translation Guides</h2>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">The Differences Between Certified and Standard Translation</div>
          </div>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">Translation Process Overview</div>
          </div>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">Translation Turnaround Times</div>
          </div>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">Glossary of Common Translation Terms</div>
          </div>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Standard;