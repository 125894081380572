import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { Mail } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { FormTextInput } from '../Components/FormTextInput';
import { FormTextArea } from '../Components/FormTextArea';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';
import { AuthContext } from '../Context/AuthContext';
import { NotificationContext } from '../Context/NotificationContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Contact.css';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const Contact = ({ windowSize }) => {

  const { setColor } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  const { signedIn } = useContext(AuthContext);

  const { transitionTo } = useScreenTransition();

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [submitButton, setSubmitButton] = useState('Submit');

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  const handleSubmit = () => {
    setSubmitButton('Submitting...');

    axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/contactFormSubmitted",
      data: {
        name: name,
        email: email.toLowerCase(),
        message: message
      }
    })
      .then((response) => {
        setName('');
        setEmail('');
        setMessage('');
        setSubmitButton('Submit');
        showNotification("Success", "Your message was successfully sent to our Support team.", "success");
      })
      .catch((error) => {
        setSubmitButton('Submit');
        showNotification("Error", "An error ocurred sending your message. Please, try again. If it persists, contact technical support at support@everbary.com", "error");
      });
  }

  return (
    <>
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>
    <TopBar/>
    <div className="homeContainer">
    {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="createAccountContainer">
        <div className="createAccountInfoContainer">
          <h1 className="documentsHeading">Contact Us</h1>
          <h2 className="documentsSubheading">
            Our support staff is available to assist you via email daily from 8 a.m. - 11 p.m. EST and after-hours for urgent matters.
            <br/><br/>
            You can <span className="hoveringTextLink" onClick={signedIn ? (e) => transitionTo('/account/orders',e) : (e) => transitionTo('/order-lookup',e)}>check your order status</span> online anytime and find answers to common questions in the <span className="hoveringTextLink" onClick={(e) => transitionTo('/faq',e)}>FAQ</span>. {/*</h2>and more in-depth knowledge in our <span className="hoveringTextLink" onClick={(e) => transitionTo('/guides',e)}>translation guides</span>.*/}
          </h2>
          <div className="createAccountExtraContainer" style={{ marginTop: 'calc(2vh)' }} onClick={() => window.open('mailto:support@everbary.com', '_blank')}>
            <Mail color='#18b7bb' size={20} />
            <p className="createAccountExtraText">support@everbary.com</p>
          </div>
          <div className="contactQRContainer">
            <p className="contactQRText">Prefer chatting with us on WhatsApp?<br/>Scan the QR Code below or <span className="hoveringTextLink" onClick={() => window.open('https://wa.me/15059168399/?text=Hello!%20I%20am%20reaching%20out%20to%20you%20because%20', '_blank')}>click here.</span></p>
            <img src={require('../assets/everbary_qr.jpg')} alt="WhatsApp Chat QR Code" className="contactQRImg" />
          </div>
        </div>
        <div className="createAccountFormContainer" style={{ marginTop: 0 }}>
          <FormTextInput placeholder="Name*" value={name} onChange={setName} color='#666' />
          <FormTextInput placeholder="Email*" value={email} onChange={setEmail} color='#666' />
          <FormTextArea placeholder="Message*" value={message} onChange={setMessage} color='#666' />
          <div className="createAccountFormSeparator" />
          <AltAnimatedButton text={submitButton} disabled={email === "" || name === "" || message === ""} onClick={() => handleSubmit()} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.6 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Contact;