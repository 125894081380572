import React, { useState, useContext, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Navbar } from "../../Components/Navbar";
import { AccountFooter } from "../../Components/AccountFooter";

import { useScreenTransition } from "../../Hooks/useScreenTransition";

import { AuthContext } from "../../Context/AuthContext";
import { TransitionContext } from "../../Context/TransitionContext";
import { NotificationContext } from "../../Context/NotificationContext";

import { languages } from "../../Data/languages";

import "./Orders.css";
import TopBar from "../../Components/TopBar";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pageLimit = 20;

const Orders = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn } = useContext(AuthContext);

  const [orders, setOrders] = useState([]);
  const [loadedOrders, setLoadedOrders] = useState(false);

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(() => {
    if (!signedIn) {
      transitionTo("/sign-in");
    } else {
      sleep(800).then(() => {
        if (firebase.auth().currentUser) {
          const firestore = firebase.firestore();

          firestore
            .collection("orders")
            .where("userID", "==", firebase.auth().currentUser.uid)
            .orderBy("timestamp", "desc")
            .limit(pageLimit)
            .get()
            .then((querySnapshot) => {
              nextPointer.current =
                querySnapshot.docs[querySnapshot.docs.length - 1];

              setOrders(
                querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
              );
              setLoadedOrders(true);
            })
            .catch((error) => {
              //console.log(error);
              setLoadedOrders(true);
              showNotification(
                "Error",
                "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
                "error"
              );
            });
        } else {
          setLoadedOrders(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const loadMoreOrders = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      firestore
        .collection("orders")
        .where("userID", "==", firebase.auth().currentUser.uid)
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get()
        .then((querySnapshot) => {
          nextPointer.current =
            querySnapshot.docs[querySnapshot.docs.length - 1];

          setOrders((o) =>
            o.concat(
              querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred while loading the orders. Please, try again. If it persists, contact Support.",
            "error"
          );
          //console.log("Error getting documents: ", error);
        });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return (
      splitted[1] +
      " " +
      splitted[2] +
      ", " +
      splitted[3] +
      " @ " +
      date.toTimeString().substr(0, 5)
    );
  };

  const getLanguage = (language) => {
    if (languages.find((lang) => lang.value === language)) {
      return languages.find((lang) => lang.value === language).label;
    } else {
      return "";
    }
  };

  const getTotal = (order) => {
    let total = 0;
    const orderDate = order ? new Date(order.timestamp) : new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (order && order.certified) {
      if (order.pagesCount !== "") {
        total = total + parseInt(order.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (order) {
      if (order.wordCount !== "") {
        total = total + parseInt(order.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (order && order.expedited) {
      if (orderDate > priceUpdateDate) {
        total = total + total * 0.4;
      } else {
        total = total + total * 0.6;
      }
    }

    if (order && order.notarization) {
      total = total + 34.9;
    }

    if (order && order.hardCopy) {
      if (orderDate >= priceUpdateDate) {
        if (
          ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
            order.hardCopyExtra.country
          )
        ) {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 49.95;
          } else {
            total = total + 78.95;
          }
        }
      } else {
        if (
          ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
            order.hardCopyExtra.country
          )
        ) {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 12.95;
          } else {
            total = total + 29.95;
          }
        } else {
          if (order.hardCopyExtra.method === "priority") {
            total = total + 39.95;
          } else {
            total = total + 67.95;
          }
        }
      }
    }

    if (order && order.hardCopy && order.hardCopyExtra.requireSignature) {
      if (orderDate >= priceUpdateDate) {
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  };

  const getDiscount = (order) => {
    if (!order || !order.discount || order.discount === 0) {
      return 0;
    } else {
      const orderDate = order ? new Date(order.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        let total = 0;

        if (order && order.certified) {
          if (order.pagesCount !== '') {
            total = total + parseInt(order.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (order) {
          if (order.wordCount !== '') {
            total = total + parseInt(order.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * order.discount / 100;
      }
      return (getTotal(order) * order.discount) / 100;
    }
  };

  const formatTotal = (value) => {
    return "$" + value.toFixed(2);
  };

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
        <animated.div style={accountMenuProps} className="accountSidebar">
          <p
            className="accountSidebarTitle"
            onClick={phoneScreen ? () => setMenuOpen((i) => !i) : null}
          >
            {windowSize.width > 767 || windowSize.orientation === "landscape"
              ? "Account"
              : "Account Menu"}
          </p>
          <p
            className="accountSidebarLink"
            onClick={(e) => transitionTo("/account/profile", e)}
          >
            Profile
          </p>
          <p
            className="accountSidebarLink"
            onClick={(e) => transitionTo("/account/shipping", e)}
          >
            Shipping
          </p>
          <p
            className="accountSidebarLink"
            onClick={(e) => transitionTo("/account/quotes", e)}
          >
            Quotes
          </p>
          <p className="accountSidebarLink" style={{ color: "#666" }}>
            Orders
          </p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/password',e)}>Password</p>
        </animated.div>
        <div
          className="accountMainContainer"
          style={{ width: !phoneScreen ? "73%" : "86%" }}
        >
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Orders</h1>
          </div>
          <div className="quoteHeaderLine">
            <p className="quoteDetailsHeaderColumn">Details</p>
            <p className="quoteDetailsHeaderColumn quoteStatusHeaderColumn">
              Status
            </p>
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn">
              Total
            </p>
          </div>
          {loadedOrders && orders.length > 0 ? (
            orders.map((order) => (
              <div key={order.id} className="quoteLine">
                <div className="quoteDetailsLineColumn">
                  <div className="quoteDetailsContainer">
                    <p
                      className="quoteDetailsTitle"
                      onClick={(e) =>
                        transitionTo(`/account/order/${order.id}`, e)
                      }
                    >{`Order #${order.orderNumber}`}</p>
                    <p className="quoteDetailsSub">{`${getLanguage(
                      order.languageFrom
                    )} to ${getLanguage(order.languageTo)} - ${
                      order.status === "created" ? "Created" : "Requested"
                    } ${formatDate(order.timestamp)}`}</p>
                  </div>
                </div>
                <div className="quoteDetailsLineColumn quoteStatusHeaderColumn">
                  <div
                    className="quoteStatusContainer"
                    style={{
                      width:
                        windowSize.width > 1024 ||
                        windowSize.orientation === "landscape"
                          ? "40%"
                          : windowSize.width > 767
                          ? "50%"
                          : "60%",
                      backgroundColor:
                        order.status === "created"
                          ? "#F6C907"
                          : order.status === "new"
                          ? "#FF7C1D"
                          : order.status === "assigned" ||
                            order.status === "in-progress" ||
                            order.status === "support-review" ||
                            (order.status === "revision" &&
                              !order.supportApproved)
                          ? "#1491F8"
                          : order.status === "user-review"
                          ? "#30d5c8"
                          : order.status === "revision"
                          ? "#333"
                          : order.status === "cancelled"
                          ? "#E1504A"
                          : "#17BC5B",
                    }}
                  >
                    {order.status === "created"
                      ? "CREATED"
                      : order.status === "new"
                      ? "NEW ORDER"
                      : order.status === "assigned" ||
                        order.status === "in-progress" ||
                        order.status === "support-review" ||
                        (order.status === "revision" && !order.supportApproved)
                      ? "IN PROGRESS"
                      : order.status === "user-review"
                      ? "READY"
                      : order.status === "revision"
                      ? "REVISION"
                      : order.status === "cancelled"
                      ? "CANCELLED"
                      : "COMPLETED"}
                  </div>
                </div>
                <div className="quoteDetailsLineColumn quoteTotalHeaderColumn">
                  {formatTotal(getTotal(order) - getDiscount(order))}
                </div>
              </div>
            ))
          ) : loadedOrders ? (
            <div className="loadingQuotes">You have no orders.</div>
          ) : (
            <div className="loadingQuotes">Loading orders...</div>
          )}
          {nextPointer.current ? (
            <p
              onClick={() => loadMoreOrders()}
              className="loadMoreQuotesButton"
            >
              Load more orders...
            </p>
          ) : null}
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
    </div>
    </>
  );
};

export default Orders;