import React, { useEffect, useContext } from 'react';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';

import { ArrowRight } from 'react-feather';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { TransitionContext } from '../Context/TransitionContext';
import TopBar from '../Components/TopBar';


import './SingleLanguage.css'
import './About.css';
import { useState } from 'react';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';


const About = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);
  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const { transitionTo } = useScreenTransition();

  return (
    <>
     <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
     <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>
    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
      <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="heading">About eVerbary</h1>
          <h2 className="documentsSubheading">
          eVerbary helps individuals and businesses with quick and affordable document translation services. Our team of professional translators have a wide range of experience and deep specialization in many areas, such as translating supporting documents for U.S. green card or citizenship applications, academic transcripts for university applications, financial records for mortgages, legal documents for judicial proceedings, and many other user cases.
            <br/><br/>
            We are excited to have you as a customer and look forward to working with you. If you have any questions, feel free to contact our support team and we'll get right back to you.
          </h2>
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'home.webp' : 'home.png'}`)} alt="People sharing a document" className="headerImg" /> : null}
      </div>
      <div className="certificationContainer">
        <div className="certificationItemContainer">
          <img src={require('../assets/ata-logo.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">ATA Corporate Member #273062</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-3.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">100% guaranteed acceptance by USCIS</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-1.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Trusted by +350 law firms</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-2.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Same-day delivery available</p>
        </div>
      </div>
      <div className="singleLanguageMainContainer aboutMainContainer">
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'jobs.webp' : 'jobs.png'}`)} alt="People sharing a document" className="headerImg aboutHeaderImg" style={{ marginRight: 0 }} /> : null}
        <div className="singleLanguageMainInfoContainer">
          <h2 className="documentsHeading">Professional Translators Delivering Dependable Quality</h2>
          <h3 className="documentsSubheading">
            The core of our business is our translation team. Through the years we've assembled an amazing group that share our values. We take a rigorous approach to quality, deadlines, and communication, which allow us to consistently deliver translations that exceed our customer's expectations.
            <br/><br/>
            The combination of this amazing group of translators paired with our proprietary systems and excellent customer support lead to a service that has unmatched value.
          </h3>
          {/* <div className="singleUseCaseLinkContainer" onClick={(e) => transitionTo('/jobs',e)}>
            <p className="singleUseCaseLinkText">Join our team of translators</p>
            <ArrowRight size={20} color="#18b7bb" />
          </div> */}
        </div>
      </div>
      <div className="singleLanguageMainContainer aboutSecondContainer">
        <div className="singleLanguageMainInfoContainer">
          <h2 className="documentsHeading">Our Support Team is Always Happy To Help</h2>
          <h3 className="documentsSubheading">
            Our support team is made of great individuals who combine to create an incredible team. Sharp, empathetic, and in tune with customer needs, you can count on our support staff to provide the best service possible no matter the size and complexity of your translation project.
          </h3>
          <div className="singleUseCaseLinkContainer" onClick={(e) => transitionTo('/contact',e)}>
            <p className="singleUseCaseLinkText">Contact the support team</p>
            <ArrowRight size={20} color="#18b7bb" />
          </div>
        </div>
        <img src={require('../assets/support.png')} alt="People sharing a document" className="headerImg aboutHeaderImg" style={{ marginRight: 0 }} />
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default About;