import React, { useState, useContext, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Navbar } from "../../Components/Navbar";
import { AccountFooter } from "../../Components/AccountFooter";

import { useScreenTransition } from "../../Hooks/useScreenTransition";

import { AuthContext } from "../../Context/AuthContext";
import { TransitionContext } from "../../Context/TransitionContext";
import { NotificationContext } from "../../Context/NotificationContext";

import { languages } from "../../Data/languages";
import { FormTextInput } from "../../Components/FormTextInput";

import "./Quotes.css";
import "./Orders.css";
import { Edit } from "react-feather";
import { DataContext } from "../../Context/DataContext";
import SaveIcon from "../../assets/SaveIcon.png";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import PaymentsPopUp from "./PaymentsPopUp";
import TopBar from "../../Components/TopBar";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pageLimit = 20;

const TeamQuotes = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn } = useContext(AuthContext);
  const { userData } = useContext(DataContext);


  const [quotes, setQuotes] = useState([]);
  const [loadedQuotes, setLoadedQuotes] = useState(false);
  
  const [editNotes, setEditNotes] = useState("");
  const [quoteNotes, setQuoteNotes] = useState("");
  const [triggerFilter, setTriggerFilter] = useState(true);


  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  const [quoteNumSearch, setQuoteNumSearch] = useState("");
  const [languageFilter, setLanguageFilter] = useState("");
  const [reqBySearch, setReqBySearch] = useState("");
  const [referenceSearch, setReferenceSearch] = useState("");
  
  const [showAdvOption, setShowAdvOption] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [teamUsers, setTeamUsers] = useState([]);

  const referenceSearchRef =  useRef();

  useEffect(() => {
    const applyReferenceSearch = e => {
      if (e.keyCode === 13) {
        setTriggerFilter(tf => !tf);
      }
    }

    const referenceSearchRefVar = referenceSearchRef.current;

    referenceSearchRef.current.addEventListener("keyup", applyReferenceSearch);

    return () => {
      referenceSearchRefVar.removeEventListener("keyup", applyReferenceSearch);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {
    if(userData && userData.teamId === "" && !userData.hasTeam){
      transitionTo('/');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  useEffect(() => {
    setColor(true);
  }, [setColor]);


  useEffect(()=> {
    if(signedIn && userData && userData.hasTeam){
      const firestore = firebase.firestore();

      firestore.collection("users").where("teamId", "==", userData.teamId).get().then(usersSnap => {
        const data = usersSnap.docs.map(doc => {
          return {id: doc.id, ...doc.data()}
        })
        setTeamUsers(data);
      })

    }
  }, [signedIn, userData])

  useEffect(() => {
    if (!signedIn) {
      transitionTo("/sign-in");
    } else {
      sleep(800).then(() => {
        if (firebase.auth().currentUser && userData) {
            const firestore = firebase.firestore();

            let query = firestore.collection("quotes")
  
            if(quoteNumSearch !== ""){
  
              query = query.where("quoteNumber", "==", parseInt(quoteNumSearch,10));
  
            }
  
            if (languageFilter !== '') {
              query = query.where('languageTo', '==', languageFilter);
            };
            if (reqBySearch !== '') {
              query = query.where('name', '==', reqBySearch);
            };
            if (referenceSearch !== '') {
              query = query.where('teamNotesSearch', '>=', referenceSearch.toLowerCase()).orderBy("teamNotesSearch", "asc");
            };
            if (startDate !== '' && endDate !=='' && referenceSearch ==='' && showAdvOption) {
              const start = new Date(startDate);
              const end = new Date(endDate);
              query = query.where('timestamp', '>=', start.getTime()).where('timestamp', '<=', end.getTime());
            };
  
            query.where("teamId", "==", userData.teamId)
            .orderBy("timestamp", "desc")
            .limit(pageLimit)
            .get()
            .then((querySnapshot) => {
              nextPointer.current =
                querySnapshot.docs[querySnapshot.docs.length - 1];
                const orders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
                if(referenceSearch !==""){
                  setQuotes(orders.filter(order => order.teamNotesSearch.includes(referenceSearch.toLowerCase())));
                }else{
                  setQuotes(orders);
                }

              setLoadedQuotes(true);
            })
            .catch((error) => {
              console.log(error);
              setLoadedQuotes(true);
              showNotification(
                "Error",
                "An error ocurred while loading the quotes. Please, try again. If it persists, contact Support.",
                "error"
              );
            });
        } else {
          setLoadedQuotes(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn, userData, triggerFilter, languageFilter,reqBySearch, startDate, endDate, showAdvOption]);


  const loadMoreQuotes = () => {
    if (nextPointer.current && userData) {
      const firestore = firebase.firestore();

      let query = firestore.collection("quotes")

      if(quoteNumSearch !== ""){

        query = query.where("quoteNumber", "==", quoteNumSearch);

      }
      if (languageFilter !== '') {
        query = query.where('languageTo', '==', languageFilter);
      };
      if (reqBySearch !== '') {
        query = query.where('name', '==', reqBySearch);
      };
      if (referenceSearch !== '') {
        query = query.where('teamNotesSearch', '>=', referenceSearch.toLowerCase()).orderBy("teamNotesSearch", "desc");
      };
      if (startDate !== '' && endDate !=='' && referenceSearch==='') {
        const start = new Date(startDate);
        const end = new Date(endDate);
        query = query.where('timestamp', '>=', start.getTime()).where('timestamp', '<=', end.getTime());
      };

        query.where("teamId", "==", userData.teamId)
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get()
        .then((querySnapshot) => {
          nextPointer.current =
            querySnapshot.docs[querySnapshot.docs.length - 1];

            const orders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
            if(referenceSearch !==""){
              setQuotes((o) => o.concat(orders.filter(order => order.teamNotesSearch.includes(referenceSearch.toLowerCase()))));
            }else{
              setQuotes((o)=> o.concat(orders));
            }
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred while loading the quotes. Please, try again. If it persists, contact Support.",
            "error"
          );
          //console.log("Error getting documents: ", error);
        });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return (
      splitted[1] +
      " " +
      splitted[2] +
      ", " +
      splitted[3] +
      " @ " +
      date.toTimeString().substr(0, 5)
    );
  };

  const getLanguage = (language) => {
    if (languages.find((lang) => lang.value === language)) {
      return languages.find((lang) => lang.value === language).label;
    } else {
      return "";
    }
  };

  const getTotal = (quote) => {
    if (quote.pagesCount === undefined || quote.wordCount === undefined) {
      return "";
    }

    let total = 0;
    const quoteDate = quote ? new Date(quote.timestamp): new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (quote && quote.certified) {
      if (quote.pagesCount !== "") {
        total = total + parseInt(quote.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (quote) {
      if (quote.wordCount !== "") {
        total = total + parseInt(quote.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (quote && quote.expedited) {
      if (quoteDate > priceUpdateDate) {
        total = total + total * 0.4;
      }else{
        total = total + total * 0.6;
      }
    }

    if (quote && quote.notarization) {
      total = total + 34.9;
    }

    if (quote && quote.hardCopy) {
      if(quoteDate >= priceUpdateDate){
        if (
          ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
            quote.hardCopyExtra.country
          )
        ) {
          if (quote.hardCopyExtra.method === "priority") {
              total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (quote.hardCopyExtra.method === "priority") {
            total = total + 49.95;
          } else {
            total = total + 7.95;
          }
        }
      }else{
      if (
        ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
          quote.hardCopyExtra.country
        )
      ) {
        if (quote.hardCopyExtra.method === "priority") {
            total = total + 12.95;
        } else {
          total = total + 29.95;
        }
      } else {
        if (quote.hardCopyExtra.method === "priority") {
          total = total + 39.95;
        } else {
          total = total + 67.95;
        }
      }
    }
  }

    if (quote && quote.hardCopy && quote.hardCopyExtra.requireSignature) {
      if(quoteDate >= priceUpdateDate){
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  };

  const getDiscount = (quote) => {
    if (quote.pagesCount === undefined || quote.wordCount === undefined) {
      return "";
    } else if (!quote || !quote.discount || quote.discount === 0) {
      return 0;
    } else {
      
      const orderDate = quote ? new Date(quote.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        let total = 0;

        if (quote && quote.certified) {
          if (quote.pagesCount !== '') {
            total = total + parseInt(quote.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (quote) {
          if (quote.wordCount !== '') {
            total = total + parseInt(quote.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * quote.discount / 100;
      }
      return (getTotal(quote) * quote.discount) / 100;
    }
  };

  const formatTotal = (value) => {
    return "$" + value.toFixed(2);
  };

  const updateQuoteNotes = (quoteId) => {
    if(quoteNotes !== ""){
      const firestore = firebase.firestore();

    firestore.collection("quotes").doc(quoteId).update({
      teamNotes: quoteNotes,
      teamNotesSearch: quoteNotes.toLowerCase()
      })
    .then(() => {
        setQuoteNotes("");
        setEditNotes("");
        setTriggerFilter(tr => !tr);
        showNotification("Note Updated", "Quote Note updated succesfully", "success");

    })
    .catch((error) => {
        console.error(error);
        showNotification("Error","Error updating document, please try again.", "error");
    });
    }
  }

  const resetAll = () => {

    setQuoteNumSearch("");
    setStartDate("");
    setEndDate("");
    setReqBySearch("");
    setReferenceSearch("");
    setLanguageFilter("");
    setTriggerFilter(trig => !trig);

  }


  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
      <animated.div style={accountMenuProps} className="accountSidebar sideBar">
            <p className="accountSidebarTitle" onClick={phoneScreen ? () => setMenuOpen(i => !i) : null}>{windowSize.width > 767 || windowSize.orientation === 'landscape' ? 'Team' : 'Team Menu'}</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/members',e)}>Members</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/shipping',e)}>Shipping</p>
            <p className="accountSidebarLink" style={{ color: '#666' }}>Quotes</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/orders',e)}>Orders</p>
            <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/options',e)}>Options</p>
        <p className="accountSidebarLink" onClick={(e) => transitionTo('/team/payments',e)}>Payments</p>
        </animated.div>
        <div
          className="accountMainContainer"
        >
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Quotes</h1>
            <div className="">
            <FormTextInput placeholder="Search by quote no." value={quoteNumSearch} onChange={setQuoteNumSearch} onKeyDown={(e)=> (e.keyCode === 13) && setTriggerFilter(tf => !tf)} color='#666' />
                {/* <input type="text" className="userNameFilter" ref={quoteNumSearchRef} value={quoteNumSearch} onChange={(e) => setQuoteNumSearch(e.target.value)} placeholder="Search by quote number" /> */}
                <p className="quoteDetailsTitle advOption" onClick={()=> setShowAdvOption(show => !show)}>Advanced options</p>
            </div>
          </div>
            <div className={`searchFilterRow ${showAdvOption ? 'showAnim': 'hideAnim'}`}>
            <div style={{minWidth: "130px"}}>
              <DatePicker
              dateFormat="dd/MM/yyyy"
              className="userNameFilter" showMonthDropdown showYearDropdown
                selected={startDate}
                onChange={(date) => {setStartDate(date); setReferenceSearch("");}}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="select start date"
              />
              <DatePicker
              dateFormat="dd/MM/yyyy"
              className="userNameFilter" showMonthDropdown showYearDropdown
                selected={endDate}
                onChange={(date) => {setEndDate(date); setReferenceSearch("");}}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="select end date"
              />
            </div>

            <select value={reqBySearch} onChange={(e) => setReqBySearch(e.target.value)} className="quoteDetailsSelect">
                  <option key="" value="">No name selected</option>
                  {teamUsers.map(user => 
                    <option key={user.id} value={user.name}>{user.name}</option>
                  )}
                </select>
           
              <input type="text" className="userNameFilter" ref={referenceSearchRef} value={referenceSearch} onChange={(e) => setReferenceSearch(e.target.value)} placeholder="Search Notes" />

                <select value={languageFilter} onChange={(e) => setLanguageFilter(e.target.value)} className="quoteDetailsSelect">
                  <option key="" value="">No language selected</option>
                  {languages.filter(language => language !== 'english').map(language => 
                    <option key={language.value} value={language.value}>{language.label}</option>
                  )}
                </select>

            <p className="quoteDetailsTitle" onClick={()=> resetAll()}>RESET</p>
            </div>
          <div className="quoteHeaderLine">
            <p className="quoteDetailsHeaderColumn">Details</p>
            <p className="quoteDetailsHeaderColumn">
             {windowSize.width > 768 ? "Requested By" : "Req. By"}
            </p>
            <p className="quoteDetailsHeaderColumn quoteStatusHeaderColumn">
              Status
            </p>
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn">
              Total
            </p>
            {windowSize.width > 768 && <p className="quoteDetailsHeaderColumn">
              Notes
            </p>}
          </div>
          {loadedQuotes && quotes.length > 0 ? (
            quotes.map((quote) => (
              <div key={quote.id} className="quoteLine">
                <div className="quoteDetailsLineColumn">
                  <div className="quoteDetailsContainer">
                  {windowSize.width > 767 ? <p
                      className="quoteDetailsTitle"
                      onClick={(e) =>
                        transitionTo(`/account/quote/${quote.id}`, e)
                      }
                    >{`Quote #${quote.quoteNumber}`}</p> :
                    <p
                      className="quoteDetailsTitle"
                      onClick={(e) =>
                        transitionTo(`/account/quote/${quote.id}`, e)
                      }
                    >{`#${quote.quoteNumber}`}</p>}
                   
                    <p className="quoteDetailsSub">{`${getLanguage(
                      quote.languageFrom
                    )} to ${getLanguage(
                      quote.languageTo 
                    )} `}<br/> {formatDate(quote.timestamp)}</p>
                     <p className="quoteDetailsSub">
                     </p>
                  </div>
                </div>
                <div className="quoteDetailsLineColumn nameText">
                  {quote.name}
                </div>
                <div className="quoteDetailsLineColumn quoteStatusHeaderColumn">
                  <div
                    className="quoteStatusContainer"
                    style={{
                      backgroundColor:
                        quote.status === "submitted"
                          ? "#FF7C1D"
                          : quote.status === "replied"
                          ? "#30d5c8"
                          : "#17BC5B",
                    }}
                  >
                    {quote.status === "submitted"
                      ? "IN REVIEW"
                      : quote.status === "replied"
                      ? "READY"
                      : "ORDERED"}
                  </div>
                </div>
                <div className="quoteDetailsLineColumn quoteTotalHeaderColumn">
                  {quote.pagesCount === undefined ||
                  quote.wordCount === undefined
                    ? ""
                    : formatTotal(getTotal(quote) - getDiscount(quote))}
                </div>
                {windowSize.width > 768 && <div className="quoteDetailsLineColumn referenceText">

                {/* <input autoFocus style={{width:"73%"}} type="text" className="userNameFilter" onKeyDown={(e) => {
                    e.key === "Enter" && quote.id === editNotes && updateQuoteNotes(quote.id)}} 
                       */}

                  
                  {quote.id === editNotes ? <div className="inputWrap"><FormTextInput focus={true} onKeyDown={(e) => {
                    e.key === "Enter" && quote.id === editNotes && updateQuoteNotes(quote.id)}} 
                    onChange={setQuoteNotes} value={quoteNotes}/></div> : <div style={{width:"80%"}} onClick={()=> {
                        setEditNotes(quote.id); setQuoteNotes(quote.teamNotes ? quote.teamNotes : '');
                      }} >{quote.teamNotes ? quote.teamNotes : 'No Notes'}</div>}
                  {quote.id !== editNotes ? <Edit className="editBtn blueColor" onClick={()=> {
                      setEditNotes(quote.id); setQuoteNotes(quote.teamNotes ? quote.teamNotes : '');
                    }}/> : 
                      <img  className="editBtn"  onClick={()=> updateQuoteNotes(quote.id)} src={SaveIcon} alt=""/>
                    }
                </div>}
              </div>
            ))
          ) : loadedQuotes ? (
            <div className="loadingQuotes">You have no quotes.</div>
          ) : (
            <div className="loadingQuotes">Loading quotes...</div>
          )}
          {nextPointer.current ? (
            <p
              onClick={() => loadMoreQuotes()}
              className="loadMoreQuotesButton"
            >
              Load more quotes...
            </p>
          ) : null}
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
      <PaymentsPopUp windowSize={windowSize} openConfirmation={openConfirmation} setOpenConfirmation={setOpenConfirmation}/>
    </div>
    </>
  );
};

export default TeamQuotes;
