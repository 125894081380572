import React, { useContext, useEffect, useState } from 'react';

import { ChevronRight } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { TransitionContext } from '../Context/TransitionContext';

import './Languages.css';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const Languages = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  const { transitionTo } = useScreenTransition();

  return (
    <>
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="documentsHeading">60+ Languages Covered</h1>
          <h2 className="documentsSubheading">
          Our team has a strong grasp of grammar in English as well as their native language. We guarantee your project's success by pairing you with a industry-specific professional translator.
            <br/><br/>
            If the language pair you need to be translated doesn't include English, <span className="hoveringTextLink" onClick={() => transitionTo('/contact')}>contact us</span> for options before ordering.
          </h2>
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'languages.webp' : 'languages.png'}`)} alt="People sharing a document" className="languagesHeaderImg" /> : null}
      </div>
      <div className="documentsListOuter">
        <h3 className="documentsListTitle">Languages We Cover</h3>
        <div className="documentsList">
          <div className="languagesListColumn">
            <div className="documentsListItem" /*onClick={(e) => transitionTo('/language/albanian',e)}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Albanian</p>
            </div>
            <div className="documentsListItem" /*onClick={(e) => transitionTo('/language/arabic',e)}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Arabic</p>
            </div>
            <div className="documentsListItem" /*onClick={(e) => transitionTo('/language/armenian',e)}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Armenian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/bengali')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Bengali</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/bosnian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Bosnian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/bulgarian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Bulgarian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/burmese')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Burmese</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/catalan')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Catalan</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/chineseSimp')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Chinese (Simplified)</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/chineseTrad')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Chinese (Traditional)</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/creole')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Creole</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/croatian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Croatian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/czech')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Czech</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/danish')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Danish</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/dutch')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Dutch</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/english')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Dari</p>
            </div>
            { windowSize.width <= 1024 && windowSize.width > 767
            ? <>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/farsi')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">English</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/farsi')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Farsi</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/finnish')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Finnish</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/french')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">French</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/frenchCan')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">French (Canadian)</p>
                </div>
              </>
            : null
            }
          </div>
          <div className="languagesListColumn">
            {windowSize.width > 1024 || windowSize.width <= 767 
            ? <>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/english')}*/>
                    <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                    <p className="documentsListItemText">English</p>
                  </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/farsi')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Farsi</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/finnish')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Finnish</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/french')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">French</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/frenchCan')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">French (Canadian)</p>
                </div>
              </>
            : null
            }
            
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/georgian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Georgian</p>
                </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/german')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">German</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/greek')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Greek</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/gujrati')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Gujrati</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/hebrew')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Hebrew</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/hindi')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Hindi</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/hungarian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Hungarian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/indonesian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Indonesian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/italian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Italian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/japanese')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Japanese</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/kannada')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Kannada</p>
            </div>
            
            { windowSize.width <= 1024 && windowSize.width > 767
            ? <>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/latin')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Latin</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/latvian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Latvian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/lithuanian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Lithuanian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/macedonian')*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Macedonian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/malay')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Malay</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/maltese')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Maltese</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/marathi')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Marathi</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/nepali')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Nepali</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/norwegian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Norwegian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/persian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Persian</p>
                </div>
              </>
            : null
            }
          </div>
          <div className="languagesListColumn">
            { windowSize.width > 1024 || windowSize.width <= 767 
            ? <>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/korean')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Korean</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/latin')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Latin</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/latvian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Latvian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/lithuanian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Lithuanian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/macedonian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Macedonian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/malay')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Malay</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/maltese')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Maltese</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/marathi')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Marathi</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/nepali')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Nepali</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/norwegian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Norwegian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/persian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Pashto</p>
                </div>
                
              </>
            : null
            }
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/persian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Persian</p>
                </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/polish')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Polish</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/portugueseBra')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Portuguese (Brazil)</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/portuguesePor')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Portuguese (Portugal)</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/punjabi')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Punjabi</p>
            </div>
            
            { windowSize.width <= 1024 && windowSize.width > 767
            ? <>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/serbian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Serbian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/slovak')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Slovak</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/slovenian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Slovenian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/somali')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Somali</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/spanish')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Spanish</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/swedish')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Swedish</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/tagalog')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Tagalog</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/tamil')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Tamil</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/thai')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Thai</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/turkish')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Turkish</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/ukrainian')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Ukrainian</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/urdu')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Urdu</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/uzbek')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Uzbek</p>
                </div>
                <div className="documentsListItem" /*onClick={() => transitionTo('/language/vietnamese')}*/>
                  <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                  <p className="documentsListItemText">Vietnamese</p>
                </div>
              </>
            : null
            }
          </div>
          { windowSize.width > 1024 || windowSize.width <= 767
          ? <div className="languagesListColumn">
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/romanian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Romanian</p>
            </div>
            <div className="documentsListItem" /*onClick={() => transitionTo('/language/russian')}*/>
              <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
              <p className="documentsListItemText">Russian</p>
            </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/serbian')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Serbian</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/slovak')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Slovak</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/slovenian')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Slovenian</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/somali')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Somali</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/spanish')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Spanish</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/swedish')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Swedish</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/tagalog')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Tagalog</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/tamil')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Tamil</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/thai')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Thai</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/turkish')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Turkish</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/ukrainian')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Ukrainian</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/urdu')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Urdu</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/uzbek')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Uzbek</p>
              </div>
              <div className="documentsListItem" /*onClick={() => transitionTo('/language/vietnamese')}*/>
                <ChevronRight color="#18b7bb" size={windowSize.width > 1024 ? 23 : windowSize.width > 767 ? 20 : 18} />
                <p className="documentsListItemText">Vietnamese</p>
              </div>
            </div>
          : null
          }
        </div>
        <div className="languagesExtraInfoContainer">
          <p className="languagesExtraInfoText">Need a language not listed? <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>Contact our support team</span> to see if we can help.</p>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Languages;