import React, { useState, useContext, useEffect, useRef } from "react";
import { useSpring, animated, config } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Navbar } from "../../Components/Navbar";
import { AccountFooter } from "../../Components/AccountFooter";

import { useScreenTransition } from "../../Hooks/useScreenTransition";

import { AuthContext } from "../../Context/AuthContext";
import { TransitionContext } from "../../Context/TransitionContext";
import { NotificationContext } from "../../Context/NotificationContext";

import { languages } from "../../Data/languages";

import "./Quotes.css";
import TopBar from "../../Components/TopBar";

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const pageLimit = 20;

const Quotes = ({ windowSize }) => {
  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);
  const { showNotification } = useContext(NotificationContext);
  const { signedIn } = useContext(AuthContext);

  const [quotes, setQuotes] = useState([]);
  const [loadedQuotes, setLoadedQuotes] = useState(false);

  let nextPointer = useRef();
  if (!nextPointer.current) nextPointer.current = null;

  const phoneScreen =
    windowSize.width > 767 || windowSize.orientation === "landscape"
      ? false
      : true;

  const [menuOpen, setMenuOpen] = useState(!phoneScreen);

  const accountMenuProps = useSpring({
    maxHeight: menuOpen || !phoneScreen ? "calc(50vh)" : "calc(2.5vh)",
    config: config.slow,
  });

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  useEffect(() => {
    if (!signedIn) {
      transitionTo("/sign-in");
    } else {
      sleep(800).then(() => {
        if (firebase.auth().currentUser) {
          const firestore = firebase.firestore();

          firestore
            .collection("quotes")
            .where("userID", "==", firebase.auth().currentUser.uid)
            .orderBy("timestamp", "desc")
            .limit(pageLimit)
            .get()
            .then((querySnapshot) => {
              nextPointer.current =
                querySnapshot.docs[querySnapshot.docs.length - 1];

              setQuotes(
                querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
              );
              setLoadedQuotes(true);
            })
            .catch((error) => {
              //console.log(error);
              setLoadedQuotes(true);
              showNotification(
                "Error",
                "An error ocurred while loading the quotes. Please, try again. If it persists, contact Support.",
                "error"
              );
            });
        } else {
          setLoadedQuotes(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedIn]);

  const loadMoreQuotes = () => {
    if (nextPointer.current) {
      const firestore = firebase.firestore();

      firestore
        .collection("quotes")
        .where("userID", "==", firebase.auth().currentUser.uid)
        .orderBy("timestamp", "desc")
        .startAfter(nextPointer.current)
        .limit(pageLimit)
        .get()
        .then((querySnapshot) => {
          nextPointer.current =
            querySnapshot.docs[querySnapshot.docs.length - 1];

          setQuotes((q) =>
            q.concat(
              querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          );
        })
        .catch((error) => {
          showNotification(
            "Error",
            "An error ocurred while loading the quotes. Please, try again. If it persists, contact Support.",
            "error"
          );
          //console.log("Error getting documents: ", error);
        });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(" ");

    return (
      splitted[1] +
      " " +
      splitted[2] +
      ", " +
      splitted[3] +
      " @ " +
      date.toTimeString().substr(0, 5)
    );
  };

  const getLanguage = (language) => {
    if (languages.find((lang) => lang.value === language)) {
      return languages.find((lang) => lang.value === language).label;
    } else {
      return "";
    }
  };

  const getTotal = (quote) => {
    if (quote.pagesCount === undefined || quote.wordCount === undefined) {
      return "";
    }

    let total = 0;
    const quoteDate = quote ? new Date(quote.timestamp): new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (quote && quote.certified) {
      if (quote.pagesCount !== "") {
        total = total + parseInt(quote.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (quote) {
      if (quote.wordCount !== "") {
        total = total + parseInt(quote.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (quote && quote.expedited) {
      if (quoteDate > priceUpdateDate) {
        total = total + total * 0.4;
      }else{
        total = total + total * 0.6;
      }
    }

    if (quote && quote.notarization) {
      total = total + 34.9;
    }

    if (quote && quote.hardCopy) {
      if(quoteDate >= priceUpdateDate){
        if (
          ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
            quote.hardCopyExtra.country
          )
        ) {
          if (quote.hardCopyExtra.method === "priority") {
              total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (quote.hardCopyExtra.method === "priority") {
            total = total + 49.95;
          } else {
            total = total + 7.95;
          }
        }
      }else{
      if (
        ["United States", "Puerto Rico", "U.S. Virgin Islands"].includes(
          quote.hardCopyExtra.country
        )
      ) {
        if (quote.hardCopyExtra.method === "priority") {
            total = total + 12.95;
        } else {
          total = total + 29.95;
        }
      } else {
        if (quote.hardCopyExtra.method === "priority") {
          total = total + 39.95;
        } else {
          total = total + 67.95;
        }
      }
    }
  }

    if (quote && quote.hardCopy && quote.hardCopyExtra.requireSignature) {
      if(quoteDate >= priceUpdateDate){
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  };


  const getDiscount = (quote) => {
    if (quote.pagesCount === undefined || quote.wordCount === undefined) {
      return "";
    } else if (!quote || !quote.discount || quote.discount === 0) {
      return 0;
    } else {

      const orderDate = quote ? new Date(quote.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        let total = 0;

        if (quote && quote.certified) {
          if (quote.pagesCount !== '') {
            total = total + parseInt(quote.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (quote) {
          if (quote.wordCount !== '') {
            total = total + parseInt(quote.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * quote.discount / 100;
      }
      return (getTotal(quote) * quote.discount) / 100;
    }
  };

  const formatTotal = (value) => {
    return "$" + value.toFixed(2);
  };

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <Navbar windowSize={windowSize} />
      <div className="accountContainer">
        <animated.div style={accountMenuProps} className="accountSidebar">
          <p
            className="accountSidebarTitle"
            onClick={phoneScreen ? () => setMenuOpen((i) => !i) : null}
          >
            {windowSize.width > 767 || windowSize.orientation === "landscape"
              ? "Account"
              : "Account Menu"}
          </p>
          <p
            className="accountSidebarLink"
            onClick={(e) => transitionTo("/account/profile", e)}
          >
            Profile
          </p>
          <p
            className="accountSidebarLink"
            onClick={(e) => transitionTo("/account/shipping", e)}
          >
            Shipping
          </p>
          <p className="accountSidebarLink" style={{ color: "#666" }}>
            Quotes
          </p>
          <p
            className="accountSidebarLink"
            onClick={(e) => transitionTo("/account/orders", e)}
          >
            Orders
          </p>
          <p className="accountSidebarLink" onClick={(e) => transitionTo('/account/password',e)}>Password</p>
        </animated.div>
        <div
          className="accountMainContainer"
          style={{ width: !phoneScreen ? "73%" : "86%" }}
        >
          <div className="accountMainTitleRow">
            <h1 className="accountMainTitle">Quotes</h1>
          </div>
          <div className="quoteHeaderLine">
            <p className="quoteDetailsHeaderColumn">Details</p>
            <p className="quoteDetailsHeaderColumn quoteStatusHeaderColumn">
              Status
            </p>
            <p className="quoteDetailsHeaderColumn quoteTotalHeaderColumn">
              Total
            </p>
          </div>
          {loadedQuotes && quotes.length > 0 ? (
            quotes.map((quote) => (
              <div key={quote.id} className="quoteLine">
                <div className="quoteDetailsLineColumn">
                  <div className="quoteDetailsContainer">
                    <p
                      className="quoteDetailsTitle"
                      onClick={(e) =>
                        transitionTo(`/account/quote/${quote.id}`, e)
                      }
                    >{`Quote #${quote.quoteNumber}`}</p>
                    <p className="quoteDetailsSub">{`${getLanguage(
                      quote.languageFrom
                    )} to ${getLanguage(
                      quote.languageTo
                    )} - Requested ${formatDate(quote.timestamp)}`}</p>
                  </div>
                </div>
                <div className="quoteDetailsLineColumn quoteStatusHeaderColumn">
                  <div
                    className="quoteStatusContainer"
                    style={{
                      backgroundColor:
                        quote.status === "submitted"
                          ? "#FF7C1D"
                          : quote.status === "replied"
                          ? "#30d5c8"
                          : "#17BC5B",
                    }}
                  >
                    {quote.status === "submitted"
                      ? "IN REVIEW"
                      : quote.status === "replied"
                      ? "READY"
                      : "ORDERED"}
                  </div>
                </div>
                <div className="quoteDetailsLineColumn quoteTotalHeaderColumn">
                  {quote.pagesCount === undefined ||
                  quote.wordCount === undefined
                    ? ""
                    : formatTotal(getTotal(quote) - getDiscount(quote))}
                </div>
              </div>
            ))
          ) : loadedQuotes ? (
            <div className="loadingQuotes">You have no quotes.</div>
          ) : (
            <div className="loadingQuotes">Loading quotes...</div>
          )}
          {nextPointer.current ? (
            <p
              onClick={() => loadMoreQuotes()}
              className="loadMoreQuotesButton"
            >
              Load more quotes...
            </p>
          ) : null}
        </div>
      </div>
      <AccountFooter windowSize={windowSize} />
    </div>
    </>
  );
};

export default Quotes;