import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { Lock } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { FormTextInput } from '../Components/FormTextInput';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';
import { useEnterKey } from '../Hooks/useEnterKey';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const OrderLookup = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  const { setColor } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const [email, setEmail] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const [submitButton, setSubmitButton] = useState('Find');
  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  const handleLookup = async () => {
    setSubmitButton('Finding...');

    axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/orderLookup",
      data: {
        email: email.toLowerCase(),
        number: orderNumber
      }
    })
      .then((response) => {
        transitionTo(response.data.link);
      })
      .catch((error) => {
        setSubmitButton('Find');

        if (error.response.status === 404) {
          showNotification('Error', "There is no order with that information. Please, try again. If you can't find your order, contact Support for help.", 'error');
        } else {
          showNotification('Error', 'An error ocurred finding the order. Try again. If it persists, please contact Support.', 'error');
        }
      });

  }

  const triggerLookup = () => {
    if (!(email === "" || orderNumber === "")) {
      handleLookup();
    }
  }

  useEnterKey(() => triggerLookup());

  return (
    <>
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
    {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="createAccountContainer">
        <div className="createAccountInfoContainer">
          <h1 className="documentsHeading">Order Lookup</h1>
          <h2 className="subheading">Check the status of your order, download files, and request revisions. Your order number can be found in your order confirmation email.</h2>
          <p className="createAccountExtraContainer">
            <Lock color='#18b7bb' size={20} />
            <p className="createAccountExtraText">Have an account? <span className="hoveringTextLink" onClick={(e) => handleLoginOpen(true)}>Sign in</span>.</p>
          </p>
        </div>
        <div className="createAccountFormContainer">
          <FormTextInput placeholder="Email*" value={email} onChange={setEmail} color='#666' />
          <FormTextInput placeholder="Order Number*" value={orderNumber} onChange={setOrderNumber} color='#666' />
          <div className="createAccountFormSeparator" />
          <AltAnimatedButton text={submitButton} disabled={email === "" || orderNumber === ""} onClick={handleLookup} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.6 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default OrderLookup;