import React, { useContext, useEffect, useState } from 'react';

import { Navbar } from '../Components/Navbar';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Standard.css';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const Interpretations = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }
  
  return (
    <>

    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
    <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="heading">Interpretation Services</h1>
          <h2 className="subheading">Our interpreters can be in person, over the phone or on a video chat. Please contact us to send an estimate of the service cost.</h2>
          <p className="subheadingFootnote"></p>
          <AltAnimatedButton text="Contact Us" onClick={(e) => transitionTo('/contact',e)} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.6) : (windowSize.orientation === 'portrait' ? 6 : 2.2)} />
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'certified.webp' : 'certified.png'}`)} alt="People stamping a translation" className="certifiedHeaderImg" /> : null}
      </div>
      <div className="certificationContainer">
        <div className="certificationItemContainer">
          <img src={require('../assets/ata-logo.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">ATA Corporate Member #273062</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-3.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">100% guaranteed acceptance by USCIS</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-1.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Trusted by +350 law firms</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-2.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Same-day delivery available</p>
        </div>
      </div>
      
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Interpretations;