import React, { useState, useContext, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { useParams } from 'react-router-dom';
import { Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import { PayPalButtons } from "@paypal/react-paypal-js";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { Info, Clock, Bold, Italic, Underline, List, Check, Download, Trash2 } from 'react-feather';

import { Navbar } from '../../Components/Navbar';
import { AccountFooter } from '../../Components/AccountFooter';
import { AltAnimatedButton } from '../../Components/AltAnimatedButton';
import { FileUploaderButton } from '../../Components/FileUploaderButton';
import { FormTextInput } from '../../Components/FormTextInput';
import { FormTextArea } from '../../Components/FormTextArea';

import { useScreenTransition } from '../../Hooks/useScreenTransition';
// import { useEnterKey } from '../../Hooks/useEnterKey';

import { AuthContext } from '../../Context/AuthContext';
import { TransitionContext } from '../../Context/TransitionContext';
import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import { languages } from '../../Data/languages';
import { countries } from '../../Data/countries';

import './SingleOrder.css';
import { useReactToPrint } from 'react-to-print';
import { InvoiceToPrint } from '../../Components/InvoicePrint';
import TopBar from '../../Components/TopBar';

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const SingleOrder = ({ windowSize }) => {

  const { setColor } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  const { signedIn } = useContext(AuthContext);

  const { order, setOrder, userData } = useContext(DataContext);

  const { transitionTo } = useScreenTransition();

  const phoneScreen = windowSize.width <= 767 && windowSize.orientation === 'portrait';

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const { orderID } = useParams();

  const [orderInfo, setOrderInfo] = useState(null);
  const [loadedOrder, setLoadedOrder] = useState(false);

  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());

  const [supportReplyNotes, setSupportReplyNotes] = React.useState(() => EditorState.createEmpty());

  const [clientRevisionNotes, setClientRevisionNotes] = React.useState(() => EditorState.createEmpty());

  const [clientRevisionNotesFinal, setClientRevisionNotesFinal] = React.useState(() => EditorState.createEmpty());

  const [translatorNotesClient, setTranslatorNotesClient] = React.useState(() => EditorState.createEmpty());

  const [openRevision, setOpenRevision] = useState(false);
  const revisionModalProps = useSpring({ opacity: openRevision ? 1 : 0, pointerEvents: openRevision ? 'all' : 'none' });

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const confirmationModalProps = useSpring({ opacity: openConfirmation ? 1 : 0, pointerEvents: openConfirmation ? 'all' : 'none' });

  const [files, setFiles] = useState([]);

  const [orderSubmitButton, setOrderSubmitButton] = useState('Place Order');
  const statusOverlayProps = useSpring({ height: windowSize.height, opacity: orderSubmitButton !== 'Place Order' ? 1 : 0, pointerEvents: orderSubmitButton !== 'Place Order' ? 'all' : 'none' });

  const [openShipping, setOpenShipping] = useState(false);
  const shippingModalProps = useSpring({ opacity: openShipping ? 1 : 0, pointerEvents: openShipping ? 'all' : 'none' });

  const [recipient, setRecipient] = useState('');
  const [street, setStreet] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');

  const [hardCopyMethod, setHardCopyMethod] = useState('');
  const [hardCopyRequireSignature, setHardCopyRequireSignature] = useState(false);

  const [openHardCopyShipping, setOpenHardCopyShipping] = useState(false);
  const hardCopyShippingModalProps = useSpring({ opacity: openHardCopyShipping ? 1 : 0, pointerEvents: openHardCopyShipping ? 'all' : 'none' });

  const [saveAddress, setSaveAddress] = useState(false);
  const [saveDefault, setSaveDefault] = useState(false);

  const [reviewRating, setReviewRating] = useState(5);
  const [reviewText, setReviewText] = useState('');
  const [reviewSubmitButton, setReviewSubmitButton] = useState('Submit Review');

  const [teamUsers, setTeamUsers] = useState([]);
  const [ccEmails, setCCEmails] = useState([]);
  const [customCCEmails, setCustomCCEmails] = useState([]);
  const [selectedCustomCCEmails, setSelectedCustomCCEmails] = useState([]);

  const [newCCEmail, setNewCCEmail] = useState("");
  const [showNewCCEmail, setShowNewCCEmail] = useState(false);

  const [selectAllCCEmails, setSelectAllCCEmails] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice-#${orderInfo && orderInfo.orderNumber}`,
  });

  useEffect(() => {
    if (orderInfo && orderInfo.hardCopy) setCountry(orderInfo.hardCopyExtra.country || '');
  }, [orderInfo]);

  useEffect(() => {
    const firestore = firebase.firestore();

    firestore.collection('orders').doc(orderID)
      .onSnapshot(doc => {
        if (doc.exists) {
          setOrderInfo({ ...doc.data(), id: doc.id });

          //set ccEmails for team
          if(doc.data().teamId && doc.data().ccEmails){
            setSelectAllCCEmails(doc.data().ccEmails.selectAllCCEmails? doc.data().ccEmails.selectAllCCEmails : false);
            setCustomCCEmails(doc.data().ccEmails.customCCEmails ? doc.data().ccEmails.customCCEmails : []);
            setCCEmails(doc.data().ccEmails.selectedCCEmails ? doc.data().ccEmails.selectedCCEmails : []);
            setSelectedCustomCCEmails(doc.data().ccEmails.selectedCustomCCEmails ? doc.data().ccEmails.selectedCustomCCEmails : []);
          }

          if (doc.data().notes) {
            setEditorState(EditorState.createWithContent(convertFromRaw(doc.data().notes)));
          }

          if (doc.data().supportReply) {
            setSupportReplyNotes(EditorState.createWithContent(convertFromRaw(doc.data().supportReply)))
          }

          if (doc.data().clientRevisionNotes) {
            setClientRevisionNotes(EditorState.createWithContent(convertFromRaw(doc.data().clientRevisionNotes)))
            setClientRevisionNotesFinal(EditorState.createWithContent(convertFromRaw(doc.data().clientRevisionNotes)))
          }

          if (doc.data().translatorNotesClient) {
            setTranslatorNotesClient(EditorState.createWithContent(convertFromRaw(doc.data().translatorNotesClient)))
          }

          if (!doc.data().shownConfirmation && doc.data().status !== 'created') {
            setOpenConfirmation(true);

            firestore.collection('orders').doc(orderID).update({ shownConfirmation: true });
          }

          setLoadedOrder(true);

          sleep(500).then(() => {
            if ((windowSize.width > 767 || windowSize.orientation === 'landscape') && document.getElementById('mainPrimary') && document.getElementById('mainSecondary')) {
              if (document.getElementById('mainPrimary').clientHeight > document.getElementById('mainSecondary').clientHeight) {
                document.getElementById('mainSecondary').style.height = `${document.getElementById('mainPrimary').clientHeight}px`;
              } else {
                document.getElementById('mainPrimary').style.height = `${document.getElementById('mainSecondary').clientHeight}px`;
              }
            }
          })
          
        } else {
          showNotification('Error', 'An error ocurred loading the order info. Try again. If it persists, please contact Support.', 'error');
        }
      }, error => {
        //console.log(error);
        showNotification('Error', 'An error ocurred loading the order info. Try again. If it persists, please contact Support.', 'error');
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderID]);

  useEffect(()=> {
    if(userData && userData.hasTeam && orderInfo && orderInfo.teamId){
    const firestore = firebase.firestore();

    firestore.collection("users").where("teamId", "==", orderInfo.teamId).where("email", "!=", orderInfo.email).orderBy("email", "asc").get().then(querySnap => {
      setTeamUsers(querySnap.docs.map(doc => {return {id: doc.id, ...doc.data()}}))
    })
    }
    
  }, [orderInfo, userData])

  useEffect(()=> {
    if(userData && userData.hasTeam && orderInfo && orderInfo.teamId && !orderInfo.ccEmails && teamUsers.length > 0){
      const firestore = firebase.firestore();

      const teamEmails = teamUsers.map(user => user.email);

      firestore.collection("teams").doc(orderInfo.teamId).get().then(doc => {
        if(doc.exists && doc.data().orderCompletedNotifyToAll){
          addCCEmail(customCCEmails, teamEmails , selectedCustomCCEmails);
          // setSelectAllCCEmails(true);
        }
      })
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInfo, userData, teamUsers])


  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  const getLanguage = (language) => {
    if (languages.find(lang => lang.value === language)) {
      return languages.find(lang => lang.value === language).label;
    } else {
      return '';
    }
  }

  const downloadFile = url => {
      window.open(url, '_blank')
  }

  const getExpedited = () => {
    const orderDate = new Date(orderInfo.timestamp);
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');
    let total = 0;

    if (orderInfo && orderInfo.certified) {
      if (orderInfo.pagesCount !== '') {
        total = total + parseInt(orderInfo.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (orderInfo) {
      if (orderInfo.wordCount !== '') {
        total = total + parseInt(orderInfo.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }
    if(orderDate >= priceUpdateDate){
      return total * 0.4;
    }

    return total * 0.6;
  }

  const getTotal = () => {
    let total = 0;
    const orderDate = orderInfo ? new Date(orderInfo.timestamp) : new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (orderInfo && orderInfo.certified) {
      if (orderInfo.pagesCount !== '') {
        total = total + parseInt(orderInfo.pagesCount, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (orderInfo) {
      if (orderInfo.wordCount !== '') {
        total = total + parseInt(orderInfo.wordCount, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (orderInfo && orderInfo.expedited) {
      total = total + getExpedited();
    }

    if (orderInfo && orderInfo.notarization) {
      total = total + 34.90;
    }

    if (orderInfo && orderInfo.hardCopy) {
      console.log(orderDate, priceUpdateDate, orderDate >= priceUpdateDate)
      if(orderDate >= priceUpdateDate){
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country)) {
          if (orderInfo.hardCopyExtra.method === 'priority') {
              total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (orderInfo.hardCopyExtra.method === 'priority') {
            total = total + 49.95;
          } else {
            total = total + 78.95;
          }
        }
      }else{
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country)) {
          if (orderInfo.hardCopyExtra.method === 'priority') {
              total = total + 12.95;
          } else {
            total = total + 29.95;
          }
        } else {
          if (orderInfo.hardCopyExtra.method === 'priority') {
            total = total + 39.95;
          } else {
            total = total + 67.95;
          }
        }
      }
      
    }

    if (orderInfo && orderInfo.hardCopy && orderInfo.hardCopyExtra.requireSignature) {
      if(orderDate >= priceUpdateDate){
        total = total + 4.99;
      }
      total = total + 2.54;
    }

    return total;
  }

  const getDiscount = () => {
    if (!orderInfo || !orderInfo.discount || orderInfo.discount === 0) {
      return 0;
    } else {
      const orderDate = orderInfo ? new Date(orderInfo.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        
        let total = 0;
  
        if (orderInfo && orderInfo.certified) {
          if (orderInfo.pagesCount !== '') {
            total = total + parseInt(orderInfo.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (orderInfo) {
          if (orderInfo.wordCount !== '') {
            total = total + parseInt(orderInfo.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * orderInfo.discount / 100;
      }
        return getTotal() * orderInfo.discount / 100;
      
    }
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const formatDiscount = value => {
    return '-$' + value.toFixed(2);
  }

  const getTimeFrame = () => {

    let limits = [0, 750];

    for (let i = 2; i < 200; i++) {
      limits.push(limits[limits.length - 1] + 1500 + (i-2) * 250);
    }

    let count = 0;

    if (orderInfo && orderInfo.certified && orderInfo.pagesCount !== '') {
      count = orderInfo.pagesCount * 250;
    } else if (orderInfo && !orderInfo.certified && orderInfo.wordCount !== '') {
      count = orderInfo.wordCount;
    };

    const ceiling = limits.filter(limit => count <= limit).sort((a,b) => a - b)[0];
    let ceilingIndex = limits.findIndex(limit => limit === ceiling);

    if (orderInfo.expedited) {
      ceilingIndex = ceilingIndex / 2;
    };

    if (ceilingIndex > 0.5) {
      ceilingIndex = Math.ceil(ceilingIndex);
    }

    if (ceilingIndex <= 2) {
      return `${ceilingIndex * 24} hours`;
    } else {
      return `${ceilingIndex - 1}-${ceilingIndex} days`;
    }

  }

  const approveTranslation = () => {
    if (window.confirm("Are you sure you want to approve this translation? After this you won't be able to request a revision without contacting support.")) {
      const firestore = firebase.firestore();

      const now = new Date();

      firestore.collection('orders').doc(orderInfo.id)
        .update({
          status: 'completed',
          clientApproved: true,
          completed: now.getTime(),
          notarizationStatus: 'queue',
          hardCopyStatus: 'queue'
        })
        .then(() => {
          showNotification('Success', 'The order has been successfully approved.', 'success');
        })
        .catch(() => {
          showNotification('Error', 'An error ocurred approving the order. Please, try again. If it persists, contact Support.', 'error');
        })
    }
  }

  const handleBoldClick = revision => {
    if (revision) {
      setClientRevisionNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    } else {
      setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    }
  }

  const handleItalicClick = revision => {
    if (revision) {
      setClientRevisionNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    } else {
      setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    }
  }

  const handleUnderlineClick = revision => {
    if (revision) {
      setClientRevisionNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    } else {
      setEditorState(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    }
  }

  const toggleUL = revision => {
    if (revision) {
      setClientRevisionNotes(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
    } else {
      setEditorState(editorState => RichUtils.toggleBlockType(editorState,'unordered-list-item'))
    }
  }

  const handleFiles = (e) => {
    const mFiles = e.target.files;

    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name) && orderInfo && !orderInfo.files.some(file => file.name === mFiles[i].name)) {
        const fileID = uuidv4();
        setFiles(f => f.concat([{ id: fileID, name: mFiles[i].name, file: mFiles[i], progress: 0, status: 'uploading' }]));
      // } else {
      //   showNotification("File already uploaded.", "You've already uploaded a file with this name. Try uploading with another name.", "error");
      // }
    }

    const storageRef = firebase.storage().ref();
    
    for (let i = 0; i < mFiles.length; i++) {
      // if (!files.some(file => file.name === mFiles[i].name) && orderInfo && !orderInfo.files.some(file => file.name === mFiles[i].name)) {
        const subFolder = uuidv4();
        const fileRef = storageRef.child(orderInfo.id + '/' + subFolder + '/' + mFiles[i].name);

        const downloadToken = uuidv4();

        const fileName = mFiles[i].name.replace('#', '%23').replace('+', "%2b");

        const url = `https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/${orderInfo.id}%2F${subFolder}%2F${fileName}?alt=media&token=${downloadToken}`;

        var metadata = {
          firebaseStorageDownloadTokens: downloadToken,
          customMetadata: {
            'orderID': orderInfo.id
          }
        }

        const uploadTask = fileRef.put(mFiles[i], metadata);

        setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, task: uploadTask } : file));

        uploadTask.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, progress: progress.toFixed(2) } : file));
        }, (error) => {
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'failed' } : file));
          showNotification("Error uploading.", "There was an error uploading the file " + mFiles[i].name + ". Please, try again.", "error");
        }, () => {
          setFiles(f => f.map(file => file.name === mFiles[i].name && file.ref === mFiles[i].ref ? { ...file, status: 'complete', ref: orderInfo.id + '/' + subFolder + '/' + mFiles[i].name, url: url } : file));
        });
      // }
    }
  }

  const handleDeleteFile = file => {
    const storageRef = firebase.storage().ref();

    if (file.status === 'complete' || file.timestamp) {
      const fileRef = storageRef.child(file.ref);
      setFiles(f => f.map(mFile => mFile.name === file.name && mFile.ref === file.ref ? { ...mFile, status: 'deleting' } : mFile));

      fileRef.delete()
        .then(function() {
          setFiles(f => f.filter(mFile => mFile.ref !== file.ref));

          if (file.timestamp) {
            const firestore = firebase.firestore();

            return firestore.collection('orders').doc(orderInfo.id)
              .update({
                files: orderInfo.files.filter(mFile => mFile.ref !== file.ref)
              });
          } else {
            return true;
          }
        })
        .then(() => {
        })
        .catch(function(error) {
          showNotification("Error deleting the file.", "There was an error deleting the file " + file.name + ". Please, try again.", "error");
        });
    } else if (file.status === 'failed') {
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    } else {
      file.task.cancel();
      setFiles(f => f.filter(mFile => mFile.ref !== file.ref));
    }
  }

  const submitRevisionRequest = () => {
    const firestore = firebase.firestore();

    const localFiles = files.map(f => f);
    setFiles([]);

    const now = new Date();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        files: orderInfo.files.concat(localFiles.map(f => ({ name: f.name, ref: f.ref, url: f.url, type: 'client', size: f.file.size, format: f.file.type, timestamp: (new Date()).getTime() }))),
        clientRevisionNotes: convertToRaw(clientRevisionNotes.getCurrentContent()),
        status: 'revision',
        clientApproved: false,
        revision: true,
        revisionAssigned: now.getTime(),
        revisionDue: now.getTime() + 43200000,
        revisionOverdue: false,
        userRevisionRequested: true
      })
      .then(() => {
        showNotification("Success", "The revision request has been successfully submitted.", "success");
        setOpenRevision(false);
      })
      .catch(error => {
        setFiles(localFiles);
        showNotification("Error", "There was an error submitting the revision. Please, try again.", "error");
      })
  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          description: `Your Order`,
          amount: {
            currency_code: "USD",
            value: (getTotal() - getDiscount()).toFixed(2)
          },
          reference_id: orderInfo.id
        }
      ],
      application_context: {
        brand_name: 'eVerbary',
        landing_page: 'NO_PREFERENCE',
        user_action: 'PAY_NOW',
        payment_method: {
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED'
        }
      }
    });
  };

  const handlePlaceOrder = async paypalID => {
    setOrderSubmitButton('Placing order...');

    const now = new Date();

    const firestore = firebase.firestore();

    const addressID = uuidv4();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        //notes: convertToRaw(editorState.getCurrentContent()),
        timestamp: now.getTime(),
        status: 'new',
        shippingInfo: orderInfo.hardCopy ? { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country } : null,
        paypalOrderID: paypalID
      })
      .then(() => {
        if (signedIn && saveAddress) {
          const address = { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country, default: saveDefault };

          const mShipping = userData && userData.shipping ? (saveDefault ? userData.shipping.map(address => ({ ...address, default: false })).concat([address]) : userData.shipping.concat([address])) : [address];

          firestore.collection('users').doc(firebase.auth().currentUser.uid)
            .update({
              shipping: mShipping
            })
            .then(() => {
            })
            .catch(error => {
              showNotification('Error', "An error ocurred while saving the address. Unfortunately, the address couldn't be saved.", 'error');
            })
        }

        setOrderSubmitButton('Place Order');

        if (order.orderID === orderInfo.id) {
          localStorage.setItem('order', JSON.stringify({ orderID: '', name: '', email: '', parsed: true }));
          setOrder({ orderID: '', name: '', email: '', parsed: true });
        }
      })
      .catch(error => {
        //console.log(error);
        setOrderSubmitButton('Place Order');
        showNotification('Error', "An error ocurred while finishing the order. Please, try again.", 'error');
      })
  }

  const onOrderApprove = (data, actions) => {
    actions.order.capture().then(details => {
      setOpenShipping(false);
      handlePlaceOrder(data.orderID);
    });
  };

  const onOrderPaymentFailure = (data, actions) => {
    showNotification('Error', "An error ocurred while placing the order. Please, try again.", 'error');
  }

  // const triggerRevisionRequest = () => {
  //   if (openRevision) {
  //     submitRevisionRequest();
  //   }
  // }

  const saveNotes = () => {
    const firestore = firebase.firestore();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        notes: convertToRaw(editorState.getCurrentContent()),
      })
      .then(() => {
        showNotification('Success', "Notes have been successfully saved.", 'success');
      })
      .catch(error => {
        //console.log(error);
        showNotification('Error', "An error ocurred while saving the notes. Please, try again.", 'error');
      })
  }

  const handleSubmitReview = async () => {
    setReviewSubmitButton('Submitting...');

    const firestore = firebase.firestore();

    const now = new Date();

    firestore.collection('orders').doc(orderInfo.id).update({ reviewRating: reviewRating.toString().length === 1 ? reviewRating.toString() + '.0' : reviewRating.toString() })
      .then(() => {
        return firestore.collection('reviews').add({
          name: orderInfo.name,
          languageFrom: getLanguage(orderInfo.languageFrom),
          languageTo: getLanguage(orderInfo.languageTo),
          score: reviewRating.toString().length === 1 ? reviewRating.toString() + '.0' : reviewRating.toString(),
          text: reviewText,
          date: now.getTime(),
          email: orderInfo.email
        })
      })
      .then(() => {
        showNotification('Success', "Thanks for leaving a review!.", 'success');
        setReviewSubmitButton('Submit Review');
      })
      .catch(error => {
        showNotification('Error', "An error ocurred while submitting the review. Please, try again.", 'error');
        setReviewSubmitButton('Submit Review');
      })
  }

  const addNotarization = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          description: `Notarization`,
          amount: {
            currency_code: "USD",
            value: 34.90
          },
          reference_id: orderInfo.id
        }
      ],
      application_context: {
        brand_name: 'eVerbary',
        landing_page: 'NO_PREFERENCE',
        user_action: 'PAY_NOW',
        payment_method: {
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED'
        }
      }
    });
  }

  const handleAddNotarization = () => {
    const firestore = firebase.firestore();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        notarization: true,
        notarizationStatus: orderInfo.status === 'completed' ? 'queue' : 'pending'
      })
      .then(() => {
        showNotification('Sucess', "Notarization has been successfully added to your order!", 'success');
      })
      .catch(error => {
        showNotification('Error', "An error ocurred while adding the service. If you have already paid for the service, contact support for assistance.", 'error');
      })
  }

  const onNotarizationApprove = (data, actions) => {
    actions.order.capture().then(details => {
      handleAddNotarization();
    });
  }

  const onNotarizationFailure = (data, actions) => {
    showNotification('Error', "An error ocurred while adding the service. Please, try again.", 'error');
  }

  const addHardCopy = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [
        {
          description: `Hard Copy Delivery`,
          amount: {
            currency_code: "USD",
            value: getHardCopyPrice()
          },
          reference_id: orderInfo.id
        }
      ],
      application_context: {
        brand_name: 'eVerbary',
        landing_page: 'NO_PREFERENCE',
        user_action: 'PAY_NOW',
        payment_method: {
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED'
        }
      }
    });
  }

  const handleAddHardCopy = () => {
    const firestore = firebase.firestore();

    const addressID = uuidv4();

    firestore.collection('orders').doc(orderInfo.id)
      .update({
        hardCopy: true,
        hardCopyStatus: orderInfo.status === 'completed' ? 'queue' : 'pending',
        hardCopyExtra: { country: country, method: hardCopyMethod, requireSignature: hardCopyRequireSignature },
        shippingInfo: { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country },
      })
      .then(() => {

        if (signedIn && saveAddress) {
          const address = { id: addressID, recipient: recipient, street: street, apartment: apartment, city: city, region: region, zipCode: zipCode, country: country, default: saveDefault };

          const mShipping = userData && userData.shipping ? (saveDefault ? userData.shipping.map(address => ({ ...address, default: false })).concat([address]) : userData.shipping.concat([address])) : [address];

          firestore.collection('users').doc(firebase.auth().currentUser.uid)
            .update({
              shipping: mShipping
            })
            .then(() => {
            })
            .catch(error => {
              showNotification('Error', "An error ocurred while saving the address. Unfortunately, the address couldn't be saved.", 'error');
            })
        }

        setCountry('');
        setHardCopyMethod('');
        setHardCopyRequireSignature('');
        showNotification('Sucess', "Hard Copy Delivery has been successfully added to your order!", 'success');
      })
      .catch(error => {
        showNotification('Error', "An error ocurred while adding the service. If you have already paid for the service, contact support for assistance.", 'error');
      })
  }

  const onHardCopyApprove = (data, actions) => {
    actions.order.capture().then(details => {
      setOpenHardCopyShipping(false);
      handleAddHardCopy();
    });
  }

  const getHardCopyPrice = () => {
    if (country === '' || hardCopyMethod === '') {
      return '';
    }
    
    const orderDate = new Date(order.timestamp);
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    const price = (orderDate >= priceUpdateDate) ? ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(country) ? (hardCopyMethod === 'priority' ? 19.95 : 37.95) : (hardCopyMethod === 'priority' ? 49.95 : 78.95) : ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(country) ? (hardCopyMethod === 'priority' ? 12.95 : 29.95) : (hardCopyMethod === 'priority' ? 39.95 : 67.95);
  
    if (hardCopyRequireSignature) {
      if(orderDate >= priceUpdateDate){
        return (price + 4.99).toFixed(2);
      }else{
        return (price + 2.54).toFixed(2);
      }
    } else {
      return price.toFixed(2);
    }
  }

  // useEnterKey(() => triggerRevisionRequest());

  const disabledPayment = recipient === '' || street === '' || region === '' || city === '' || country === '' || zipCode === '';

  const checkCustomCCEMails = () => {
    const emailRegex = new RegExp("^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,}(\\.?[a-z]{2})?$");
    const lowerCaseEmail = newCCEmail.toLowerCase();
    let EmailMatched = false;

    if(newCCEmail === "" || !emailRegex.test(lowerCaseEmail)){
      showNotification('Error', "Invalid Email, please enter valid email.", 'error');
      return;
    }

      teamUsers.forEach(user => {
        if(user.email === lowerCaseEmail){
          EmailMatched = true;
          return;
        }
      })

      if(customCCEmails.includes(lowerCaseEmail) || EmailMatched || orderInfo.email === lowerCaseEmail){
        showNotification('Error', "Email already exist, please enter another email.", 'error');
        return;
      }
      addCCEmail([...customCCEmails, lowerCaseEmail], [...ccEmails, lowerCaseEmail],[...selectedCustomCCEmails, lowerCaseEmail]);
      setShowNewCCEmail(false);
      setNewCCEmail("");
      showNotification('Email added', "Email added successfully.", 'success');
  }

  const onSelectAllEmail = (state) => {
    if(state){
      const teamEmails = teamUsers.map(user => user.email);
      const allEmails = [...teamEmails, ...customCCEmails];
      addCCEmail(customCCEmails, allEmails, customCCEmails);
    }else{
      addCCEmail(customCCEmails, [], []);
    }
  }

  const checkAllEmailsSelected =(customEmails, selectedEmails)=>{
    var arrayMatch = true;
    const emails = [...teamUsers.map(user => user.email), ...customEmails];
    emails.forEach(email => { if(!selectedEmails.includes(email)) {
      arrayMatch = false;
      return;
    }})

    if(arrayMatch){
      setSelectAllCCEmails(true);
      return true;
    }
    setSelectAllCCEmails(false);
    return false;
  }


  const addCCEmail = (customEmails, allSelectedEmails, selectedCustomEmails) => {
    const firestore = firebase.firestore();

    var teamMatched = true;
    const allTeamEmails = teamUsers.map(user => user.email);
    allTeamEmails.forEach(email => { if(!allSelectedEmails.includes(email)) {
      teamMatched = false;
      return;
    }})

    const selectAll = checkAllEmailsSelected(customEmails, allSelectedEmails);

    firestore.collection("orders").doc(orderID).update({
      ccEmails: {
        selectAllCCEmails: selectAll,
        selectAllTeamEmails: teamMatched,
        customCCEmails: customEmails,
        selectedCCEmails: allSelectedEmails,
        selectedCustomCCEmails: selectedCustomEmails
      }
    }).catch(e=> {
      showNotification('Error', "Error occured please try again", 'error');
    })
  }

  const onSelectEmail = (email) => {
    const selected = ccEmails.includes(email) ?  ccEmails.filter(data => data !== email) 
    :  [...ccEmails, email];

    addCCEmail(customCCEmails, selected, selectedCustomCCEmails);
  }

  const onSelectCustomEmail = (email) => {
    const selectedAll = ccEmails.includes(email) ?  ccEmails.filter(data => data !== email) 
    :  [...ccEmails, email];

    const selectedCustom = selectedCustomCCEmails.includes(email) ? selectedCustomCCEmails.filter(data => data !== email) : [...selectedCustomCCEmails, email];

    addCCEmail(customCCEmails, selectedAll, selectedCustom);
  }

  const removeCustomEmail = (email) => {
    addCCEmail(customCCEmails.filter(data => data !== email), ccEmails.filter(data => data !== email), selectedCustomCCEmails.filter(data => data !== email));

  }

  return (
    <>
    <TopBar/>
    <div className="homeContainer">
      <animated.div style={statusOverlayProps} className="checkoutStatusOverlay">
        We are finishing up the order and redirecting you. This will take a second.
      </animated.div>

      {openRevision && <animated.div style={revisionModalProps} className="revisionModalBg" onClick={() => setOpenRevision(false)} />}
      {openRevision && <animated.div style={revisionModalProps} className="revisionModalOuter">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0 }}>
          Revision Notes
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={orderInfo && orderInfo.status === 'revision' ? "Close" : "Cancel"} onClick={() => setOpenRevision(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
            <div className="singleOrderRevisionButtonsSeparator" />
            <AltAnimatedButton color={'#333'} text={orderInfo && orderInfo.status === 'revision' ? (windowSize.width < 768 && windowSize.orientation === 'portrait' ? "Update" : "Update Revision") : (windowSize.width < 768 && windowSize.orientation === 'portrait' ? "Submit" : "Submit Revision")} onClick={() => submitRevisionRequest()} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="singleTranslationNotesContainer">
          <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#18b7bb' }}>
            <strong>Client's Revision Notes</strong>
            <div className="singleOrderTextButtons">
              <Bold className="singleOrderRichTextButton" onClick={() => handleBoldClick(true)} />
              <Italic className="singleOrderRichTextButton" onClick={() => handleItalicClick(true)} />
              <Underline className="singleOrderRichTextButton" onClick={() => handleUnderlineClick(true)} />
              <List className="singleOrderRichTextButton" onClick={() => toggleUL(true)} />
            </div>
          </div>
          <div className="singleTranslationRichTextEditor">
            <Editor
              editorState={clientRevisionNotes}
              onChange={clientRevisionNotes => setClientRevisionNotes(clientRevisionNotes)} 
            />
          </div>
        </div>
        <div className="singleQuoteMainPrimaryFilesTitle">Revision Files</div>
        {orderInfo && orderInfo.files && <div className="quoteDetailsFileUploaderContainer">
          <FileUploaderButton display backend text="Add Files" files={orderInfo.files.filter(f => f.type === 'client').concat(files)} handleFiles={handleFiles} handleDeleteFile={handleDeleteFile} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.4 : 1) : (windowSize.orientation === 'portrait' ? 4 : 1.3)}/>
        </div>}
      </animated.div>}

      {openConfirmation && <animated.div style={confirmationModalProps} className="revisionModalBg" onClick={() => setOpenConfirmation(false)} />}
      {openConfirmation && <animated.div style={confirmationModalProps} className="confirmationModalOuter">
        <div className="singleTranslationNotesContainer">
          <div className="confirmationModalCheckContainer">
            <Check className="confirmationModalCheck" />
          </div>
          <p className="confirmationModalTitle">Your order has been placed!</p>
          <p className="confirmationModalText">Please take a few moments to read the information below. Understanding the specifics of the translation process will help ensure the smooth and quick delivery of your documents. Please contact our support team at <a href="mailto:support@everbary.com" target="_blank" rel="noopener noreferrer" style={{ color: '#444' }}>support@everbary.com</a> if you have any questions. </p>
          <p className="confirmationModalSubtitle">CHECK YOUR EMAIL</p>
          <p className="confirmationModalText">We sent an order confirmation email to the address you provided. Please check that it arrived. If not, contact us for assistance. All communications are sent to you via email, so you must verify you are receiving our emails.</p>
          <p className="confirmationModalSubtitle">TURNAROUND TIME</p>
          <p className="confirmationModalText">The turnaround time provided is an estimate, not a guarantee, to provide the digital version of your translation. The turnaround time does not include optional services such as notarization or shipping. The time starts once your order is assigned to a translator. Our support staff processes and assigns orders daily from 8 a.m - 11 p.m. EST and outside of those hours as availability allows.</p>
          <div className="confirmationModalCloseContainer">
            <AltAnimatedButton color={'#FF7C1D'} text="Close" onClick={() => setOpenConfirmation(false)} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
      </animated.div>}

      {openShipping && <animated.div style={shippingModalProps} className="revisionModalBg" onClick={() => setOpenShipping(false)} />}
      {openShipping && <animated.div style={shippingModalProps} className="revisionModalOuter">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0 }}>
          Shipping Information
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={"Cancel"} onClick={() => setOpenShipping(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="singleQuoteShippingFormSeparator" />
        <div className="orderAddressOptionalInnerForm">
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <div className="orderPaymentTripleLine">
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
              </div>
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
              </div>
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
              </div>
            </div>
          </div>
          <div className="singleQuoteInputsContainer">
            <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect" disabled>
              <option key="" value="" disabled>Country (*)</option>
              {countries.map(country => 
                <option key={country} value={country}>{country}</option>
              )}
            </select>
          </div>
          {signedIn && <div className="hardCopyCheckboxLine" onClick={() => setSaveAddress(sa => !sa)}>
            <div className="hardCopyCheckbox">
              <Check className="hardCopyCheck" style={{ opacity: saveAddress ? 1 : 0 }} />
            </div>
            <p className="hardCopyCheckboxText">Save address</p>
          </div>}
          { signedIn && saveAddress &&
            <div className="hardCopyCheckboxLine" onClick={() => setSaveDefault(ss => !ss)}>
              <div className="hardCopyCheckbox">
                <Check className="hardCopyCheck" style={{ opacity: saveDefault ? 1 : 0 }} />
              </div>
              <p className="hardCopyCheckboxText">Set as default</p>
            </div>
          }
          <div className="singleQuoteShippingFormSeparator" />
          <div className="accountProfileFormButton" style={{ opacity: disabledPayment ? 0.4 : 1, pointerEvents: disabledPayment ? 'none' : 'all', cursor: disabledPayment ? 'default' : 'pointer' }}>
            <PayPalButtons
              style={{ layout: 'horizontal', height: 40 }}
              createOrder={createOrder}
              onApprove={onOrderApprove}
              onError={onOrderPaymentFailure}
              onShippingChange={function noRef() {}}
            />
          </div>
        </div>
      </animated.div>}

      {openHardCopyShipping && <animated.div style={hardCopyShippingModalProps} className="revisionModalBg" onClick={() => setOpenHardCopyShipping(false)} />}
      {openHardCopyShipping && <animated.div style={hardCopyShippingModalProps} className="revisionModalOuter">
        <div className="singleQuoteMainPrimaryFilesTitle" style={{ marginTop: 0 }}>
          Shipping Information
          <div className="singleOrderRevisionButtons">
            <AltAnimatedButton color={'#E1504A'} text={"Cancel"} onClick={() => setOpenHardCopyShipping(false)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
          </div>
        </div>
        <div className="singleQuoteShippingFormSeparator" />
        <div className="orderAddressOptionalInnerForm">
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Recipient (*)" value={recipient} onChange={setRecipient} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Street (*)" value={street} onChange={setStreet} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <FormTextInput placeholder="Apartment, Suite or Floor Number" value={apartment} onChange={setApartment} color='#666' />
          </div>
          <div className="singleQuoteInputsContainer">
            <div className="orderPaymentTripleLine">
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder="City (*)" value={city} onChange={setCity} color='#666' />
              </div>
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder={country === 'United States' ? "State (*)" : "Region (*)"} value={region} onChange={setRegion} color='#666' />
              </div>
              <div className="orderPaymentTripleField">
                <FormTextInput placeholder="Postal Code (*)" value={zipCode} onChange={setZipCode} color='#666' />
              </div>
            </div>
          </div>
          <div className="singleQuoteInputsContainer">
            <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect" disabled>
              <option key="" value="" disabled>Country (*)</option>
              {countries.map(country => 
                <option key={country} value={country}>{country}</option>
              )}
            </select>
          </div>
          {signedIn && <div className="hardCopyCheckboxLine" onClick={() => setSaveAddress(sa => !sa)}>
            <div className="hardCopyCheckbox">
              <Check className="hardCopyCheck" style={{ opacity: saveAddress ? 1 : 0 }} />
            </div>
            <p className="hardCopyCheckboxText">Save address</p>
          </div>}
          { signedIn && saveAddress &&
            <div className="hardCopyCheckboxLine" onClick={() => setSaveDefault(ss => !ss)}>
              <div className="hardCopyCheckbox">
                <Check className="hardCopyCheck" style={{ opacity: saveDefault ? 1 : 0 }} />
              </div>
              <p className="hardCopyCheckboxText">Set as default</p>
            </div>
          }
          <div className="singleQuoteShippingFormSeparator" />
          <div className="accountProfileFormButton" style={{ opacity: disabledPayment ? 0.4 : 1, pointerEvents: disabledPayment ? 'none' : 'all', cursor: disabledPayment ? 'default' : 'pointer' }}>
           <PayPalButtons
              style={{ layout: 'horizontal', height: 40 }}
              createOrder={addHardCopy}
              onApprove={onHardCopyApprove}
              onError={onNotarizationFailure}
              onShippingChange={function noRef() {}}
            />
          </div>
        </div>
      </animated.div>}

      <Navbar windowSize={windowSize} />
        <animated.div style={{ opacity: loadedOrder ? 1 : 0 }} className="quoteMainContainer singleQuoteOuter">
          <div id="mainPrimary" className="singleQuoteMainPrimaryContainer">
            {orderInfo && orderInfo.status === 'created' && <div className="singleQuoteCheckoutContainer">
              <img src={require('../../assets/standard.png')} alt="Checkout" className="singleQuoteCheckoutImg" />
              <div className="singleQuoteCheckoutInfo">
                <p className="singleQuoteCheckoutInfoText">Ready to place your order? Continue to the checkout, select your payment method, and confirm your order.</p>
                <div className="accountProfileFormButton">
                  { orderInfo.hardCopy
                  ? <AltAnimatedButton text="Checkout Now" onClick={() => setOpenShipping(true)} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.3 : 1) : (windowSize.orientation === 'portrait' ? 3 : 1.3)} />
                  : <PayPalButtons
                      style={{ layout: 'horizontal', height: 40 }}
                      createOrder={createOrder}
                      onApprove={onOrderApprove}
                      onError={onOrderPaymentFailure}
                      onShippingChange={function noRef() {}}
                    />
                  }
                </div>
              </div>
            </div>}
            <div className="singleQuoteMainPrimaryTitleRow">
              <p className="singleQuoteMainPrimaryTitle">{`Order #${orderInfo && orderInfo.orderNumber}`}</p>
             
                {orderInfo && <div className="quoteStatusContainer" style={{ minWidth: 'auto', backgroundColor: orderInfo.status === 'created' ? '#F6C907' : orderInfo.status === 'new' ? "#FF7C1D" : orderInfo.status === 'assigned' || orderInfo.status === 'in-progress' || orderInfo.status === 'support-review' || (orderInfo.status === 'revision' && !orderInfo.supportApproved) ? '#1491F8' : orderInfo.status === 'user-review' ? '#30d5c8' : orderInfo.status === 'revision' ? '#333' : orderInfo.status === 'cancelled' ? '#E1504A' : '#17BC5B' }}>
                  { orderInfo.status === 'created' ? 'CREATED' : orderInfo.status === 'new' ? "NEW ORDER" : orderInfo.status === 'assigned' || orderInfo.status === 'in-progress' || orderInfo.status === 'support-review' || (orderInfo.status === 'revision' && !orderInfo.supportApproved) ? 'IN PROGRESS' : orderInfo.status === 'user-review' ? 'READY' : orderInfo.status === 'revision' ? 'REVISION' : orderInfo.status === 'cancelled' ? 'CANCELLED' : 'COMPLETED'}
                </div>}
               
              
            </div>
                <div style={{width: "95%", display: "flex", justifyContent:"space-between"}}>
            <div className="singleQuoteMainPrimaryInfo">
              {orderInfo && <p className="singleQuoteMainPrimaryInfoTitle"><strong>{getLanguage(orderInfo.languageFrom)}</strong> to <strong>{getLanguage(orderInfo.languageTo)}</strong></p>}
              {orderInfo && <p className="singleQuoteMainPrimaryInfoSub">{formatDate(orderInfo.timestamp)}</p>}
            </div>
            <div onClick={handlePrint} className="quoteStatusContainer" style={{ maxHeight:"15px", minWidth: 'auto', backgroundColor: '#fff', border:"1px solid #666", color:"#666", cursor:"pointer" }}>
                  <Download style={{width:"15px", height:"15px"}}/>
                  Invoice
                </div>
                </div>
            
            <div className="singleQuoteMainPrimaryTitleRow" style={{ flexDirection: phoneScreen ? 'column' : 'row' }}>
              <div className="singleQuoteCardContainer">
                <div className="singleQuoteCardTitle">CONTACT</div>
                {orderInfo && <p className="singleQuoteCardName">{orderInfo.name}</p>}
                {orderInfo && <p className="singleQuoteCardText">{orderInfo.email}</p>}
              </div>
              <div className="singleQuoteCardContainer">
                <div className="singleQuoteCardTitle">DELIVERY</div>
                {orderInfo && !orderInfo.hardCopy
                ? <p className="singleQuoteCardText">Digital delivery only</p>
                : orderInfo && orderInfo.status === 'created'
                  ? <p className="singleQuoteCardText">Information provided at Checkout</p>
                  : orderInfo && orderInfo.shippingInfo && <p className="singleQuoteCardText">
                      <strong>{orderInfo.shippingInfo.recipient}</strong><br/>
                      {orderInfo.shippingInfo.street}{orderInfo.shippingInfo.apartment !== '' ? ", " + orderInfo.shippingInfo.apartment : null}<br/>
                      {orderInfo.shippingInfo.city + ', ' + orderInfo.shippingInfo.region + ', ' + orderInfo.shippingInfo.zipCode + ', ' + orderInfo.shippingInfo.country}<br/>
                    </p>
                }
              </div>
            </div>

            {userData && userData.hasTeam && orderInfo && orderInfo.teamId  &&
             <div className="singleQuoteMainPrimaryTitleRow" style={{ flexDirection: phoneScreen ? 'column' : 'row' }}>
              <div className="singleQuoteCardContainer contentWidth">
                <div className="singleQuoteCardTitle">CC'ed to this order</div>
                <div className='checkboxContainer'>
               { (teamUsers.length > 0 || customCCEmails.length > 0) && <div className="checkboxWrap" onClick={() => { onSelectAllEmail(!selectAllCCEmails)
                        }}>
                      <div className="hardCopyCheckbox">
                        <Check className="hardCopyCheck" style={{ opacity: selectAllCCEmails ? 1 : 0 }} />
                      </div>
                      <p className="singleQuoteCardText nameText">Select all</p>
                    </div>}
                    {teamUsers.map(user => 
                    <div className="checkboxWrap" key={user.id}>
                      <div className="hardCopyCheckbox" onClick={() => onSelectEmail(user.email)}>
                        <Check className="hardCopyCheck" style={{ opacity: ccEmails.includes(user.email) ? 1 : 0 }} />
                      </div>
                      <p className="singleQuoteCardText nameText" onClick={() => onSelectEmail(user.email)}>{`${user.name} (${user.email})`}</p>
                    </div>)}
                    {customCCEmails.map(email => 
                    <div className="checkboxWrap" key={email}>
                      <div className="hardCopyCheckbox" onClick={() => onSelectCustomEmail(email)}>
                        <Check className="hardCopyCheck" style={{ opacity: ccEmails.includes(email) ? 1 : 0 }} />
                      </div>
                      <p className="singleQuoteCardText nameText" onClick={() => onSelectCustomEmail(email)}>{email}</p>
                      <Trash2 className="editBtn" style={{marginTop: "2px"}}onClick={()=> {removeCustomEmail(email)}}/> 
                    </div>)}

                </div>
                   {customCCEmails.length < 5 && <div className='checkboxContainer'>
                      {!showNewCCEmail ?
                    <AltAnimatedButton text={"Add Email"}  onClick={() => setShowNewCCEmail(true)} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                    : <>
                    <FormTextInput placeholder={'Email'} value={newCCEmail} onChange={setNewCCEmail} color='#666' onKeyDown={(e) => e.key === "Enter" && checkCustomCCEMails()}/>
                    <AltAnimatedButton text={"Add email"}  onClick={() => checkCustomCCEMails()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.7 : 1.1) : (windowSize.orientation === 'portrait' ? 3.5 : 1)} />
                    </>
                      }
                    </div>}
              </div>
              </div>
            }
            <div className="singleQuoteMainPrimaryFilesContainer">
              <div className="singleQuoteMainPrimaryFilesTitle">Source Files</div>
              { orderInfo && orderInfo.files.filter(f => f.type === 'source' || f.type === 'reference' || f.type === 'client').map((file, index) => 
                <div key={file.name} className="singleQuoteFileRow">
                  <p className="singleQuoteFileTitle" onClick={() => downloadFile(file.url)}>{file.name}</p>
                  <p className="singleQuoteFileSub">{`${file.type === 'source' ? 'Source' : 'Revision Source'} uploaded ${formatDate(file.timestamp)}`}</p>
                </div>
              )}
            </div>
            { orderInfo && (orderInfo.status === 'user-review' || orderInfo.status === 'revision' || orderInfo.status === 'cancelled' || orderInfo.status === 'completed') && orderInfo.supportApproved && orderInfo.files.filter(f => f.type === 'translation' || f.type === 'revision').length > 0 && 
              <div className="singleQuoteMainPrimaryFilesContainer">
                <div className="singleQuoteMainPrimaryFilesTitle">Translated Files</div>
                { orderInfo.files.filter(f => (f.type === 'translation' || (f.type === 'revision' && (orderInfo.status === 'user-review' || orderInfo.status === 'completed'))) && (!orderInfo.certified || f.format === 'application/pdf')).map((file, index) => 
                  <div key={file.name} className="singleQuoteFileRow">
                    <p className="singleQuoteFileTitle" onClick={() => downloadFile(file.url)}>{file.name}</p>
                    <p className="singleQuoteFileSub">{`${file.type === 'translation' ? 'Translation' : 'Revision'} uploaded ${formatDate(file.timestamp)}`}</p>
                  </div>
                )}
              </div>
            }
            <div className="singleQuoteMainPrimaryFilesContainer">
              {orderInfo &&  <div className="singleQuoteMainPrimaryFilesTitle">Notes</div>}
              {orderInfo && <div className="singleTranslationNotesContainer">
                <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#18b7bb' }}>
                  <strong>Client's Notes</strong>
                  {orderInfo.status === 'created' && <div className="singleOrderTextButtons">
                    <Bold className="singleOrderRichTextButton" onClick={() => handleBoldClick(false)} />
                    <Italic className="singleOrderRichTextButton" onClick={() => handleItalicClick(false)} />
                    <Underline className="singleOrderRichTextButton" onClick={() => handleUnderlineClick(false)} />
                    <List className="singleOrderRichTextButton" onClick={() => toggleUL(false)} />
                    <div className="singleTranslationNotesAuxButton" onClick={() => saveNotes()}>Save</div>
                  </div>}
                </div>
                <div className="singleTranslationRichTextEditor">
                  <Editor
                    editorState={editorState}
                    onChange={editorState => setEditorState(editorState)}
                    readOnly={orderInfo.status !== 'created'}
                  />
                </div>
              </div>}
              {supportReplyNotes.getCurrentContent().getPlainText() !== '' && <div className="singleTranslationNotesContainer">
                <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#e91e63' }}><strong>Support's Notes</strong></div>
                <div className="singleTranslationRichTextEditor">
                  <Editor
                    editorState={supportReplyNotes}
                    onChange={supportReplyNotes => setSupportReplyNotes(supportReplyNotes)}
                    readOnly
                  />
                </div>
              </div>}
              {clientRevisionNotesFinal.getCurrentContent().getPlainText() !== '' && <div className="singleTranslationNotesContainer">
                <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#18b7bb' }}><strong>Client's Revision Notes</strong></div>
                <div className="singleTranslationRichTextEditor">
                  <Editor
                    editorState={clientRevisionNotesFinal}
                    onChange={clientRevisionNotesFinal => setClientRevisionNotesFinal(clientRevisionNotesFinal)}
                    readOnly
                  />
                </div>
              </div>}
              { orderInfo && (orderInfo.status === 'user-review' || orderInfo.status === 'completed') && translatorNotesClient.getCurrentContent().getPlainText() !== '' &&
                <div className="singleTranslationNotesContainer">
                  <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#333' }}>
                    <strong>Translator Revision Notes</strong>
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={translatorNotesClient}
                      onChange={translatorNotesClient => setTranslatorNotesClient(translatorNotesClient)}
                      readOnly
                    />
                  </div>
                </div>
              }
            </div>
            {orderInfo && orderInfo.notarization && orderInfo.notarizationStatus 
            ? <div className="singleQuoteMainPrimaryFilesContainer">
                <div className="singleQuoteMainPrimaryFilesTitle">Notarization</div>
                <div className="quoteStatusContainer" style={{ minWidth: 'auto', marginTop: 'calc(3vh)', marginBottom: 'calc(2vh)', backgroundColor: orderInfo.notarizationStatus === 'pending' ? '#FF7C1D' : orderInfo.notarizationStatus === 'queue' ? '#1491F8' : orderInfo.notarizationStatus === 'completed' ? '#17BC5B' : 'transparent' }}>
                  {orderInfo.notarizationStatus === 'pending' ? 'Approval Pending' : orderInfo.notarizationStatus === 'queue' ? 'In the Queue' : orderInfo.notarizationStatus === 'completed' ? 'Completed' : ''}
                </div>
                <p className="singleQuoteCardText">
                  {orderInfo.notarizationStatus === 'pending' 
                    ? 'The order needs to be finalized before notarizing your documents.' 
                    : orderInfo.notarizationStatus === 'queue' 
                      ? 'The translated files are in the process of being notarized.' 
                      : orderInfo.notarizationStatus === 'completed' 
                        ? 'The notarized files have been uploaded in the Translated Files section.' 
                        : ''}
                </p>
              </div>
            : orderInfo && orderInfo.status !== 'cancelled' && orderInfo.status !== 'created'
              ? <div className="singleQuoteMainPrimaryFilesContainer">
                  <div className="singleQuoteMainPrimaryFilesTitle">Notarization</div>
                  <p className="singleOrderSubText">Do you want to have your translation notarized for <i><u>$34.90</u></i>? Valid in all 50 states. Click the button below to proceed to checkout and add this service to your order.</p>
                  <div className="accountProfileFormButton">
                    {!openRevision && <PayPalButtons
                      style={{ layout: 'horizontal', height: 40 }}
                      createOrder={addNotarization}
                      onApprove={onNotarizationApprove}
                      onError={onNotarizationFailure}
                      onShippingChange={function noRef() {}}
                    />}
                  </div>
                </div>
              : null
            }
            {orderInfo && orderInfo.hardCopy && orderInfo.hardCopyStatus 
            ? <div className="singleQuoteMainPrimaryFilesContainer">
                <div className="singleQuoteMainPrimaryFilesTitle">Hard Copy</div>
                <div className="quoteStatusContainer" style={{ minWidth: 'auto', marginTop: 'calc(3vh)', marginBottom: 'calc(2vh)', backgroundColor: orderInfo.hardCopyStatus === 'pending' ? '#FF7C1D' : orderInfo.hardCopyStatus === 'queue' ? '#1491F8' : orderInfo.hardCopyStatus === 'shipped' ? '#17BC5B' : 'transparent' }}>
                  {orderInfo.hardCopyStatus === 'pending' ? 'Approval Pending' : orderInfo.hardCopyStatus === 'queue' ? 'In the Queue' : orderInfo.hardCopyStatus === 'shipped' ? `Shipped (Tracking ID: ${orderInfo.hardCopyTrackingID})` : ''}
                </div>
                <p className="singleQuoteCardText">
                  {orderInfo.hardCopyStatus === 'pending' 
                    ? 'The order needs to be finalized before shipping your documents.' 
                    : orderInfo.hardCopyStatus === 'queue' 
                      ? 'The translated files are in the process of being shipped based on the address and shipping service you selected when placing the order.' 
                      : orderInfo.hardCopyStatus === 'shipped' 
                        ? <span>The translated files have been shipped! You can track the status of your package using the Tracking ID <a style={{ textDecoration: 'underline', cursor: 'pointer', color: '#666' }} href="https://tools.usps.com/go/TrackConfirmAction_input" target="_blank" rel="noopener noreferrer">here.</a></span>
                        : ''}
                </p>
              </div>
            : orderInfo && orderInfo.status !== 'cancelled' && orderInfo.status !== 'created'
              ? <div className="singleQuoteMainPrimaryFilesContainer">
                  {/* <div className="singleQuoteMainPrimaryFilesTitle">Hard Copy</div>
                  <p className="singleOrderSubText">Do you want to get the original translation shipped to you via USPS Priority or Express Mail? Complete the required fields and click the 'Checkout Now' button to proceed to checkout and add this service to your order. <br/><br/>*Prices will vary according to the country and shipping option selected.</p>
                  <div className="quoteDetailsInputsContainer">
                    <div className="quoteDetailsSelectContainer">
                      <select value={country} onChange={(e) => setCountry(e.target.value)} className="quoteDetailsSelect">
                        <option key="" value="" disabled>Country (*)</option>
                        {countries.map(mCountry => 
                          <option key={mCountry} value={mCountry}>{mCountry}</option>
                        )}
                      </select>
                    </div>
                    <div className="quoteDetailsSelectContainer">
                      <select placeholder="USPS Service (*)" value={hardCopyMethod} onChange={(e) => setHardCopyMethod(e.target.value)} className="quoteDetailsSelect">
                        <option key="" value="" disabled>USPS Service (*)</option>
                        <option key="priority" value="priority">{['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(country) ? "Priority: 2-3 days in transit" : "Priority: 6-10 days in transit"}</option>
                        <option key="express" value="express">{['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(country) ? "Express: 1-2 days in transit" : "Express: 3-5 days in transit" }</option>
                      </select>
                    </div>
                  </div>
                  <div className="hardCopyCheckboxLine" onClick={() => setHardCopyRequireSignature(hc => !hc)}>
                    <div className="hardCopyCheckbox">
                      <Check className="hardCopyCheck" style={{ opacity: hardCopyRequireSignature ? 1 : 0 }} />
                    </div>
                    <p className="hardCopyCheckboxText">Require signature for delivery $4.99</p>
                  </div>
                  <div className="singleOrderHardCopySeparator" />
                  <div className="accountProfileFormButton">
                    <AltAnimatedButton text={`Checkout Now ($${getHardCopyPrice()})`} disabled={country === '' || hardCopyMethod === ''} onClick={() => setOpenHardCopyShipping(true)} fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.3 : 1) : (windowSize.orientation === 'portrait' ? 3 : 1.3)} />
                  </div> */}
                </div>
              : null
            }
            {orderInfo && orderInfo.status === 'completed' && 
              <div className="singleQuoteMainPrimaryFilesContainer">
                <div className="singleQuoteMainPrimaryFilesTitle">Leave a Review</div>
                <div className="singleOrderReviewSeparator" />
                {!orderInfo.reviewRating
                ? <>
                    <div className="singleOrderReviewSelectContainer">
                      <p className="formInputSimpleLabel">Rating (1 to 5) *</p>
                      <div className="singleOrderReviewSliderContainer">
                        <input type="range" min="1" max="5" step="0.5" value={reviewRating} onChange={(e) => setReviewRating(e.target.value)} className="singleOrderReviewSlider" />
                        <p className="singleOrderReviewSliderResult">{reviewRating.toString().length === 1 ? reviewRating.toString() + '.0' : reviewRating}</p>
                      </div>
                    </div>
                    <FormTextArea placeholder="Review *" value={reviewText} onChange={setReviewText} color='#666' />
                    <div className="confirmationModalCloseContainer">
                      <AltAnimatedButton color={'#FF7C1D'} disabled={reviewText === ''} backend text={reviewSubmitButton} onClick={() => handleSubmitReview()} fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
                    </div>
                  </>
                : <div className="singleOrderReviewMessage">Thanks for leaving a review! See more reviews like yours in our <span className="hoveringTextLink" onClick={(e) => transitionTo('/reviews',e)}>reviews</span> page.</div>
                }
              </div>
            }
          </div>
          <div id="mainSecondary" className="singleQuoteMainSecondaryContainer">
            <div className="singleQuoteOrderSummary">
              <div className="singleQuoteOrderSummaryTitle">SUMMARY</div>
              <div className="singleQuoteOrderSummaryInfo">
                <div className="singleQuoteOrderSummaryInfoMain">
                  {orderInfo && <p className="singleQuoteOrderSummaryInfoType">{orderInfo.certified ? "Certified Translation" : "Standard Translation"}</p>}
                  {orderInfo && <p className="singleQuoteOrderSummaryInfoCount">{orderInfo.certified ? (orderInfo.pagesCount !== '' ? orderInfo.pagesCount + " pages (" + (parseInt(orderInfo.pagesCount, 10) * 250) + " words max)" : "0 pages (0 words max)") : (orderInfo.wordCount !== '' ? orderInfo.wordCount + " words" : "0 words")}</p>}
                </div>
                {orderInfo && <p className="singleQuoteOrderSummaryInfoType">{orderInfo.certified ? (orderInfo.pagesCount !== "" ? '$' + (parseInt(orderInfo.pagesCount, 10) * 24.95).toFixed(2) : "$0.00") : ((orderInfo.wordCount !== '' ? '$' + (parseInt(orderInfo.wordCount, 10) * 0.1).toFixed(2) : '$0.00'))}</p>}
              </div>
              {orderInfo && orderInfo.notarization ? 
              <div className="singleQuoteOrderSummaryInfo">
                <div className="singleQuoteOrderSummaryInfoMain">
                  <p className="singleQuoteOrderSummaryInfoType">Notarization</p>
                  <p className="singleQuoteOrderSummaryInfoCount">Valid in all 50 states</p>
                </div>
                <p className="singleQuoteOrderSummaryInfoType">$34.90</p>
              </div> : null}
              {orderInfo && orderInfo.expedited ? 
              <div className="singleQuoteOrderSummaryInfo">
                <div className="singleQuoteOrderSummaryInfoMain">
                  <p className="singleQuoteOrderSummaryInfoType">Expedited Turnaround</p>
                  <p className="singleQuoteOrderSummaryInfoCount">Turnaround reduced by 50%</p>
                </div>
                <p className="singleQuoteOrderSummaryInfoType">{formatTotal(getExpedited())}</p>
              </div> : null}
              {orderInfo && orderInfo.hardCopy && orderInfo.hardCopyExtra.country !== '' && orderInfo.hardCopyExtra.method !== '' ? 
              <div className="singleQuoteOrderSummaryInfo">
                <div className="singleQuoteOrderSummaryInfoMain">
                  <p className="singleQuoteOrderSummaryInfoType">{`USPS ${orderInfo.hardCopyExtra.method.charAt(0).toUpperCase() + orderInfo.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? 'International' : ''}`}</p>
                  <p className="singleQuoteOrderSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? (orderInfo.hardCopyExtra.method === 'priority' ? '2-3' : '1-2') : (orderInfo.hardCopyExtra.method === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
                </div>
                <p className="singleQuoteOrderSummaryInfoType">{`$${(new Date(orderInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? (orderInfo.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (orderInfo.hardCopyExtra.method === 'priority' ? '49.95' : '78.95') : ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(orderInfo.hardCopyExtra.country) ? (orderInfo.hardCopyExtra.method === 'priority' ? '12.95' : '29.95') : (orderInfo.hardCopyExtra.method === 'priority' ? '39.95' : '67.95')}`}</p>
              </div> : null}
              {orderInfo && orderInfo.hardCopy && orderInfo.hardCopyExtra.requireSignature ? 
              <div className="singleQuoteOrderSummaryInfo">
                <div className="singleQuoteOrderSummaryInfoMain">
                  <p className="singleQuoteOrderSummaryInfoType">Signature Required for Delivery</p>
                  <p className="singleQuoteOrderSummaryInfoCount">Adult signature required</p>
                </div>
                <p className="singleQuoteOrderSummaryInfoType">{`$${(new Date(orderInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? 4.99 : 2.54}`}</p>
              </div> : null}
              {orderInfo && orderInfo.discount && orderInfo.discount > 0 ? 
                <div className="singleQuoteOrderSummaryInfo">
                  <div className="singleQuoteOrderSummaryInfoMain">
                    <p className="singleQuoteOrderSummaryInfoType">{`Discount -${orderInfo.discount}%`}</p>
                  </div>
                  <p className="singleQuoteOrderSummaryInfoType">{formatDiscount(getDiscount())}</p>
                </div> : null}
              <div className="singleQuoteOrderSummaryTotal">
                <p>Total</p>
                <p>{orderInfo ? formatTotal(getTotal() - getDiscount()) : 0}</p>
              </div>
            </div>
            {orderInfo && <div className="quoteSecondaryExtraInfo" style={{ width: phoneScreen ? '88%' : 'calc(100% - 3vw)' }}>
              {orderInfo && ((orderInfo.certified && orderInfo.pagesCount !== '') || (!orderInfo.certified && orderInfo.wordCount !== '')) &&
              <div className="orderSecondaryTitle">
                <Clock className="orderSecondaryIcon" />
                {`${getTimeFrame()} est. turnaround`}
              </div>}
              <div className="quoteSecondarySub">This is an estimate to deliver the digital copy based on the current order information you've provided.</div>
              <div className="orderSecondaryTitle">
                <Info className="orderSecondaryIcon" />
                {`Status: ${orderInfo.status === 'created' ? 'Created' : orderInfo.status === 'new' ? 'New Order' : orderInfo.status === 'assigned' || orderInfo.status === 'in-progress' || orderInfo.status === 'support-review' || (orderInfo.status === 'revision' && !orderInfo.supportApproved) ? 'In Progress' : orderInfo.status === 'user-review' ? 'Ready' : (orderInfo.status === 'revision' && orderInfo.supportApproved) ? 'Revision' : orderInfo.status === 'completed' ? 'Completed' : orderInfo.status === 'cancelled' ? 'Cancelled' : ''}`}
              </div>
              <div className="quoteSecondarySub">
                {orderInfo.status === 'created'
                  ? <span>Your order has not been placed yet, but we've saved your progress for you. <br/><br/>Please continue the checkout process when you are ready to place it. You can do this from the <span style={{ color: '#444', fontWeight: 700 }}>PayPal</span> or <span style={{ color: '#444', fontWeight: 700 }}>Checkout Now</span> button at the top of the page. <br/><br/>If you paid for the order but it remains in "Created", do not worry! We most probably received your payment, so we will put it in the queue manually. There is nothing to do on your end.</span>
                  : orderInfo.status === 'new' 
                    ? <span>Your order has been received! Our staff will review the order details and assign it to your translator shortly. We'll email you once your translation is ready for review.<br/><br/><span style={{ cursor: 'pointer', color: '#18b7bb', textDecoration: 'underline' }} onClick={() => setOpenConfirmation(true)}>Show the order confirmation message again.</span></span> 
                    : orderInfo.status === 'assigned' || orderInfo.status === 'in-progress' || orderInfo.status === 'support-review' || (orderInfo.status === 'revision' && !orderInfo.supportApproved) 
                      ? <span>Your order is currently in progress, and your documents are being translated. We'll email you once your translation is ready for your review.</span> 
                      : orderInfo.status === 'user-review' 
                        ? (orderInfo.certified ? <span>Your order is ready! The translated files can be downloaded, and you can request revisions. Your approval is necessary before finalizing, i.e. notarizing the files and sending the physical copies, if requested.</span> : <span>Your order is ready! The translated files can be downloaded, and you can request revisions. Your approval will be necessary before finalizing, this is, leaving a review.</span>)
                        : (orderInfo.status === 'revision' && orderInfo.supportApproved) 
                          ? <span>Your order has been sent back to the translator according to your review request.</span> 
                          : orderInfo.status === 'completed' 
                            ? (orderInfo.certified ? <span>Your order is complete, and you can now write a review! The end-user can check the authenticity of your translation by scanning the QR Code and using the proper credentials.</span> : <span>Your order is complete, and you can now write a review!</span>)
                            : orderInfo.status === 'cancelled' 
                              ? <span>The order has been cancelled and refund issued if appropriate.</span> 
                              : ''
                }
              </div>
            </div>}
            { orderInfo && (orderInfo.status === 'user-review' || orderInfo.status === 'revision') && orderInfo.supportApproved &&
              <div className="singleOrderActionButtonsContainer">
                {orderInfo.status === 'user-review' && <AltAnimatedButton space color={'#17BC5B'} text="Approve Translation" width={windowSize.width > 1024 ? '48%' : '100%'} onClick={() => approveTranslation()} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />}
                <AltAnimatedButton space color={'#333'} text={orderInfo.status === 'user-review' ? "Request Revision" : 'Edit Revision'} width={windowSize.width > 1024 ? '48%' : '100%'} onClick={() => setOpenRevision(true)} backend fontSize={windowSize.width > 1024 ? 0.9 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
              </div>
            }
          </div>
        </animated.div>
      <AccountFooter windowSize={windowSize} />

      <div style={{display:"none"}}>
      <InvoiceToPrint ref={componentRef} orderInfo={orderInfo} expedited={orderInfo && orderInfo.expedited ? formatTotal(getExpedited()) : ""} 
      discount={orderInfo && orderInfo.discount && orderInfo.discount > 0 ? formatDiscount(getDiscount()): 0} subTotal={orderInfo ? formatTotal(getTotal()) : 0}
      total={orderInfo ? formatTotal(getTotal() - getDiscount()) : 0}/>
      </div>
    </div>
    </>
  )
};

export default SingleOrder;