import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Editor, EditorState, convertFromRaw, convertToRaw, RichUtils } from 'draft-js';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { Bold, Italic, Underline, List, Clock } from 'react-feather';

import { languages } from '../../Data/languages';

import { AltAnimatedButton } from '../../Components/AltAnimatedButton';

import { NotificationContext } from '../../Context/NotificationContext';
import { DataContext } from '../../Context/DataContext';

import './SingleQuote.css';
import { useScreenTransition } from '../../Hooks/useScreenTransition';
import { FormSimpleTextInput } from '../../Components/FormSimpleTextInput';

const SingleQuote = ({ windowSize }) => {

  const { id } = useParams();

  const { transitionTo } = useScreenTransition();

  const { showNotification } = useContext(NotificationContext);

  const { userData } = useContext(DataContext);

  const [tab, setTab] = useState('details');

  const [quoteInfo, setQuoteInfo] = useState(null);

  const [clientNotes, setClientNotes] = React.useState(() => EditorState.createEmpty());

  const [openReplyBox, setOpenReplyBox] = useState(false);
  const [supportNotes, setSupportNotes] = React.useState(() => EditorState.createEmpty());

  const [count, setCount] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [submitQuoteButton, setSubmitQuoteButton] = useState('');

  useEffect(() => {
    if (id) {
      const firestore = firebase.firestore();

      const unsubscribe = firestore.collection('quotes').doc(id)
        .onSnapshot(doc => {
          if (doc.exists) {
            setQuoteInfo({ ...doc.data(), id: doc.id });
            
            if (doc.data().notes) {
              setClientNotes(EditorState.createWithContent(convertFromRaw(doc.data().notes)));
            }

            if (doc.data().supportNotes) {
              setSupportNotes(EditorState.createWithContent(convertFromRaw(doc.data().supportNotes)));
            }

            if (doc.data().status !== 'submitted') {
              setCount(doc.data().certified ? doc.data().pagesCount : doc.data().wordCount);
            }

            if (doc.data().discount) {
              setDiscount(doc.data().discount);
            }

            if (doc.data().status === 'submitted') {
              setSubmitQuoteButton('Reply Quote');
            } else if (doc.data().status === 'replied') {
              setSubmitQuoteButton('Update Quote');
            }

            if (!userData || userData.role === 'Translator') {
              showNotification('Error', "You don't have sufficient permissions to see this order. If you think you have the correct permissions, please contact our support team.", 'error');
              transitionTo('/office/quotes');
            }
          } else {
            showNotification('Error', "There is no quote with that ID. Please, try again.", 'error');
            transitionTo('/office/quotes');
          }
        }, error => {
          showNotification('Error', "There was an error loading the quote info. Please, reload the page.", 'error');
        })

      return () => unsubscribe();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatSize = size => {
    if (!size) {
      return '';
    }

    if (size > 1000000) {
      return (size / 1000000).toFixed(2) + "MB"
    } else {
      return (size / 1000).toFixed(2) + "KB"
    }
  }

  const formatDate = timestamp => {
    if (!timestamp) {
      return '';
    }

    const date = new Date(timestamp);

    const splitted = date.toDateString().split(' ');

    return splitted[1] + ' ' + splitted[2] + ', ' + splitted[3] + ' @ ' + date.toTimeString().substr(0, 5);
  }

  const downloadFile = url => {
      window.open(url)
  }

  const toggleSupportReply = () => {
    setOpenReplyBox(o => !o);
  }

  const saveSupportReply = () => {
    const firestore = firebase.firestore();

    firestore.collection('quotes').doc(quoteInfo.id)
      .update({
        supportNotes: convertToRaw(supportNotes.getCurrentContent())
      })
      .then(() => {
        showNotification("Success", "Support notes have been saved.", "success");
      })
      .catch(error => {
        showNotification("Error", "An error ocurred saving the notes. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const handleBoldClick = () => {
    setSupportNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'BOLD'));
  }

  const handleItalicClick = () => {
    setSupportNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
  }

  const handleUnderlineClick = () => {
    setSupportNotes(editorState => RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
  }

  const toggleUL = () => {
    setSupportNotes(editorState => RichUtils.toggleBlockType(editorState, 'unordered-list-item'));
  }

  const submitQuote = () => {
    const firestore = firebase.firestore();
    setSubmitQuoteButton(quoteInfo.status === 'submitted' ? 'Replying...' : 'Updating...');

    const now = new Date();

    firestore.collection('quotes').doc(quoteInfo.id)
      .update({
        status: 'replied',
        pagesCount: quoteInfo.certified ? parseInt(count,10) : 0,
        wordCount: quoteInfo.certified ? 0 : parseInt(count,10),
        replied: now.getTime(),
        discount: discount !== 0 && discount !== '' && discount !== '0' ? Number(discount) : 0,
        discountApplied: discount !== 0 && discount !== '' && discount !== '0' ? true : false
      })
      .then(() => {
        showNotification("Success", "Quote has been successfully updated.", "success");
        setSubmitQuoteButton(quoteInfo.status === 'submitted' ? 'Reply Quote' : 'Update Quote');
      })
      .catch(error => {
        setSubmitQuoteButton(quoteInfo.status === 'submitted' ? 'Reply Quote' : 'Update Quote');
        showNotification("Error", "An error ocurred updating the quote. Please, try again. If it persists, contact technical support.", "error");
      })
  }

  const onChangeCount = (c) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (c === '' || reg.test(c)) {
      setCount(c);
    }
  }

  const onChangeDiscount = (discount) => {
    var reg = /^[0-9]+\.?[0-9]{0,3}$/;

    if (discount === '' || reg.test(discount)) {
      setDiscount(discount);
    }
  }

  const getExpedited = () => {
    const quoteDate = new Date(quoteInfo.timestamp);
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');
    let total = 0;

    if (quoteInfo && quoteInfo.certified) {
      if (count && count !== '' && count !== 0) {
        total = total + parseInt(count, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (quoteInfo) {
      if (count && count !== '' && count !== 0) {
        total = total + parseInt(count, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if(quoteDate >= priceUpdateDate){
      return total * 0.4;
    }

    return total * 0.6;
  }

  const getTotal = () => {
    let total = 0;
    const quoteDate = quoteInfo ? new Date(quoteInfo.timestamp): new Date();
    const priceUpdateDate = new Date('Sun, 30 Oct 2022 20:02:56 GMT');

    if (quoteInfo && quoteInfo.certified) {
      if (count && count !== '' && count !== 0) {
        total = total + parseInt(count, 10) * 24.95;
      } else {
        total = 0;
      }
    } else if (quoteInfo) {
      if (count && count !== '' && count !== 0) {
        total = total + parseInt(count, 10) * 0.1;
      } else {
        total = 0;
      }
    }

    if (quoteInfo && quoteInfo.expedited) {
      total = total + getExpedited();
    }

    if (quoteInfo && quoteInfo.notarization) {
      total = total + 34.90;
    }

    if (quoteInfo && quoteInfo.hardCopy) {
      if(quoteDate >= priceUpdateDate){
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country)) {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
            total = total + 19.95;
          } else {
            total = total + 37.95;
          }
        } else {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
            total = total + 48.95;
          } else {
            total = total + 78.95;
          }
        }
      }else{
        if (['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country)) {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
            total = total + 12.95;
          } else {
            total = total + 29.95;
          }
        } else {
          if (quoteInfo.hardCopyExtra.method === 'priority') {
            total = total + 39.95;
          } else {
            total = total + 67.95;
          }
        }
      }
      
    }

    if (quoteInfo && quoteInfo.hardCopy && quoteInfo.hardCopyExtra.requireSignature) {
      if(quoteDate >= priceUpdateDate){
        total = total + 4.99;
      }else{
        total = total + 2.54;
      }
    }

    return total;
  }

  const getDiscount = (quoteInfo) => {
    if (discount === 0 || !discount || discount === '') {
      return 0;
    } else {
      const orderDate = quoteInfo ? new Date(quoteInfo.timestamp) : new Date();
      const discountUpdateDate = new Date('Tue, 28 Mar 2023 20:02:56 GMT');
      if(orderDate >= discountUpdateDate){
        let total = 0;

        if (quoteInfo && quoteInfo.certified) {
          if (quoteInfo.pagesCount !== '') {
            total = total + parseInt(quoteInfo.pagesCount, 10) * 24.95;
          } else {
            total = 0;
          }
        } else if (quoteInfo) {
          if (quoteInfo.wordCount !== '') {
            total = total + parseInt(quoteInfo.wordCount, 10) * 0.1;
          } else {
            total = 0;
          }
        }
        return total * quoteInfo.discount / 100;
      }
      return (getTotal(quoteInfo) * discount) / 100;
    }
  }

  const formatTotal = value => {
    return '$' + value.toFixed(2);
  }

  const formatDiscount = value => {
    return '-$' + value.toFixed(2);
  }

  return (
    <div className="singleTranslationContainer">
      <div className="singleTranslationHeader">
        <p className="singleTranslationOrderNumber">{quoteInfo && "#" + quoteInfo.quoteNumber}</p>
        {quoteInfo && <div className="singleTranslationTabsControl">
          <p className="singleTranslationTabButton" onClick={() => setTab('details')} style={{ color: tab === 'details' ? '#FF7C1D' : '#666', borderColor: tab === 'details' ? 'rgba(255, 124, 29, 1)' : 'rgba(255, 124, 29, 0)' }}>Details</p>
          <p className="singleTranslationTabButton" onClick={() => setTab('files')} style={{ color: tab === 'files' ? '#FF7C1D' : '#666', borderColor: tab === 'files' ? 'rgba(255, 124, 29, 1)' : 'rgba(255, 124, 29, 0)' }}>Files</p>
        </div>}
      </div>
      {quoteInfo && tab === 'details'
      ? <div className="singleTranslationDetailsOuter">
          <div className="singleTranslationDetailsMain">
            <div className="singleTranslationDetailsDates">
              <p className="singleTranslationDecideTitle">Quote Details</p>
              <div className="singleTranslationDecideMainContainer">
                <div className="singleTranslationDecideInfoContainer" style={{ width: windowSize.width <= 1024 && windowSize.orientation === 'portrait' ? '100%' : '54%' }}>
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Language Pair</p>
                    <p className="singleTranslationDecideInfoText">
                      <strong>{languages.find(lang => lang.value === quoteInfo.languageFrom).label}</strong>
                      {" to "}
                      <strong>{languages.find(lang => lang.value === quoteInfo.languageTo).label}</strong>
                    </p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Type</p>
                    <p className="singleTranslationDecideInfoText">{quoteInfo.certified ? "Certified Translation" : "Standard Translation"}</p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Submitted</p>
                    <p className="singleTranslationDecideInfoText">{formatDate(quoteInfo.timestamp)}</p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Replied</p>
                    <p className="singleTranslationDecideInfoText">{formatDate(quoteInfo.replied)}</p>
                  </div>
                  <div className="singleTranslationDecideInfoSeparator" />
                  <div className="singleTranslationDecideInfoLine">
                    <p className="singleTranslationDecideInfoTitle">Ordered</p>
                    <p className="singleTranslationDecideInfoText">{formatDate(quoteInfo.ordered)}</p>
                  </div>
                </div>
              </div>
              <div className="singleTranslationDecideTurnaround" style={{ color: quoteInfo.expedited ? '#FF7C1D' : '#333' }}>
                <Clock className="singleTranslationDecideTurnaroundIcon" style={{ color: quoteInfo.expedited ? '#FF7C1D' : '#333' }}/>
                {quoteInfo.expedited ? 'Expedited Turnaround' : 'Standard Turnaround'}
              </div>
            </div>

            <div className="singleTranslationDetailsComments">
              <p className="singleTranslationDecideTitle">Comment History</p>
              <>
                {clientNotes.getCurrentContent().getPlainText() !== '' && <div className="singleTranslationNotesContainer">
                  <div className="singleTranslationNotesTitle">
                    <strong>Client's Notes</strong>
                    {!quoteInfo.supportNotes && <div className="singleTranslationNotesAuxButton" onClick={() => toggleSupportReply()}>
                      {openReplyBox ? 'Cancel' : 'Reply'}
                    </div>}
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={clientNotes}
                      onChange={clientNotes => setClientNotes(clientNotes)}
                      readOnly
                    />
                  </div>
                </div>}
                {(quoteInfo.supportNotes || openReplyBox ) && <div className="singleTranslationNotesContainer">
                  <div className="singleTranslationNotesTitle" style={{ backgroundColor: '#e91e63' }}>
                    <strong>Support Notes</strong>
                    <div className="singleOrderTextButtons">
                      <Bold className="singleOrderRichTextButton" onClick={() => handleBoldClick()} />
                      <Italic className="singleOrderRichTextButton" onClick={() => handleItalicClick()} />
                      <Underline className="singleOrderRichTextButton" onClick={() => handleUnderlineClick()} />
                      <List className="singleOrderRichTextButton" onClick={() => toggleUL()} />
                      <div className="singleTranslationNotesAuxButton" onClick={() => saveSupportReply()}>
                        Save
                      </div>
                    </div>
                  </div>
                  <div className="singleTranslationRichTextEditor">
                    <Editor
                      editorState={supportNotes}
                      onChange={supportNotes => setSupportNotes(supportNotes)}
                    />
                  </div>
                </div>}
              </>
            </div>

            <div className="singleTranslationDetailsComments">
              <p className="singleTranslationDecideTitle">Client Information</p>
              <p className="singleTranslationDetailsSecondaryText">
                <strong>Name:</strong>{` ${quoteInfo.name}`}
                <br/>
                <strong>Email:</strong>{` ${quoteInfo.email}`}
              </p>
            </div>
          </div>
          <div className="singleRevisionDetailsSecondary">
            <div className="singleRevisionInformation">
              <p className="singleTranslationDecideTitle">Quote Information</p>
              <div className="singleTranslationStatusContainer" style={{ backgroundColor: quoteInfo.status === 'submitted' ? "#FF7C1D" : quoteInfo.status === 'replied' ? '#30d5c8' : '#17BC5B' }}>
                {quoteInfo.status === 'submitted' ? 'IN REVIEW' : quoteInfo.status === 'replied' ? 'READY' : 'ORDERED'}
              </div>
              <p className="singleTranslationDetailsSecondaryText">
                <strong>{quoteInfo.certified ? 'Certified Translation' : 'Standard Translation'}</strong>
                <br/>
                {quoteInfo.certified ? 'This is a word-for-word translation delivered as both a .docx and .pdf with a coversheet. Basic formatting to match the source document is required.' : 'This is a standard translation delivered as a .docx only with no coversheet. Basic formatting is required.'}
              </p>
              <FormSimpleTextInput placeholder={quoteInfo.certified ? "Page Count (*)" : "Word Count (*)"} value={count} onChange={onChangeCount} color='#666' />
              <FormSimpleTextInput placeholder={"Discount (%)"} value={discount} onChange={onChangeDiscount} color='#666' />
              <div className="singleTranslationDetailsSecondarySeparator" />
              { quoteInfo.status !== 'ordered' &&
                <AltAnimatedButton space color={'#e91e63'} text={submitQuoteButton} disabled={count === 0 || count === '' || quoteInfo.status === 'ordered'} width={'100%'} onClick={() => submitQuote()} backend fontSize={windowSize.width > 1024 ? 1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.9 : 1.2) : (windowSize.orientation === 'portrait' ? 3.7 : 1.1)} />
              }
            </div>
            <div className="singleRevisionInformation">
              <p className="singleTranslationDecideTitle">Quote Summary</p>
              <div className="singleQuoteSummary">
                <div className="orderSummaryInfo">
                  <div className="orderSummaryInfoMain">
                    {quoteInfo && <p className="singleQuoteSummaryInfoType">{quoteInfo.certified ? "Certified Translation" : "Standard Translation"}</p>}
                    {quoteInfo && <p className="singleQuoteSummaryInfoCount">{quoteInfo.certified ? (count !== '' ? count + " pages (" + (parseInt(count, 10) * 250) + " words max)" : "0 pages (0 words max)") : (count !== '' ? count + " words" : "0 words")}</p>}
                  </div>
                  {quoteInfo && <p className="singleQuoteSummaryInfoType">{quoteInfo.certified ? (count !== "" ? '$' + (parseInt(count, 10) * 24.95).toFixed(2) : "$0.00") : ((count !== '' ? '$' + (parseInt(count, 10) * 0.1).toFixed(2) : '$0.00'))}</p>}
                </div>
                {quoteInfo && quoteInfo.notarization ? 
                <div className="orderSummaryInfo">
                  <div className="orderSummaryInfoMain">
                    <p className="singleQuoteSummaryInfoType">Notarization</p>
                    <p className="singleQuoteSummaryInfoCount">Valid in all 50 states</p>
                  </div>
                  <p className="singleQuoteSummaryInfoType">$34.90</p>
                </div> : null}
                {quoteInfo && quoteInfo.expedited ? 
                <div className="orderSummaryInfo">
                  <div className="orderSummaryInfoMain">
                    <p className="singleQuoteSummaryInfoType">Expedited Turnaround</p>
                    <p className="singleQuoteSummaryInfoCount">Turnaround reduced by 50%</p>
                  </div>
                  <p className="singleQuoteSummaryInfoType">{formatTotal(getExpedited())}</p>
                </div> : null}
                {quoteInfo && quoteInfo.hardCopy && quoteInfo.hardCopyExtra.country !== '' && quoteInfo.hardCopyExtra.method !== '' ? 
                <div className="orderSummaryInfo">
                  <div className="orderSummaryInfoMain">
                    <p className="singleQuoteSummaryInfoType">{`USPS ${quoteInfo.hardCopyExtra.method.charAt(0).toUpperCase() + quoteInfo.hardCopyExtra.method.slice(1)} Mail ${!['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? 'International' : ''}`}</p>
                    <p className="singleQuoteSummaryInfoCount">{`${['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? (quoteInfo.hardCopyExtra.method === 'priority' ? '2-3' : '1-2') : (quoteInfo.hardCopyExtra.method === 'priority' ? '6-10' : '3-5')} days in transit`}</p>
                  </div>
                  <p className="singleQuoteSummaryInfoType">{`$${(new Date(quoteInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? (quoteInfo.hardCopyExtra.method === 'priority' ? '19.95' : '37.95') : (quoteInfo.hardCopyExtra.method === 'priority' ? '49.95' : '78.95') : ['United States', 'Puerto Rico', 'U.S. Virgin Islands'].includes(quoteInfo.hardCopyExtra.country) ? (quoteInfo.hardCopyExtra.method === 'priority' ? '12.95' : '29.95') : (quoteInfo.hardCopyExtra.method === 'priority' ? '39.95' : '67.95')}`}</p>
                </div> : null}
                {quoteInfo && quoteInfo.hardCopy && quoteInfo.hardCopyExtra.requireSignature ? 
                <div className="orderSummaryInfo">
                  <div className="orderSummaryInfoMain">
                    <p className="singleQuoteSummaryInfoType">Signature Required for Delivery</p>
                    <p className="singleQuoteSummaryInfoCount">Adult signature required</p>
                  </div>
                  <p className="singleQuoteSummaryInfoType">{`$${(new Date(quoteInfo.timestamp) >= new Date('Sun, 30 Oct 2022 20:02:56 GMT')) ? 4.99 : 2.54 }`}</p>
                </div> : null}
                {discount > 0 ? 
                  <div className="orderSummaryInfo">
                    <div className="orderSummaryInfoMain">
                      <p className="singleQuoteSummaryInfoType">{`Discount -${discount}%`}</p>
                    </div>
                    <p className="singleQuoteSummaryInfoType">{formatDiscount(getDiscount(quoteInfo))}</p>
                  </div> : null}
                <div className="orderSummaryTotal">
                  <p>Total</p>
                  <p>{formatTotal(getTotal() - getDiscount(quoteInfo))}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      : quoteInfo && tab === 'files'
      ? <div className="singleTranslationFilesOuter">
          <div className="singleTranslationFilesMain">
            <p className="singleTranslationDecideTitle">Quote Files</p>
            <div className="singleTranslationFilesTableHeader">
              <div className="singleTranslationFilesTableHeaderColumn singleTranslationFilesTableHeaderNameColumn">Name</div>
            </div>
            { quoteInfo.files.map(file => 
              <div key={file.name} className="singleTranslationFilesTableLine">
                <div className="singleTranslationFilesTableLineTextColumn singleTranslationFilesTableHeaderNameColumn">
                  <p className="singleTranslationFilesTableLineText" style={{ cursor: 'pointer' }} onClick={() => downloadFile(file.url)}>
                    <strong>{file.name}</strong>
                    {file.size && file.timestamp && <br/>}
                    <span style={{ opacity: 0.7 }}>{file.size && file.timestamp && `Uploaded ${formatDate(file.timestamp)} - ${formatSize(file.size)}`}</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      : null
      }
    </div>
  );
};

export default SingleQuote;