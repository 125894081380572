import React, { useState, useEffect, useContext } from 'react';
import { Element, scroller } from 'react-scroll';
import axios from 'axios';

import { Navbar } from '../Components/Navbar';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { FormTextArea } from '../Components/FormTextArea';
import { FormTextInput } from '../Components/FormTextInput';
import { Footer } from '../Components/Footer';

import { Check } from 'react-feather';

import { TransitionContext } from '../Context/TransitionContext';
import { NotificationContext } from '../Context/NotificationContext';
import TopBar from '../Components/TopBar';

// import { useScreenTransition } from '../Hooks/useScreenTransition';

import './Business.css';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';
import NewNavbar from './LandingPage/NewNavbar';

const Business = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  const { showNotification } = useContext(NotificationContext);

  // const { transitionTo } = useScreenTransition();

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const [name, setName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [expectedVolume, setExpectedVolume] = useState('');
  const [translationNeeds, setTranslationNeeds] = useState('');

  const [submitButton, setSubmitButton] = useState('Submit');

  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  const goToForm = () => {
    scroller.scrollTo('contactForm', {
      duration: 1000,
      delay: 0,
      smooth: true,
      offset: -30
    })
  }

  const handleSubmit = () => {
    setSubmitButton('Submitting...');

    axios({
      method:'post',
      url: "https://us-central1-project-everbary.cloudfunctions.net/businessFormSubmitted",
      data: {
        name: name,
        businessName: businessName,
        email: email.toLowerCase(),
        phone: phone,
        expectedVolume: expectedVolume,
        translationNeeds: translationNeeds
      }
    })
      .then((response) => {
        setName('');
        setBusinessName('');
        setEmail('');
        setPhone('');
        setExpectedVolume('');
        setTranslationNeeds('');
        setSubmitButton('Submit');
        showNotification("Success", "Your message was successfully sent to our team.", "success");
      })
      .catch((error) => {
        setSubmitButton('Submit');
        showNotification("Error", "An error ocurred sending your message. Please, try again. If it persists, contact technical support at support@everbary.com", "error");
      });
  }

  return (
    <>
    
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      <NewNavbar
          windowSize={windowSize}
          isLoginFormOpen={handleLoginOpen}
          isCreateAccountOpen={handleCreateAccountOpen}
        />
        {/* <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/> */}
      <div className="headerContainer businessHeaderContainer">
        <div className="headerInfoContainer">
          <h1 className="documentsHeading">Benefits for Businesses</h1>
          <h2 className="documentsSubheading">We support businesses of all sizes across dozens of industries with seamless and efficient workflows to ensure all materials are properly translated and delivered on time.</h2>
          {/* <h2 className="documentsSubheading">Our professional <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified</span> and <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard</span> document translation services are designed with speed, quality, and scale in mind. We support businesses of all sizes across dozens of industries with seamless and efficient workflows to ensure all materials are properly translated and delivered on time.</h2> */}
          <div className="businessHeaderSeparator" />
          <AltAnimatedButton text="Contact Sales" onClick={() => goToForm()} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.6) : (windowSize.orientation === 'portrait' ? 6 : 2.2)} />
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'business.webp' : 'business.png'}`)} alt="People sharing a document" className="documentsHeaderImg" /> : null}
      </div>
      <div className="singleLanguageMainContainer businessMainContainer">
        <div className="singleLanguageMainInfoContainer businessMainInfoContainer">
          <h2 className="documentsHeading">Features to Support Your Business</h2>
          <h3 className="documentsSubheading">
            In addition to our standard services, you’ll have access to additional business class features to seamlessly integrate our translation services with your company’s workflows, products, and services.
            <br/><br/>
            We’ll also pair you with a dedicated account manager so you’ll have a trusted contact available via email or phone.
          </h3>
        </div>
        <div className="singleLanguageMainInfoContainer">
          <div className="businessLine">
            <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />
            <div className="businessLineInfo">
              <h3 className="businessLineInfoTitle">White Label Services</h3>
              <p className="documentsSubheading">Translations delivered with your company's custom coversheet, letterhead, and other materials.</p>
            </div>
          </div>
          <div className="businessLine">
            <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />
            <div className="businessLineInfo">
              <h3 className="businessLineInfoTitle">Third-Party Payments</h3>
              <p className="documentsSubheading">Allow your customers to pay for their quotes directly while you manage the workflow and delivery.</p>
            </div>
          </div>
          <div className="businessLine">
            <Check size={windowSize.width > 1024 ? 30 : windowSize.width > 767 ? 33 : 45} color='#18b7bb' className="businessLineGlyph" />
            <div className="businessLineInfo">
              <h3 className="businessLineInfoTitle">Monthly Invoicing</h3>
              <p className="documentsSubheading">Flexible monthly invoicing with payment via credit card, check, or wire transfer.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="singleLanguageMainContainer businessMainContainer">
        <div className="singleLanguageMainInfoContainer businessMainInfoContainer">
          <Element name="contactForm" className="documentsHeading">Contact Our Sales Team</Element>
          <p className="documentsSubheading">Let’s have a conversation to see if we are the right translation partner for your business. You can kick it off by providing us with some basic details about your translation needs via this form. We look forward to hearing from you and will follow up quickly.</p>
        </div>
        <div className="createAccountFormContainer businessFormContainer">
          <FormTextInput placeholder="Name *" value={name} onChange={setName} color='#666' />
          <FormTextInput placeholder="Business Name *" value={businessName} onChange={setBusinessName} color='#666' />
          <FormTextInput placeholder="Email *" value={email} onChange={setEmail} color='#666' />
          <FormTextInput placeholder="Phone Number *" value={phone} onChange={setPhone} color='#666' />
          <FormTextInput placeholder="Expected Volume *" value={expectedVolume} onChange={setExpectedVolume} color='#666' />
          <FormTextArea placeholder="Describe your translation needs *" value={translationNeeds} onChange={setTranslationNeeds} color='#666' />
          <div className="createAccountFormSeparator" />
          <AltAnimatedButton text={submitButton} disabled={email === "" || name === "" || phone === "" || businessName === "" || expectedVolume === "" || translationNeeds === ""} onClick={() => handleSubmit()} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.6 : 1.1) : (windowSize.orientation === 'portrait' ? 5 : 1.5)}/>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Business;